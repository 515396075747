.marketing-dashboard {
	overflow-y: auto;
	height: 88vh;
	position: inherit;
	.get-to-know {
		padding: 4rem 5rem;
		&__title {
			color: #1c2e59;
			font-size: 20px;
			font-weight: 800;
			margin-bottom: 8px;
		}
		&__sub-title {
			font-size: 18px;
			margin-bottom: 20px;
		}
		&__video-links {
			.video-links-thumbnail {
				width: 32%;
			}
		}
	}
	.sales-marketing {
		padding: 4rem 5rem;
		background-color: #ffffff;
		&__title {
			color: #1c2e59;
			font-size: 20px;
			font-weight: 800;
			margin-bottom: 30px;
		}
		&__card {
			border-radius: 2px;
			padding: 2rem 3.8rem 2rem 2rem;
			width: 32%;
			cursor: pointer;
			border: 2px solid #11a1ae;
			margin-bottom: 20px;
			margin-right: 20px;
			color: #1c2e59;
			&:hover {
				background-color: #11a1ae;
				color: #ffffff;
			}
			&__title {
				font-size: 20px;
				font-weight: 800;
				margin-bottom: 8px;
			}
			&__sub-title {
				font-size: 18px;
			}
		}
	}
}
.add-marketing-resource-button {
	margin-right: 12.5%;
}
.marketing-resource-card {
	border-radius: 2px;
	padding: 2rem 3.8rem 2rem 3.8rem;
	width: 100%;
	cursor: pointer;
	border: 2px solid #e9e9e9;
	margin-bottom: 20px;
	margin-right: auto;
	margin-left: auto;
	width: 75%;
	color: #1c2e59;
	background-color: #e6eef4;
	&:hover {
		border: 2px solid #11a1ae;
	}
	.resources-download-icon,
	.material-icons {
		height: 34px;
		cursor: pointer;
	}
	&__text {
		flex: 1 1 auto;
		.marketing-resource-card-title {
			font-size: 20px;
			font-weight: 800;
			margin-bottom: 8px;
		}
		.marketing-resource-card-sub-title {
			font-size: 18px;
		}
	}
	&__content-card {
		width: 100%;
		background-color: #ffffff;
		border: 2px solid #e9e9e9;
		border-radius: 2px;
		font-size: 18px;
		padding: 20px;
		margin: 10px 30px 10px 0px;
		.social-media-actions {
			// float: right;
			.social-media-share-link {
				margin-top: 10px;
				color: #11a1ae;
				font-weight: 800;
			}
		}
	}
}
.marketing-resources-social-posts-images {
	height: 200px;
	width: 200px;
	margin-bottom: 10px;
	border-radius: 10px;
}
.marketing-resources-container {
	padding-right: 0px;
}
.marketing-resources-page {
	&__section {
		margin-bottom: 40px;
		.marketing-resources-section-pic {
			width: 50%;
		}
		.marketing-resources-section-description {
			margin-right: 40px;
			.section-description-title {
				font-size: 20px;
				font-weight: 800;
				margin-bottom: 20px;
			}
			.section-description-description {
				font-size: 18px;
				line-height: 3rem;
			}
		}
		.marketing-social-media-links {
			border-radius: 2px;
			padding: 1rem;
			width: 100%;
			cursor: pointer;
			border: 2px solid #e9e9e9;
			margin-bottom: 20px;
			margin-right: 20px;
			color: #1c2e59;
			background-color: #e6eef4;
			&__icon {
				height: 30px;
				margin-right: 20px;
			}
			&__link {
				font-weight: 400;
				color: #1c2e59;
				font-size: 16px;
			}
		}
	}
}
.marketing-resources-header-title {
	font-size: 20px;
	font-weight: 800;
	line-height: 4rem;
	color: #ffffff;
}

.enquirybot-partner-register-modal {
	.modal-container {
		width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
	.modal__footer {
		border-top: 2px solid #e6eef4;
		justify-content: space-between;
	}
	.enquirybot-partner-model {
		&__close {
			float: right;
		}
		&__container {
			.enquirybot-partner-model-customer {
				&__title {
					font-size: 22px;
					font-weight: 800;
					margin-top: 50px;
					margin-bottom: 10px;
				}
				&__sub-title {
					font-size: 16px;
					margin-bottom: 60px;
				}
			}
			.enquirybot-partner-model-plan-selected {
				.plan-selected-title {
					font-size: 22px;
					font-weight: 800;
					margin-top: 50px;
					margin-bottom: 10px;
				}
				.plan-selected-sub-title {
					font-size: 16px;
					font-weight: 600;
					opacity: 0.6;
					margin-bottom: 10px;
				}
				.plan-selected-price {
					padding: 12px 12px 12px 16px;
					border: 2px solid #12b13f;
					background-color: #e9f9f2;
					width: 450px;
					border-radius: 3px;
					margin-top: 10px;
					.plan-title {
						font-size: 18px;
						font-weight: 600;
					}
					.plan-price {
						float: right;
						font-size: 20px;
						font-weight: 600;
						.plan-duration {
							font-size: 12px;
							opacity: 0.5;
							margin-left: 5px;
						}
					}
				}
				.plan-selected-next-step {
					margin: 20px 0px;
					font-weight: 600;
					font-size: 22px;
				}
				.plan-selected-warning {
					width: 450px;
					padding: 12px 28px 12px 28px;
					border: 2px solid #ffb300;
					background-color: #fff8e1;
					font-weight: 600;
					font-size: 14px;
					text-align: center;
				}
				.plan-selected-btn {
					margin-top: 30px;
					padding: 20px;
					font-weight: 800;
					font-size: 20px;
				}
			}
			.enquirybot-partner-model-payment {
				.payment-title {
					font-size: 22px;
					font-weight: 800;
					margin-top: 50px;
					margin-bottom: 10px;
				}
				.payments-type {
					position: relative;
					// border: 2px solid #c8c7c7;
					border-radius: 3px;
					height: 100px;
					width: 200px;
					background-color: #eaf4f9;
					color: #393f53;
					cursor: pointer;
				}
			}
			.enquirybot-partner-model-plans {
				width: 40%;
				padding-right: 10px;
				&__title {
					font-size: 24px;
					font-weight: 800;
					line-height: 4rem;
					margin: 2% 10%;
				}
				&__terms {
					font-size: 12px;
					line-height: 4rem;
					margin: 6px 0% 0% 22%;
				}
				.plan-active {
					color: #ffffff;
					background-color: #11a1ae;
				}
				&__toggle {
					cursor: pointer;
					display: inline;
					border: 1px solid #11a1ae;
					padding: 1rem;
					font-weight: 800;
					font-size: 18px;
					margin-right: 10px;
					width: 50%;
					.plan-duration {
						font-size: 14px;
						opacity: 0.5;
						margin-left: 5px;
					}
				}
				&__updates {
					padding: 20px 30px 10px 10px;
					.plans-updates-sub-title {
						font-size: 16px;
						font-weight: 800;
						margin-bottom: 20px;
					}
					.plans-updates-price {
						float: right;
						font-size: 18px;
						font-weight: 600;
						.plan-duration {
							font-size: 12px;
							opacity: 0.5;
							margin-left: 5px;
						}
					}
				}
				&__total {
					margin: 0px 22px;
					border-top: 1px solid #e6eef4;
					.plans-total-price {
						margin-top: 10px;
						font-size: 18px;
						font-weight: 800;
						.plan-duration {
							font-size: 16px;
							opacity: 0.5;
							margin-left: 5px;
						}
					}
					.plans-total-label {
						margin-top: 10px;
						font-size: 16px;
						font-weight: 800;
						margin-bottom: 10px;
					}
				}
				.plan-register-btn {
					& > button {
						width: 100%;
						text-align: center;
						font-size: 20px;
						font-weight: 800;
						padding: 1.5rem 0rem;
					}
					padding: 1rem 2rem 0rem 2rem;
				}
			}
			.enquirybot-partner-success-payment {
				.plan-selected-title {
					font-size: 22px;
					font-weight: 800;
					margin-top: 50px;
					margin-bottom: 10px;
				}
				.plan-selected-sub-title {
					font-size: 16px;
					font-weight: 600;
					opacity: 0.6;
					margin-bottom: 10px;
				}
				.plan-selected-btn {
					margin-top: 10px;
					padding: 20px;
					font-weight: 800;
					font-size: 20px;
				}
				.plan-selected-next-step {
					margin: 30px 0px;
					font-weight: 600;
					font-size: 22px;
				}
				.material-icons {
					&.success {
						opacity: 1;
						background-color: #12b13f;
						font-size: 50px;
					}
					&.fail {
						opacity: 1;
						background-color: $red;
						font-size: 50px;
					}
				}
			}
			.enquirybot-partner-model-includes {
				width: 40%;
				background-color: #e6eef4;
				padding: 3rem;
				.plans-model-title {
					font-size: 20px;
					font-weight: 800;
					line-height: 4rem;
				}
				.plans-model-benefit {
					line-height: 4rem;
					font-size: 16px;
					opacity: 0.2;
					&.active {
						opacity: 0.8;
					}
					.material-icons {
						float: right;
						margin-top: 10px;
						border-radius: 50%;
						font-size: 14px;
						font-weight: bold;
						padding: 3px;
						color: #fff;
						background-color: gray;
						// opacity: 0.2;
						&.active {
							opacity: 1;
							background-color: #12b13f;
						}
					}
				}
			}
		}
	}
}

.register-lead-modal {
	.modal-container {
		width: 475px;
		margin-left: auto;
		margin-right: auto;
		.lead-modal-title {
			font-weight: 700;
			font-size: 18px;
		}
		.lead-already-registered {
			margin: 10px;
			.lead-exclamation {
				height: 50px;
				margin-right: 15px;
			}
			.lead-title {
				font-weight: 700;
				font-size: 18px;
				margin-bottom: 6px;
			}
			.lead-sub-title {
				font-size: 18px;
			}
		}
		.modal__body {
			margin-bottom: 1rem;
		}
	}
	.modal__footer {
		border-top: 2px solid #e6eef4;
		justify-content: space-between;
	}
}
