.settings {
	width: 850px;
	.bot-builder {
		background-color: initial;
	}
	.bot-builder__aside {
		width: 900px;
		flex-grow: initial;
	}
	.menu-options {
		width: calc(100% - 76px);
		height: 100%;
	}
	.cont-wrap {
		padding: 0 4rem 2rem 0;
		border-bottom: 1px solid rgba($border-color, 0.5);
		.sub-sec-title {
			color: $primary-text-color;
			font-size: 1.4rem;
			font-weight: $bold;
			line-height: 1.7rem;
			text-transform: uppercase;
			margin-bottom: 1.5rem;
		}
		.link {
			font-size: 1.4rem;
			> .material-icons {
				font-size: 2rem;
			}
		}
		.material-icons.delete {
			position: absolute;
			right: -20px;
			top: 8px;
			cursor: pointer;
		}
	}
	.grey-value {
		color: #979797;
	}
	&--view {
		.input-wrap {
			.input-label {
				color: rgba(32, 45, 86, 0.56);
				font-weight: $regular;
			}
			.input-desc {
				font-size: 1.5rem;
				line-height: 2rem;
			}
		}
		.url-link {
			background: url('/img/link-url.png') left center no-repeat;
			padding-left: 3rem;
			.url-icon {
				color: $primary-text-color;
			}
			.link-item {
				color: #4e4e4e;
				font-size: 1.4rem;
				font-weight: 700;
				line-height: 1.9rem;
				width: 100%;
				&:hover {
					// color: darken($primary-brand-color, 20%);
					text-decoration: none;
				}
			}
		}
	}
}

.pos-relative {
	position: relative;
}

.border-bottom {
	border-bottom: 0 !important;
}

.client-form-container {
	height: calc(100% - 80px);
	overflow: auto;
	padding: 0 5px;
	@media only screen and (min-width: 834px) and (max-width: 1024px) {
		height: calc(100% - 140px);
	}
	.input-wrap {
		.app_dropdown__control,
		.input-field {
			border: none !important;
		}
		.input-label {
			font-size: 16px;
			font-weight: 700;
		}
	}
}

.account-information {
	padding-right: 15px;
	height: calc(100vh - 90px);
	overflow: auto;
	.account-info-title {
		font-size: 18px;
		font-weight: 700;
	}

	.account-info-section-title {
		font-size: 16px;
		font-weight: 700;
	}
	.primary-label {
		font-size: 14px;
		font-weight: 700;
	}
	.form-seperator {
		border-top: 1px solid #d5e2e8;
	}
	.account-edit-button {
		color: #11a1ae;
		border-color: #11a1ae;
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 500;
		padding: 0.9rem 1.6rem;
		background: #fff;
		border: 1px solid;
		border-radius: 2px;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: #96edf5;
		}
	}
}
.design-title-section {
	background-color: #f2f7f9;
	padding: 25px 30px 0 30px;
	.design-one {
		width: 200px;

		.title {
			color: #1c2e59;
			font-size: 16px;
			font-weight: 700;
		}
		.desc {
			font-size: 14px;
			color: #777a82;
			margin-top: 20px;
		}
	}
}
