.admin-profile {
	&--edit {
		.ruler1 {
			margin: 2rem 0;
			border: 1px solid #dbdbdb;
		}
		.section-footer__right {
			.btn--cancel,
			.btn--save {
				min-width: 80px;
				padding-left: initial;
				padding-right: initial;
			}
		}
		.item-wrap {
			.label-heading {
				color: rgba(#393f53, 0.6);
				font-size: 1.3rem;
				font-weight: $medium;
				line-height: 1.6rem;
			}
		}
		.right-sec {
			width: calc(100% - 146px);
			margin-left: 2rem;
			width: 900px;
			@include respond-to(mobile) {
				width: 100% !important;
				margin-left: 0px;
			}
		}
		.account {
			&-grid {
				margin-top: 20px;
				display: flex;
				@include respond-to(mobile) {
					flex-wrap: wrap;
					margin-top: 0px;
				}
			}
		}
		.left {
			flex-basis: 25%;
			@include respond-to(mobile) {
				flex-basis: auto;
			}
			.section-header {
				max-width: 180px;
			}
		}
		.right {
			background: white;
			padding: 40px 30px 0 32px;
			border-radius: 10px;
			box-shadow: 0 11px 12px 0 #c4cbc9;
			// min-height: 550px;
			max-width: 900px;
			@include respond-to(mobile) {
				margin-top: 10px;
				padding: 20px 20px;
			}
		}
		.cont-wrap {
			@include respond-to(mobile) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
		.header-section {
			justify-content: center;
			margin: 1.7rem 0;
			.heading-level1 {
				margin-bottom: 0.6rem;
			}
			.website {
				color: $primary-text-color;
				font-size: 1.4rem;
				line-height: 1.7rem;
				margin-bottom: 0.8rem;
			}
			.add-info {
				color: rgba(#646a7c, 0.8);
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
			}
		}
	}
	&--view {
		@include respond-to(mobile) {
			display: none;
		}
		&.mobile {
			display: none;
			@include respond-to(mobile) {
				display: block;
			}
		}
		.right-sec {
			width: calc(100% - 146px);
			margin-left: 2rem;
			width: 900px;
		}
		.account {
			&-grid {
				margin-top: 20px;
				display: flex;
				@include respond-to(mobile) {
					margin-top: 0px;
				}
			}
		}
		.left {
			flex-basis: 25%;
			.section-header {
				max-width: 180px;
			}
		}
		.right {
			background: white;
			padding: 40px 30px;
			border-radius: 10px;
			box-shadow: 0 11px 12px 0 #c4cbc9;
			min-height: 550px;
			max-width: 900px;
			@include respond-to(mobile) {
				margin-top: 10px;
				padding: 15px 15px;
			}
		}
		.btn--box-wrap {
			@include avatar(40px, 40px, $white, 3px);
			border: 1px solid #dbd9e4;
			> .material-icons {
				color: rgba($black, 0.5);
				font-size: 2rem;
			}
		}
		.header-section {
			margin-bottom: 1.7rem;
			.heading-level1 {
				margin-bottom: 0.6rem;
			}
			.website {
				color: $primary-text-color;
				font-size: 1.4rem;
				line-height: 1.7rem;
				margin-bottom: 0.8rem;
			}
			.add-info {
				color: rgba(#646a7c, 0.8);
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
			}
		}
		.table-info {
			border-top: 1px solid rgba($border-color, 0.47);
			margin-top: 25px;
			&__header {
				padding: 1rem 0;
				border-bottom: 1px solid rgba($border-color, 0.47);
				.heading {
					color: $primary-text-color;
					font-size: 1.4rem;
					font-weight: $medium;
					line-height: 1.7rem;
				}
			}
			&__body {
				.heading-level4 {
					margin: 1.8rem 0 2.4rem;
				}
				.cont-item {
					@include flexbox();
					margin-bottom: 1.7rem;
					.title,
					.desc {
						color: #393f53;
						font-size: 1.4rem;
						font-weight: $semibold;
						line-height: 2rem;
						width: 50%;
						@include word-wrap();
					}
					.desc {
						color: rgba(#393f53, 0.8);
					}
				}
			}
		}
	}
	.avatar-pic {
		@include avatar(124px, 124px, transparent, 100%);
		position: relative;
		min-width: 124px;
		border-radius: 50%;
		background: #fb7c4d;
		text-align: center;
		line-height: 124px;
		font-size: 30px;
		font-weight: 800;
		color: white;
		@include respond-to(mobile) {
			@include avatar(75px, 75px, transparent, 100%);
			min-width: 75px;
			background: #fb7c4d;
			line-height: 75px;
		}
		> img {
			@include avatar(124px, 124px, transparent, 100%);
			min-width: 124px;
			object-fit: cover;
			@include respond-to(mobile) {
				@include avatar(75px, 75px, transparent, 100%);
				min-width: 75px;
			}
		}
		.upload {
			@include flexbox();
			justify-content: center;
			align-items: center;
			position: absolute;
			height: 26px;
			width: 26px;
			border-radius: 50%;
			background: #ffffff;
			border: 0.5px solid #dadada;
			border-radius: 50%;
			top: 2px;
			right: 11px;
			.material-icons {
				color: black;
				font-size: 1.8rem;
			}
		}
	}
	.border-right {
		border-right: 1px solid rgba($border-color, 0.47);
	}
	.dropdown {
		top: 30px;
		right: -5px;
		&::before {
			content: none;
		}
		&::after {
			content: none;
		}
	}
}

.avatar-info {
	@include respond-to(mobile) {
		margin-left: 1rem;
		width: 100%;
	}
	.material-icons {
		font-size: 18px;
		vertical-align: middle;
		margin-right: 10px;
		color: #5e7281;
	}
}
