.stripe {
	&-container {
		position: relative;
		// padding: 20px;
		overflow-x: auto;
		// background: #ece9e6; /* fallback for old browsers */
		// background: -webkit-linear-gradient(to top, #ece9e6, #ffffff); /* Chrome 10-25, Safari 5.1-6 */
		// background: linear-gradient(to top, #ece9e6, #ffffff);
		background: #fff;
		min-height: calc(100vh - 54px);
		&-public {
			height: 100vh;
		}
		&::before {
			height: 100%;
			width: 50%;
			position: fixed;
			content: ' ';
			top: 0;
			right: 0;
			background: #fff;
			box-shadow: 0 11px 12px 0 #c4cbc9;
			@include respond-to(tablet) {
				background: #f8f8f8;
				box-shadow: none;
				width: 0%;
			}
		}
	}
	&-single {
		position: relative;
		margin: 48px auto;
		max-width: 920px;
		height: calc(100vh - 255px);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		@include respond-to(tablet) {
			align-items: center;
			justify-content: center;
			height: calc(100vh - 195px);
			margin: 0px auto;
		}
	}

	.stripe-ruler {
		margin: 1rem 0;
		border: 1px solid #f3f3f3;
	}

	&-overview {
		width: 380px;
		.bot-logo {
			> img {
				height: 28px;
				width: auto;
				// margin-bottom: 10px;
			}
		}
		&-header {
			color: #0854b3;
			font-weight: 600;
			font-size: 2.7rem;
		}
		&-title {
			color: #9e9d9d;
			font-weight: 600;
			font-size: 2.4rem;
		}
		&-amount {
			font-weight: 600;
			font-size: 2.1rem;
		}
		.plan-detail-container {
			padding-left: 10px;
			.price-container {
				.pay-to {
					color: rgba(26, 26, 26, 0.6);
					font-size: 16px;
					font-weight: 500;
				}
				.pay-price {
					font-size: 36px;
					font-weight: 600;
					margin: 18px 0;
				}
				.pay-per-month {
					color: rgba(26, 26, 26, 0.6);
					font-size: 16px;
					font-weight: 500;
				}
			}
			.other-variable {
				justify-content: space-between;
				font-size: 16px;
				font-weight: 500;
				.variable-property {
					text-align: right;
					.property-value-detail {
						color: rgba(26, 26, 26, 0.6);
					}
				}
			}
		}
	}
	&-payment {
		width: 380px;
		z-index: 9;
		@include respond-to(tablet) {
			margin-top: 10px;
		}
		.stripe-element {
			.stripe-custom {
				width: 100%;
				padding: 11px 15px 11px 0;
				font-size: 1.4rem;
				line-height: 2rem;
				padding: 0.9rem 1.6rem;
				background: #fff;
				border: 1px solid #dadada;
				border-radius: 2px;
				transition: border 300ms;
				&.StripeElement--focus {
					border-color: $primary-brand-color--light;
				}
			}
		}
		&-error {
			position: absolute;
			width: 100%;
			top: 100%;
			left: 0;
			opacity: 0;
			visibility: hidden;
			min-height: 25px;
			padding: 0.5rem 0;
			font-size: 1.4rem;
			font-weight: $medium;
			color: rgba(#a70505, 0.6);
			transform: translateY(10px);
			transition-property: opacity, transform;
			transition-duration: 0.35s;
			&.show {
				opacity: 1;
				visibility: visible;
				transform: none;
			}
		}
	}
	.stripe-footer {
		position: absolute;
		bottom: 5px;
		display: flex;
		flex-direction: column;
		@include respond-to(tablet) {
			position: relative !important;
			bottom: -30px !important;
		}
		.powered {
			color: #9e9d9d;
			font-size: 1.2rem;
		}
		img {
			height: 20px;
			width: 43px;
		}
		.info {
			color: #9e9d9d;
			font-size: 1.2rem;
			margin-top: 5px;
		}
		.note {
			color: #393f53;
			font-size: 1.2rem;
		}
		&-partner {
			position: relative;
			top: 10px;
		}
		.container-footer {
			display: flex;
			margin-top: 5rem;
			color: #a3a3a3;
			.left {
				padding-right: 20px;
				border-right: 1px solid #a3a3a3;
			}
			.right {
				padding-left: 20px;
			}
			a {
				color: #a3a3a3;
			}
		}
	}
	.top-center {
		align-items: baseline;
	}
	.partner-plan {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: baseline;
		height: 400px;
	}
}
