body {
	overflow-y: hidden !important;
	@include respond-to(mobile) {
		overflow: auto !important;
	}
}

.bot-icon {
	position: fixed;
	bottom: 60px;
	right: 60px;
	@include transition(all 0.3s ease);
	text-align: right;
	&.close {
		transform: translateX(calc(100% + 60px));
	}
	&.open {
		.bot-icon-start-message {
			display: none;
		}
	}
	&-btn {
		position: relative;
		@include avatar(60px, 60px, #3856de, 50%);
		line-height: 7.2rem;
		border: 2px solid #ffffff;
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
		@include transition(all 0.2s ease);
		animation: slideLeft 0.6s;
		&:hover {
			//   box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
			box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.4);
		}
		&:focus {
			box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
		}
		.bot-icon-blurb {
			position: absolute;
			top: -12px;
			right: -2px;
			@include avatar(20px, 20px, #ff5e5e, 50%);
			color: white;
			text-align: center;
			line-height: 20px;
			box-sizing: content-box;
			border: 2px solid white;
			animation: scaleup 0.6s ease;
			font-size: 13px;
			font-weight: $medium;
		}
	}
	&-start-message {
		// position: relative;
		margin-bottom: 25px;
		margin-right: -20px;
		min-width: 180px;
		padding: 1.5rem 1.5rem 2rem 1.5rem;
		background: $white;
		box-shadow: -1px 4px 10px 1px rgba(90, 60, 122, 0.22);
		// box-shadow: 0 1px 10px 0 rgba(90, 60, 122, 0.22);
		border-radius: 10px 20px 10px 20px;
		font-size: 1.3rem;
		font-weight: $medium;
		opacity: 0;
		animation: slidefrombottom 0.3s ease 0.3s forwards;
		text-align: left;
		&::before {
			content: '';
			position: absolute;
			border: 7px solid transparent;
			box-sizing: border-box;
			border-top: 14px solid white;
			top: 100%;
			right: 25px;
		}
		&::after {
			//   content: "";
			width: 100%;
			height: 100%;
			transform: scale(0.8);
			position: absolute;
			z-index: -1;
			display: inline-block;
			bottom: -20px;
			background: black;
			left: 0;
			background: #cdcdcf;
			filter: blur(10px);
			-webkit-filter: blur(10px);
		}
	}
	&-chatbox {
		width: 458px;
		height: 100vh;
		position: fixed;
		top: 0;
		right: 0;
		padding-top: 100px;
		background-image: linear-gradient(270deg, #ffffff 2%, rgba(255, 252, 252, 0) 91%);
		transform: translateX(100%);
		@include transition(all 0.3s ease);
		&.open {
			transform: translateX(0);
			.bot-icon-avatar {
				animation: scaleup 0.4s ease forwards;
				animation-delay: 0.3s;
			}
			.chat-text {
				animation: width 0.3s ease 1 forwards;
				animation-delay: 2.6s;
			}
			.chat-text-loader {
				.loader1,
				.loader2,
				.loader3 {
					opacity: 1;
					animation: 0.7s ease spinner1 2;
					animation-fill-mode: forwards;
				}
				.loader1 {
					animation-delay: 1s;
				}
				.loader2 {
					animation-delay: 1.1s;
				}
				.loader3 {
					animation-delay: 1.2s;
				}
			}
		}
		.chat-container {
			margin-bottom: 0.5rem;
			.bot-icon-avatar {
				transform: scale(0);
			}
			.bubble {
				min-width: 100px;
				// opacity: 0;
				opacity: 1;
				position: relative;
				min-height: 44px;
				transition: all 0.3s ease;
			}
			.chat-text {
				// max-width: 100px;
				// max-height: 44px;
				opacity: 0;
				// padding: 1.2rem 2.5rem;
				max-width: 0;
				max-height: 0;
				white-space: pre-wrap;
				word-break: break-all;
			}
			.btn--interaction {
				border-radius: 23px;
				background-color: #3856de;
				box-shadow: 0 5px 28px 0 rgba(59, 68, 164, 0.31), 0 2px 2px 0 rgba(108, 117, 124, 0.16);
				margin-left: 5.3rem;
				padding: 1.2rem 5rem;
			}
		}
		.close-chatbox {
			position: absolute;
			top: 20px;
			right: 20px;
			@include avatar(40px, 40px, #393d53, 50%);
			color: white;
			text-align: center;
			box-sizing: content-box;
			animation: scaleup 0.6s ease;
			font-size: 13px;
			font-weight: $medium;
			box-shadow: 0 4px 7px 0 rgba(115, 109, 109, 0.5);

			> i {
				line-height: 4rem;
			}
		}
		.bubble {
			border-radius: 4px 32px 37px 33px;
			background-color: #ffffff;
			box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31), 0 2px 2px 0 rgba(108, 117, 124, 0.16);
			// padding: 1.2rem 2.5rem;
			margin-left: 0.5rem;
		}
	}
}

@keyframes slideLeft {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes scaleup {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes slidefrombottom {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slidefromTop0 {
	0% {
		transform: translateY(-150px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slidefromTop1 {
	0% {
		transform: translateY(-250px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slidefromTop2 {
	0% {
		transform: translateY(-350px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes widthAnimate {
	0% {
		width: 0;
		opacity: 0;
	}
	100% {
		width: 100px;
		opacity: 1;
	}
}

@keyframes chatText {
	0% {
		opacity: 0;
		white-space: initial;
	}
	100% {
		opacity: 1;
		white-space: initial;
	}
}

.chat-text-loader {
	position: absolute;
	left: 25px;
	top: 12px;
	.loader1,
	.loader2,
	.loader3 {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: $primary-brand-color--light;
		transform-origin: center center;
		margin-right: 1px;
		opacity: 0;
	}
	.loader2 {
		animation-delay: 0.1s;
	}
	.loader3 {
		animation-delay: 0.2s;
	}

	@keyframes spinner1 {
		0%,
		50% {
			transform: scaleX(1) scaleY(1);
		}
		25% {
			transform: scaleX(1.5) scaleY(1.5);
			background: $primary-brand-color;
			border-color: $primary-brand-color;
		}
		100% {
			transform: scaleX(1) scaleY(1);
			opacity: 0;
		}
	}
}

@keyframes width {
	0% {
		// max-width: 0;
		opacity: 0;
		// max-height: 0px;
	}
	50% {
		opacity: 0;
		// max-width: 300px;
		max-width: 270px;
		max-height: 44px;
		padding: 1.2rem 5.5rem 1.2rem 2.5rem;
	}
	100% {
		max-width: 270px;
		max-height: 1000px;
		padding: 1.2rem 5.5rem 1.2rem 2.5rem;
		// max-width: 300px;
		opacity: 1;
		// max-height: 1000px;
	}
}
.bot_selected_client_wrap::before {
	position: absolute;
	top: 8px;
	right: 8px;
	content: '\E5C5';
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 2.4rem;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	z-index: 9;
	color: #7f7f7f;
	cursor: pointer;
}
.bot_client_options {
	position: absolute;
	z-index: 99;
	background: #fff;
	border: 1px solid #dadada;
	padding: 1rem;
	max-height: 300px;
	overflow: scroll;
	.bot_client_searchbox::after {
		position: absolute;
		top: 20px;
		left: 15px;
		content: '\E8B6';
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		font-size: 2rem;
		display: inline-block;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
	}
	.bot_client_option {
		padding: 0.4rem 1.5rem;
		cursor: pointer;
	}
}
.auto-responder-iframe-section,
.custom-button-iframe-section {
	a {
		display: flex;
		align-items: flex-end;
		color: #5fb3b7;
		font-weight: bold;
	}
	.material-icons {
		margin-right: 10px;
	}
	p {
		display: flex;
		align-items: flex-end;
		color: #5fb3b7;
		font-weight: bold;
		margin-left: 15px;
		cursor: pointer;
		span {
			height: 24px;
		}
	}
}
.email-section,
.bot-custom-button {
	.heading-level4 {
		span {
			font-size: 1.4rem;
			margin-left: 15px;
			color: #bfbdbd;
		}
	}
}
.add-payment {
	&-type-info {
		font-size: 14px;
		position: relative;
		top: 3px;
		left: 3px;
	}

	&-amount {
		position: relative;
		input {
			padding: 0.9rem 2.6rem;
		}
		p {
			position: absolute;
			bottom: 10px;
			left: 15px;
		}
	}
}
.version-table {
	background-color: #fff;

	.table {
		width: 100%;
		text-align: left;
		table-layout: fixed;

		.table-actions {
			height: 32px;
			width: 32px;
			background-color: rgba(234, 234, 234, 0.4);
			border-radius: 5px;
			margin-right: 1rem;
			font-size: 2rem;
			color: #5e7281;
		}
	}
	th,
	td {
		padding-left: 5px;
		padding-right: 5px;
	}
	.header-table {
		padding: 25px 15px 10px 15px;
		border-bottom: 1px solid rgba(166, 167, 167, 0.31);
	}
	.inner-table {
		padding: 15px 15px;
		max-height: calc(100vh - 300px);
		overflow: scroll;

		tr:not(:first-child) {
			border-top: 1px solid rgba(166, 167, 167, 0.31);
			td {
				padding-top: 15px;
			}
		}
		td {
			padding-bottom: 15px;
		}
	}
}
.versions-section {
	position: relative;
	bottom: 60px;
	padding: 1rem 3rem;
	background-color: #fff;

	.versions-section-header {
		margin: 0 -30px;
		padding: 0 30px;
		border-bottom: 1px solid #f1efef;
		.tabs-container {
			border: none;
			flex-grow: 1;
		}
		button {
			font-weight: bold;
			.material-icons {
				font-size: 12px;
				font-weight: bold;
			}
		}
	}
}
.version-test {
	.input-wrap {
		margin-bottom: 2.5rem;
	}
	.notify-user-desc {
		color: #999;
	}
	.checkbox-icon-wrap::before {
		font-size: inherit;
	}
}
.version-tab-container {
	.active::after {
		background: #3f6384 !important;
	}
	.tab:hover::after {
		background: #3f6384;
	}
}
.version-checkbox {
	width: 1.4rem !important;
	height: 1.4rem !important;
	min-width: 1.4rem !important;
	min-height: 1.4rem !important;
}
.version-detail {
	&-container {
		margin-left: -25px;
		margin-right: -25px;
		border-top: 1px solid rgba(208, 211, 224, 0.8);
	}
	&-description {
		margin-left: 25px;
		margin-right: 25px;
		margin-top: 1.5rem;
	}
	&-notes-container {
		font-size: 1.2rem;
		margin-top: 1.5rem;
	}
	&-notes-title {
		font-weight: bold;
	}
	&-note {
		margin-top: 1rem;
		background-color: #f3f7f9 !important;
		border-color: #cbd6e2 !important;
		padding: 0.9rem 1.6rem;
	}
	&-toggle {
		.open {
			transform: rotate(90deg);
		}
	}
}
.Version-list-container {
	overflow-y: auto;
	height: 564px;
	.Version-test-card {
		.card-header {
			.card-title {
				flex-grow: 1;
				h1 {
					font-size: 1.6rem;
				}
				span {
					font-size: 1rem;
				}
				.status-pill {
					font-size: 0.9rem;
					border-radius: 0;
					padding: 0 0.5rem;
					font-weight: bold;
					.material-icons {
						font-size: 1.2rem;
						position: relative;
						top: 3px;
						margin-right: 0.2rem;
					}
				}
			}
			.card-actions {
				cursor: pointer;
				.material-icons {
					background: #f8f8f8;
					font-size: 1.6rem;
					padding: 3px;
					margin-left: 1rem;
				}
			}
		}
		.card-table {
			border-top: 1px solid #f1efef;
			table {
				width: 100%;
			}
			th {
				padding: 1rem 0.8rem;
				font-weight: normal;
			}
			td {
				padding: 1.5rem 0.8rem;
				font-size: 1.6rem;
				font-weight: bold;
				text-align: center;
			}
			tbody {
				tr {
					border: 1px solid #f1efef;
					&.highest {
						background-image: linear-gradient(to right, #11a1ae, #50b8a9);
						color: #fff;
					}
				}
			}
		}
	}
}
