.client-account-view {
	height: 100vh;
	&__container {
		margin-top: 25px;
	}
	.org-profile {
		@include avatar(128px, 128px, #fb7c4d, 100%);
		text-align: center;
		line-height: 128px;
		font-size: 30px;
		font-weight: 800;
		color: white;
		> img {
			@include avatar(100%, 100%, #fb7c4d, 100%);
			object-fit: cover;
		}
	}
	.body-section {
		// min-width: 900px;
		background-color: $white;
		border-radius: 10px;
		position: relative;
		top: -20px;
		&--header {
			padding: 10px 20px;
			border-bottom: 1px solid rgba(#d0d3e0, 0.8);
			.title {
				font-size: 2rem;
				line-height: 3rem;
				font-weight: $semibold;
			}
			.material-icons {
				font-size: 18px;
				color: $black;
			}
		}
	}
	.account {
		&-grid {
			display: flex;
		}
	}
	.left {
		flex-basis: 12% !important;
		.section-header {
			max-width: 180px;
		}
		.nav-section {
			margin-top: 100px;
		}
	}
	.right {
		background: white;
		padding: 40px 30px;
		border-radius: 10px;
		box-shadow: 0 11px 12px 0 #c4cbc9;
		min-height: 550px;
	}
	.card-wrapper {
		background: white;
		padding: 40px 30px 0 32px;
		border-radius: 10px;
		box-shadow: 0 11px 12px 0 #c4cbc9;
		margin: 0 auto;
		max-width: 1155px;
		&-nav {
			min-width: 1020px;
			width: 1058px;
			max-width: 1058px;
			min-height: 550px;
			padding: 0px;
			border-radius: 0px;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		.card-body {
			width: 100%;
		}
	}
	.right-sec {
		width: calc(100% - 146px);
		position: relative;
		margin-left: 20px;
	}
	.header-section {
		min-height: 170px;
		// margin-bottom: 1.7rem;
		&-type {
			background: linear-gradient(136.15deg, #3a435e 0%, #243c47 41.53%, #393f53 100%);
			border-radius: 10px;
		}
		.header-body {
			padding: 20px;
			.material-icons {
				color: $white;
				font-size: 17px;
			}
			.settings-icon {
				color: #393f53 !important;
				font-size: 19px !important;
			}
			.onboard {
				color: $black;
			}
			.title {
				font-weight: $semibold;
				font-size: 2.4rem;
				line-height: 36px;
				color: $white;
			}
			.website {
				color: rgba($white, 0.7);
				font-weight: $medium;
				font-size: 1.4rem;
				line-height: 1.7rem;
				margin: 1rem 0;
			}
			.add-info {
				color: rgba($white, 0.7);
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
				margin: 1rem 0;
			}
			.btn--round {
				padding: 0.6rem 1.5rem;
				color: #169ef8;
				font-size: 1.3rem;
				font-weight: $medium;
				line-height: 1.6rem;
				border: 1px solid #169ef8;
				border-radius: 14px;
			}
		}
		.heading-level1 {
			margin-bottom: 0.6rem;
		}
		.website {
			color: $primary-text-color;
			font-size: 1.4rem;
			line-height: 1.7rem;
			margin-bottom: 0.8rem;
		}
		.add-info {
			color: rgba(#646a7c, 0.8);
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.7rem;
		}
		.btn--round {
			padding: 0.6rem 1.5rem;
			color: #169ef8;
			font-size: 1.3rem;
			font-weight: $medium;
			line-height: 1.6rem;
			border: 1px solid #169ef8;
			border-radius: 14px;
		}
	}
}

.client-account-view,
.bot-account-view,
.view-client-bot,
.publish-preview {
	.left {
		flex-basis: 17%;
	}
	.nav-tertiary {
		position: relative;
		bottom: 60px;
		padding: 0 20px;
		border-bottom: 1px solid rgba($border-secondary, 0.5);
		&__item {
			position: relative;
			padding: 20px 0px;
			color: #393f53;
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.7rem;
			padding-bottom: 1rem;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0px;
				width: 100%;
				height: 4px;
				background-color: $nav-bottom;
				opacity: 0;
				visibility: hidden;
				transition: opacity 300ms;
			}
			&:not(:last-child) {
				margin-right: 2.5rem;
			}
			&.active {
				font-weight: $bold;
				padding: 20px 0px;
				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	.nav-tertiary-client {
		position: relative;
		// border-bottom: 1px solid rgba($border-secondary, 0.5);
		.disabled {
			cursor: not-allowed;
		}
		&__item {
			display: flex;
			justify-content: flex-end;
			position: relative;
			cursor: pointer;
			padding: 5px 0;
			padding-right: 10px;
			color: #6f6f70;
			font-size: 1.6rem;
			font-weight: $medium;
			line-height: 1.7rem;
			min-height: 24px;
			color: rgba(57, 63, 83, 0.8);
			// padding-bottom: 1rem;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				width: 3px;
				height: 100%;
				background-color: $nav-bottom;
				opacity: 1;
				visibility: hidden;
				transition: opacity 300ms;
			}
			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
			&.active {
				font-weight: $semibold;
				color: $black;
				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.btn--box-wrap {
		@include avatar(40px, 40px, $white, 3px);
		border: 1px solid #dbd9e4;
		// position: absolute;
		// right: 30px;
		// top:18px;
		z-index: 1;
		> .material-icons {
			color: rgba($black, 0.5);
			font-size: 2rem;
		}
	}
	.table-info {
		margin-top: 25px;
		// max-width: 900px;
		margin: 50px auto;
		position: relative;
		top: -50px;
		background: white;
		padding: 20px 30px 30px 30px;
		border-radius: 1px;
		border-top: 1px solid rgba($border-secondary, 0.5);
		&-type {
			margin: 0;
			border-radius: 0px;
			border: none;
			top: 0;
			padding: 10px 40px 30px 40px;
		}
		&__header {
			padding: 1rem 0;
			border-bottom: 1px solid rgba($border-secondary, 0.5);
			.heading {
				// color: $primary-text-color;
				font-size: 1.4rem;
				// font-weight: $medium;
				// line-height: 1.7rem;
				color: #393f53;
				font-size: 1.4rem;
				font-weight: 600;
				line-height: 1.7rem;
				font-size: 18px;
				position: relative;
				bottom: 10px;
			}
		}
		&__body {
			.heading-level4 {
				margin: 1.8rem 0 2.4rem;
			}
			.heading-level5 {
				margin: 1.8rem 0 2.4rem;
				text-transform: uppercase;
				color: rgba(#393f53, 0.5);
			}
			.header-label {
				text-transform: capitalize;
				color: rgba(57, 63, 83, 0.6);
				font-weight: 600;
				margin-bottom: 1rem;
				margin-top: 1.5rem;
			}
			.table-ruler {
				border-bottom: 1px solid rgba(#979797, 0.4);
				margin: 1.5rem 0;
			}
			.cont-item {
				@include flexbox();
				margin-bottom: 1.7rem;
				.title,
				.desc {
					color: #393f53;
					font-size: 1.4rem;
					font-weight: $medium;
					line-height: 2rem;
					width: 45%;
					@include word-wrap();
				}
				.title {
					font-weight: 600;
				}
				.desc {
					width: 60%;
				}
				&--bg {
					padding: 2rem;
					border: 1px solid #dfe0e5;
					border-radius: 2px;
					background-color: $white;
				}
				.desc {
					color: rgba(#393f53, 0.8);
					// padding-left: 2rem;
				}
				.status-pill {
					width: initial;
					// margin-left: 2rem;
				}
			}
			.contact {
				@include flexbox();
				@include flex-direction(column);
				padding: 15px 20px;
				border: 2px solid #c3cdce;
				border-radius: 5px;
				&-title {
					text-transform: capitalize;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.3px;
					margin-bottom: 5px;
				}
				&-sub-title {
					opacity: 0.7;
					color: #393f53;
					font-size: 14px;
					font-weight: 300;
					letter-spacing: 0.3px;
					margin-bottom: 5px;
				}
				.material-icons {
					font-size: 17px;
					vertical-align: middle;
					margin-right: 5px;
				}
			}
		}
	}
}
.view-client-bot {
	.table-info {
		border-top: 1px solid rgba($border-secondary, 0.5);
		.status-pill.trail {
			color: #ffbb29;
			border: 1px solid rgba(255, 187, 41, 0.31);
			border-radius: 12px;
			background-color: #fff6e5;
		}
	}
}
.view-client-bot,
.publish-preview {
	.cont-item-copy {
		position: relative;
		@include flex-direction(column);
		.btn-wrap {
			width: 99%;
			height: 69px;
			padding-top: 7px;
			position: absolute;
			left: 1px;
			bottom: 2px;
			background-color: $white;
			z-index: 1;
			> .btn--copy-code {
				position: relative;
				left: 15px;
				width: 140px;
			}
			.info {
				font-size: 12px;
				font-weight: 500;
				color: $red;
				margin-left: 14px;
			}
		}
		textarea {
			width: 100%;
			padding-bottom: 100px;
		}
	}
}
.bot-preview {
	margin-top: -3px !important;
	overflow: hidden;
}
.bot-account-view,
.view-client-bot,
.publish-preview {
	.section-body {
		position: relative;
		top: 25px;
	}
	.section-header {
		padding: 10px 20px;
		&--type2 {
			padding: 0px;
			position: relative;
			top: 15px;
			padding-right: 20px;
		}
		&__right {
			z-index: 9;
		}
		.btn--delete .material-icons {
			color: rgba($primary-text-color, 0.6);
		}
	}
}
.publish-preview {
	.status-pill.trail {
		border-radius: 2px;
	}
}
.border-right {
	border-right: 1px solid rgba($border-secondary, 0.5);
}

.tags {
	&-list {
		display: inline-block;
		padding: 1rem;
		color: #2f57e6;
		font-size: 1.4rem;
		font-weight: $medium;
		line-height: 17px;
		border-radius: 2px;
		background-color: #e6ecff;
		margin: 0.3rem;
	}
}
.cont-wrap-new {
	max-height: 440px;
	overflow: auto;
}
.contact-info {
	.material-icons {
		font-size: 14px;
		vertical-align: middle;
		margin-right: 10px;
		color: #5e7281;
	}
}
.org-info {
	.material-icons {
		font-size: 20px;
		vertical-align: middle;
		margin-right: 10px;
		color: #5e7281;
	}
}
.privacy-link {
	color: #3856de;
}
