.payment {
	padding: 20px;
	overflow-x: auto;
	&-public {
		height: 100vh;
		background: #f8f8f8;
	}
	&-title {
		padding: 1.5rem 0;
		font-weight: $semibold;
		font-size: 2.2rem;
	}
	&-body {
		background: #f8f8f8;
		.section {
			position: relative;
			margin-top: 50px;
		}
		.card {
			background: $white;
			border-radius: 10px;
			width: 900px;
			margin: 0 auto;
			padding: 1.5rem 2.5rem;
			box-shadow: 0 11px 12px 0 #c4cbc9;
			&.stripe {
				width: 530px;
				padding: 2em 4rem;
				border-radius: 5px;
				.title {
					color: #0854b3;
					padding: 1.5rem 0;
					font-weight: $medium;
					line-height: 1.1;
					font-size: 2.8rem;
				}
				.stripe-element {
					.stripe-custom {
						width: 100%;
						padding: 11px 15px 11px 0;
						font-size: 1.4rem;
						line-height: 2rem;
						padding: 0.9rem 1.6rem;
						background: #fff;
						border: 1px solid #dadada;
						border-radius: 2px;
						transition: border 300ms;
						&.StripeElement--focus {
							border-color: $primary-brand-color--light;
						}
					}
				}
				.card {
					&-error {
						position: absolute;
						width: 100%;
						top: 100%;
						left: 0;
						opacity: 0;
						visibility: hidden;
						min-height: 25px;
						padding: 0.5rem 0;
						font-size: 1.4rem;
						font-weight: $medium;
						color: rgba(#a70505, 0.6);
						transform: translateY(10px);
						transition-property: opacity, transform;
						transition-duration: 0.35s;
						&.show {
							opacity: 1;
							visibility: visible;
							transform: none;
						}
					}
				}
			}
			&--body {
				margin-bottom: 50px;
				&--confirmation {
					margin-bottom: 30px;
				}
				.label {
					&-text {
						font-size: 1.4rem;
						font-weight: $medium;
						color: rgba(#1c2e59, 0.6);
						&-sub {
							color: #1c2e59;
							padding-top: 0.5rem;
						}
					}
					&-sub {
						font-size: 1.4rem;
						color: #393f53;
						line-height: 2.1rem;
					}
				}
				.method {
					border: 2px solid #c8c7c7;
					border-radius: 3px;
					height: 69px;
					width: 216px;
					background-color: #ffffff;
					color: $primary-text-color;
					cursor: pointer;
					&--label {
						line-height: 2.4rem;
						font-size: 1.6rem;
						font-weight: $semibold;
					}
					&:hover {
						border: 2px solid #11a1ae;
					}
				}
				.confirmation {
					&-container {
						margin-top: 20px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
					}
					&-icon-wrap {
						width: 110px;
						height: 110px;
						background-color: #28a25f;
						border-radius: 50%;
						min-width: 110px;
						margin-bottom: 9px;
						.done {
							font-size: 70px;
							color: $white;
						}
					}
				}
			}
			&--footer {
				padding: 2rem;
				border-top: 1px solid rgba(#979797, 0.6);
				.btn-text {
					font-weight: $semibold;
					line-height: 2.2rem;
					padding-left: 4.5rem;
					padding-right: 4.5rem;
				}
			}
		}
	}
}

.payment-method {
	position: relative;
	border: 2px solid #c8c7c7;
	border-radius: 3px;
	height: 69px;
	width: 216px;
	background-color: #ffffff;
	color: $primary-text-color;
	cursor: pointer;
	&--label {
		line-height: 2.4rem;
		font-size: 1.6rem;
		font-weight: $semibold;
		color: #393f53;
	}
	&--sub-label {
		font-size: 1.4rem;
		font-weight: $medium;
		color: rgba(#1c2e59, 0.6);
	}
	.copy {
		position: absolute;
		right: 3px;
		top: 3px;
		color: #dadada;
		&:hover {
			color: black;
		}
	}
	&:hover {
		border: 2px solid #11a1ae;
	}
}
.payment-plan-detail-title {
	font-size: 2rem !important;
	color: #393f53;
	font-weight: 600;
	span {
		color: #393f53;
	}
	.material-icons {
		vertical-align: bottom;
	}
}
.footer-logo {
	padding: 1rem 0rem;
	img {
		width: auto !important;
		height: 45px !important;
	}
}
.invoice-modal {
	width: 593px;
}
.payment_note {
	width: 385px;
}
.payment-type {
	padding: 2rem 0;
}
.payment-success-page-footer {
	display: flex;
	padding-left: 2rem;
	.note-icon {
		.material-icons {
			transform: rotate(180deg);
			border-radius: 50%;
			background: #337af6;
			color: white;
			color: white;
			font-size: 18px;
		}
	}
	.note-text {
		margin-left: 15px;
		color: #737373;
		font-size: 11px;
		p {
			font-weight: 600;
		}
		.note-text-1 {
			line-height: 10px;
		}
	}
}
.payment-success-page-card {
	padding: 3vw 5vw !important;
	max-width: 800px !important;
	min-width: 390px;
	position: relative;
	margin: 0 !important;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 11px 12px 0 #c4cbc9;
	.card--body--confirmation {
		margin-bottom: 30px !important;
		.confirmation-container {
			margin-top: 0 !important;
			display: flex;
			flex-direction: column;
			align-items: flex-start !important;
			.confirmation-container-body {
				width: 100%;
				position: relative;
				.line-connecting-stages {
					height: 74.5%;
					border: 2px solid #f0f4f7;
					position: absolute;
					top: 11.5%;
					left: 23.5px;
					background: #f0f4f7;
				}
				.active-stage {
					padding-left: 7px !important;
					.active-stage-ring {
						display: block !important;
						padding: 2px;
						border-radius: 50%;
						border: 2px solid #5bc553;
						background: white;
					}
					.done {
						font-size: 20px !important;
					}
					.stage-description {
						margin-left: 1.6rem !important;
					}
				}
				.incomplete-stage {
					.done {
						background-color: #eae7e7 !important;
						font-size: 20px !important;
					}
				}
				.stage {
					display: flex;
					align-items: center;
					margin-bottom: 2rem;
					position: relative;
					z-index: 9;
					padding-left: 1rem;
					.done {
						font-size: 25px;
						color: white;
						background-color: #5bc553;
						border-radius: 50%;
						padding: 2px;
						width: 30px;
						height: 30px;
						font-weight: 600;
					}
					.stage-description {
						flex-grow: 1;
						border: 2px solid #f4f6f9;
						border-radius: 7px;
						padding: 1.2rem 2rem;
						margin-left: 2rem;
						.description-container {
							flex-grow: 1;
						}
						.decription-timer {
							color: #a3a3a3;
						}
						.stage-description-title {
							font-size: 17px;
							font-weight: bold;
							color: #1c1c1c;
						}
						.stage-description-content {
							color: #646464;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
.payment-success-page-section {
	margin-top: 0 !important;
	z-index: 9;
	height: 100vh;
	.john-img-background-container {
		position: absolute;
		height: 67px;
		overflow: hidden;
		right: 40px;
		top: -68px;
	}
	.john-img-background {
		width: 140px;
		background-color: #337af6;
		height: 150px;
		border-radius: 50%;
	}
	.john-image-container {
		height: 100px;
		position: absolute;
		overflow: hidden;
		right: 60px;
		top: -101px;
	}
	.john-image {
		width: 100px;
	}
}
.payment-success-page-top-section {
	display: flex;
	margin: 20px 20px 50px 20px;
	padding-top: 45px;
	align-items: center;
	.right-section {
		width: 315px;
		font-size: 30px;
		flex-grow: 1;
	}
	@media (max-width: 800px) {
		margin: 0px 0 150px 0;
		padding-top: 20px;
	}
}
.payment-success-page-section-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	// margin: 0 269.5px;
	.left-section {
		max-width: 299px;
		padding: 1rem;
		border: 2px solid #f8c674;
		position: absolute;
		z-index: 9;
		top: -120px;
		right: 155px;
		border-radius: 5px;
		background: #fff;
		p {
			font-size: 12px;
			font-weight: bold;
			color: #646464;
			margin-bottom: 5px;
		}
		h5 {
			font-weight: bold;
			color: #1c1c1c;
			font-size: 14px;
			line-height: 16px;
		}
	}
}
.public-p-m-container {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 100vh;

	@include respond-to(mobile) {
		flex-direction: column;
		justify-content: start;
	}
	.header-logo {
		height: 60px;
		padding: 16px 16px 16px 16px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		margin-bottom: 0;
		background-color: #fff;
		overflow-y: hidden;
		border-bottom: 1px solid #f5f5f5;
		display: none;
		@media (max-width: 600px) {
			display: flex;
		}
		&.show-plain-breakdown {
			border-bottom: none;
			z-index: 100;
		}
		.header-content {
			.bot-logo {
				width: 131px;
				@include respond-to(mobile) {
					width: 100%;
				}

				img {
					object-fit: contain;
					height: 28px;
				}
			}
		}
	}
	.plan-modal {
		z-index: 99;
		.modal-container {
			background-color: #fff;
			margin-top: 5rem;
		}
	}
	.header-payment-breakdown {
		height: 200px;
		position: absolute;
	}
	.payment-overlay {
		opacity: 0.5;
		background-color: #000000;
		position: absolute;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		width: 100%;
		height: 100%;
	}
	.tax-discount-container {
		border-top: 1px solid #eff0f1;
		border-bottom: 1px solid #eff0f1;
		margin-top: 16px;
		padding-top: 16px;
		padding-bottom: 16px;
	}
	.other-variable {
		&.discount-section {
			.discount-coupon-card {
				background-color: #eff0f1;
				border-radius: 7px;
				padding: 8px;
				padding-right: 10px;
				width: fit-content;
				.material-icons {
					font-size: 16px;
				}
			}
			.discount-secondary-text {
				color: #a3a3a3;
				font-size: 15px;
			}
		}
		justify-content: space-between;
		margin-top: 36px;
		font-size: 15px;
		font-weight: 600;
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu,
			sans-serif;
		color: rgba(26, 26, 26, 0.9);
		.variable-property {
			text-align: right;
			.property-value-detail {
				color: rgba(26, 26, 26, 0.5);
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
	.left-container,
	.right-container {
		width: 50%;
		height: auto;
	}
	.left-container {
		padding-right: 80px;
		@include respond-to(mobile) {
			padding-right: 0;
			width: 100%;
			margin-top: 80px;
		}
		.inner-container {
			float: right;
			width: 380px;
			-webkit-transform: translateY(48px);
			-ms-transform: translateY(48px);
			transform: translateY(48px);
			margin-top: 84px;

			@include respond-to(mobile) {
				float: left;
				width: 100%;
				margin-top: 0 !important;
				transform: initial;
			}
			.container-footer {
				display: flex;
				margin-top: 5rem;
				color: #a3a3a3;
				a {
					color: #a3a3a3;
				}
				@include respond-to(mobile) {
					position: fixed;
					bottom: 0;
					width: 100%;
					display: flex;
					margin-top: 0 !important;
					justify-content: center;
					margin-bottom: 25px;
				}
			}
		}
		.section-header-logo {
			@include respond-to(mobile-sm) {
				display: none;
			}
			@include respond-to(mobile) {
				display: none;
			}
			@include respond-to(mobile-ls) {
				display: none;
			}

			.header-content {
				.bot-logo {
					width: 131px;

					img {
						object-fit: contain;
						height: 28px;
					}
				}
			}
		}
		.plan-detail-container {
			margin-top: 33px !important;
			@include respond-to(mobile) {
				margin-top: 0px !important;
				display: flex;
				justify-content: center;
				text-align: center;
			}
			.price-container {
				.pay-to {
					color: rgba(26, 26, 26, 0.6);
					font-weight: 500;
					font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu,
						sans-serif;
					font-size: 16px;
				}
				.pay-price {
					font-size: 36px;
					font-weight: 600;
					margin: 15px 0;
					@include respond-to(mobile) {
						margin: 10px 0;
					}
				}
				.pay-per-month {
					color: rgba(26, 26, 26, 0.6);
					font-weight: 500;
					font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu,
						sans-serif;
					font-size: 14px;
				}
				.view-details {
					display: none;
					@include respond-to(mobile) {
						display: flex;
						color: rgba(26, 26, 26, 0.6);
						font-weight: 500;
						justify-content: center;
						margin-bottom: 5px;
					}
				}
				.view-details-wrap {
					display: none;
					@include respond-to(mobile) {
						display: flex;
						flex-direction: column;
					}
					.view-details-row {
						display: none;
						&.discount-section {
							border-top: 1px solid #eff0f1;
							border-bottom: 1px solid #eff0f1;
							margin-top: 16px;
							padding-top: 16px;
							padding-bottom: 16px;
							.discount-coupon-card {
								background-color: #eff0f1;
								border-radius: 7px;
								padding: 10px;
							}
							.discount-secondary-text {
								color: #a3a3a3;
								font-size: 15px;
							}
						}
						@include respond-to(mobile) {
							width: 348px;
							justify-content: space-between;
							margin-bottom: 5px;
						}

						.detail-title {
							@include respond-to(mobile) {
								font-weight: 500;
								font-size: 14px;
							}
						}

						.detail-property {
							@include respond-to(mobile) {
								font-weight: 500;
								font-size: 14px;
							}
							.day {
								text-align: end;
							}
						}

						.detail-property-time {
							@include respond-to(mobile) {
								color: #a3a3a3;
							}
						}
					}
				}
				.show {
					display: flex;
				}
			}
			.isDesktop {
				@include respond-to(mobile) {
					display: none !important;
				}
			}
			.title {
				margin-top: 15px !important;
			}
		}
		.container-footer {
			.left {
				padding-right: 20px;
				border-right: 1px solid #a3a3a3;
			}
			.right {
				padding-left: 20px;
			}
		}
	}
	.right-container {
		padding-left: 80px;
		box-shadow: 15px 0 30px 0 #0000002e;
		@include respond-to(mobile) {
			padding-left: 0;
			width: 100%;
			box-shadow: none;
		}
		.inner-container {
			width: 380px;
			-webkit-transform: translateY(48px);
			-ms-transform: translateY(48px);
			transform: translateY(48px);
			margin-top: 84px;
			@include respond-to(mobile) {
				width: 100%;
				margin: 0;
				transform: translateY(0px) !important;
				padding: 0px 24px;
			}
			.proceed-btn {
				@include respond-to(mobile) {
					margin-top: 20px;
				}
			}
			.container-footer {
				display: flex;
				margin-top: 5rem;
				color: #a3a3a3;
				a {
					color: #a3a3a3;
				}
				.left {
					padding-right: 20px;
					border-right: 1px solid #a3a3a3;
				}
				.right {
					padding-left: 20px;
				}
			}
		}
		.container-header {
			h2 {
				font-size: 18px;
			}
			@include respond-to(mobile) {
				margin-top: 30px;
				text-align: center;
			}
		}
		.payment-m-button-container {
			.btn {
				font-size: 18px;
				padding: 2rem 2.8rem;
				border-radius: 5px;
				min-width: 179px;
				.material-icons {
					margin-right: 0.9rem;
				}
			}
			.selected {
				border: 1px solid #006bdb;
				box-shadow: 0 3px 8px -4px #006bdb;
				position: relative;
			}
			.selected::after {
				content: '\2713';
				position: absolute;
				top: -10px;
				right: -5px;
				width: 22px;
				transform: scale(1);
				height: 22px;
				background: #18a0fb;
				color: #fff;
				border-radius: 50%;
				border: 1px solid #fff;
				box-shadow: 0px 4px 8px -3px #333;
				font-size: 15px;
			}

			@include respond-to(mobile) {
				margin-top: 28px !important;
			}
		}
		.proceed-btn {
			margin-top: 10rem;
			padding: 1.3rem;
			font-size: 16px;
			background: #006bdb;
			color: #fff;
			border-radius: 5px;
			line-height: 2rem;
			font-weight: 500;
			border: 1px solid;
			border-color: #dbd9e4;
			cursor: pointer;
			transition: all 0.3s ease;
		}
	}
}
