$white: #fff !default;
$black: #000 !default;
$grey: #ecf0f1;
$green: #28a25f;
$red: #e74c3c;
$blue: #3498db;
$orange: #e67e22;
$info: #337af6;

$primary-brand-color: #263ead;
// $primary-brand-color--light: #3856de;
$primary-brand-color--light: #11a1ae;
$secondary-brand-color: #ffd082;
// $secondary-brand-color: #fbbe4d;
$primary-text-color: #393f53;
// $border-color: #aeb4b8;
$border-color: #dadada;
$border-secondary: #979797;
$dark: #33516a;
$nav-bottom: #ec5959;
$filter-background: #f3f7f9;
$filter-text: #6d90ad;
$filter-border: #eff0f1;
$comment-background: #fef6ee;
$filter-type-color: #398ba3;
// /* font weight */
$very-light: 100;
$light: 300;
$light-italic: 300;
$regular: 400;
$regular-italic: 400;
$medium: 500;
$semibold: 600;
$semibold-italic: 600;
$bold: 700;
$bold-italic: 700;

/* img path */
$img-path: '../img';
$img-icons-path: '../img/icons';

/* break points*/
$bp-mobile-sm: 320px;
$bp-mobile: 576px;
$bp-mobile-ls: 768px;
$bp-tablet: 992px;
$bp-tablet-ls: 1024px;
$bp-large: 1200px;
