.privacy-policy {
	background: white;
	padding: 80px 90px;
	border-radius: 10px;
	// box-shadow: 0 11px 12px 0 #c4cbc9;
	max-height: 100vh;
	overflow: auto;
	.logo {
		margin-top: -71px;
		margin-left: -14px;
		> img {
			height: 100px;
			width: 50px;
			margin-right: 10px;
		}
	}
	.content-body {
		align-items: baseline;
		text-align: justify;
		.privacy-header {
			margin-bottom: 15px;
		}
		.privacy-para {
			margin-bottom: 15px;
		}
		.link{
			width: unset;
			overflow: inherit;
		}
	}
}
