.email-section {
	width: 775px;
	&--container {
		padding: 1rem 1rem 1rem 1.5rem;
		border: 1px solid rgba($border-color, 0.5);
		margin-bottom: 2rem;
		.sub-sec-title {
			color: $primary-text-color;
			font-size: 1.4rem;
			line-height: 1.7rem;
		}
	}
	.cont-wrap {
		padding: 0 4rem 2rem 0;
		border-bottom: 1px solid rgba($border-color, 0.5);
		.logo {
			&-title {
				color: $primary-text-color;
				font-size: 1.4rem;
				font-weight: $semibold;
				line-height: 1.7rem;
			}
			.zappier {
				width: 20px;
				height: 20px;
				margin-right: 0.5rem;
			}
		}
		.list {
			&-item {
				padding: 0.7rem 0;
				color: $primary-text-color;
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
				.item-title {
					min-width: 150px;
					> a {
						color: $primary-text-color;
					}
				}
				.material-icons {
					font-size: 18px;
					cursor: pointer;
				}
			}
		}
		.title {
			color: $primary-text-color;
			font-size: 1.4rem;
			font-weight: $bold;
			line-height: 1.7rem;
			text-transform: uppercase;
		}
		.sub-sec-title {
			color: $primary-text-color;
			font-size: 1.4rem;
			line-height: 1.7rem;
		}
		.link {
			font-size: 1.4rem;
			> .material-icons {
				font-size: 2rem;
			}
		}
		.material-icons.delete {
			position: absolute;
			right: -20px;
			top: 8px;
			cursor: pointer;
		}
	}
	&--edit {
		.integration-settings__body {
			padding-bottom: 3rem;
			border-bottom: 1px solid rgba($border-color, 0.5);
		}
	}
	&--view {
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			border-radius: 22px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 30px;
			background-color: $white;
			box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31);
			&:not(:first-of-type) {
				margin-top: 10px;
			}
			&__cont {
				color: var(--primary-text-color);
				font-weight: $medium;
				letter-spacing: 0.2px;
				line-height: 1.9rem;
			}
		}
	}
	.variable {
		font-weight: $medium;
	}
}
.email_container {
	border: 1px solid #dbdbdd;
	position: relative;
	.editor-container {
		border: 0 !important;
		border-top: 1px solid #dbdbdd !important;
		.editor-body {
			.DraftEditor-root {
				height: 100%;
				.DraftEditor-editorContainer {
					height: 100%;
					.public-DraftEditor-content {
						height: 100%;
					}
				}
			}
		}
	}
}
.save-template {
	@include flexbox();
	cursor: pointer;
	align-items: center;
	.material-icons {
		font-size: 17px;
		color: #393f53;
		margin-right: 3px;
	}
	.label-template {
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		color: #393f53;
	}
}
.email_to {
	padding: 10px;
	border-radius: 4px;
	@include respond-to(mobile) {
		padding: 12px 0px;
		padding-bottom: 0px;
		.label-value {
			padding: 8px 12px;
			// padding-bottom: 5px;
			margin-bottom: 0px !important;
			border-bottom: 1px solid #f1f1f1;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	.label-value {
		margin-bottom: 10px;
	}
	.label {
		color: #393f53;
		font-weight: $medium;
		@include respond-to(mobile) {
			font-size: 1.45rem;
		}
	}
	.value {
		color: #393f53;
		font-size: 13px;
		line-height: 19px;
		min-width: calc(100% - 10%);
		word-break: break-all;
		padding-right: 10px;
		&.placeholder {
			color: rgba(57, 63, 83, 0.6);
		}
		@include respond-to(mobile) {
			font-size: 1.45rem;
		}
	}
	.email-text {
		color: rgba(#393f53, 0.9);
		line-height: 19px;
		padding: 0;
		border: 0;
		min-width: 120px;
		background: transparent;
		width: 100%;
		@include respond-to(mobile) {
			font-size: 1.45rem;
		}
		&.error {
			color: red;
		}
	}
}

.phone-number {
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
}

.email_section {
	border-top: 1px solid #dbdbdd !important;
	padding: 10px;
	display: flex;
	.open {
		.material-icons {
			transform: rotate(180deg);
		}
	}

	.email_adduser {
		flex-grow: 1;
		position: relative;
		&.open {
			.sub-content {
				display: block !important;
			}
		}

		.user_selection_header {
			display: inline-flex;
			align-items: center;
			cursor: pointer;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
			.selected_user {
				border-left: 1px solid #dadada;
				padding-left: 5px;
				max-width: 510px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
		.sub-content {
			display: none;
			width: max-content;
			position: absolute;
			background: white;
			z-index: 99;
			border: 1px solid #dadada;
			padding: 10px;
			.userOption {
				overflow-x: auto;
				max-width: 550px;
				cursor: pointer;
				&.active {
					background-color: #96edf5;
					border-color: #11a1ae;
					border: 1px solid;
					overflow-wrap: break-word;
				}
			}
			.userOption:hover {
				background-color: #96edf5;
			}
			&-list {
				height: 142px;
				overflow: scroll;
			}
		}
	}
}
.email_subject {
	display: flex;
	.subject_header {
		font-weight: 500;
		padding: 10px;
	}
	.subject_text {
		padding-top: 0.7rem;
		padding-left: 0.2rem;
		border: none;
		padding-bottom: 0.8rem;
		flex-grow: 1;
		padding-right: 10px;
	}
	.email-name-error {
		position: relative;
		top: 11px !important;
		right: 290px !important;
		// display: block;
	}
	.subject-error {
		right: 240px !important;
	}
}
.sms-area {
	width: 100%;
	padding: 11px;
	outline: none;
	border: none;
	border-top: 1px solid #dbdbdd;
	&:focus {
		border-color: #dbdbdd;
	}
}
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 2;
}
.edit-email-icon {
	color: #393f53 !important;
}
.email-item {
	display: flex;
	border: 1px solid #dbd9e4;
	justify-content: space-between;
	padding: 7px !important;
	border-radius: 9px;
}
.email-list-action-icon {
	padding: 0 1rem;
}
.email_addvariable {
	position: relative;
	.sub-content {
		width: max-content;
		position: absolute;
		background: white;
		z-index: 9;
		border: 1px solid #dadada;
		padding: 10px;
		right: 5px;
	}
}
.email_subject {
	.sub-content {
		display: none;
	}
	.material-icons {
		position: relative;
		top: 7px;
		cursor: pointer;
	}
	.open {
		.sub-content {
			display: block !important;
		}
		.material-icons {
			transform: rotate(180deg);
		}
	}
}
