/**
 Styles for Pipeline Version 2
 */

.pipeline-container {
	min-height: calc(100vh - 54px);
	padding: 2rem 4rem;

	.pipeline-header-section {
		.pipeline-title {
			color: #1c2e59;
			font-size: 18px;
			font-weight: 600;
		}

		.pipeline-sub-title {
			color: #1c2e59;
			font-size: 16px;
		}

		.pipeline-header-links {
			.link-watch {
				color: #11a1ae;
				font-weight: 600;
			}

			.link-help {
				color: #1c2e59;
				font-weight: 600;

				.link-outlined {
					color: #7b7b7b;
				}
			}
		}
	}

	.pipeline-list-section {
		.list-title {
			color: #1c2e59;
			font-size: 18px;
			font-weight: 600;
		}

		.pipeline-list {
			height: calc(100vh - 240px);
			overflow-y: auto;
			padding-bottom: 60px;

			.pipeline-list-items {
				gap: 30px;
				flex-wrap: wrap;
				// overflow-y: scroll;
				padding-top: 2px;

				.pipeline-item {
					padding: 2.8rem;
					background-color: #ffffff;
					width: 234px;
					justify-content: center;
					box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

					.pipeline-menu {
						position: absolute;
						right: 10px;
						top: 12px;
						color: #7d7d7d;

						.dropdown {
							border: 1px solid #e5e5e5;
							box-shadow: none;

							&::before {
								border-bottom: 10px solid #e5e5e5;
							}
						}
					}

					.pipeline-content {
						text-align: center;
						.pipeline-initial-info {
							padding: 10px;
							border-radius: 50%;
							width: 40px;
						}
						.pipeline-name {
							color: #4c505e;
							font-size: 16px;
							margin-bottom: 12px;
							font-weight: 600;
							white-space: nowrap;
							width: 186px;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.pipeline-type {
							color: #777a82;
						}
					}
				}

				.pipeline-add-wrap {
					background-color: #fff;
					width: 234px;
					justify-content: center;
					border: 2px dashed #a1e9f0;

					.add-content {
						text-align: center;
						margin-top: 68px;
						.add-icon {
							padding: 12px;
							background: rgba(0, 171, 186, 0.22);
							color: #00abba;
							font-size: 32px;
							border-radius: 7px;
						}

						.add-title {
							color: #777a82;
						}
					}
				}
			}
		}
	}
}

.bot-connection-modal {
	.modal-container {
		width: 488px;
		margin-left: auto;
		margin-right: auto;
	}

	.bot-connection-body {
		.connection-img {
			text-align: center;
			margin-bottom: 26px;
		}

		.connection-text-wrap {
			text-align: center;
			.title {
				color: #393f53;
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 6px;
			}

			.desc {
				color: #393f53;
				white-space: break-spaces;
			}
		}
	}

	.bot-connection-footer {
		justify-content: center;
		padding-bottom: 5.5rem !important;

		.btn--save {
			width: max-content !important;
		}
	}
}

.delete-pipeline-modal {
	.delete-pipeline-container {
		width: 510px !important;
		height: auto !important;
		min-height: 510px !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%);
		.modal__body {
			padding: 0px 5rem 5rem 5rem;
			height: auto;
			.delete-icon {
				text-align: center;
				> img {
					height: 220px;
					width: 220px;
				}
			}
			.delete-pipeline-text {
				font-size: 14px;
				font-weight: 400;
				color: #393f53;
			}
		}
	}
}

.upgrade-pro-plan-modal {
	background: none;
	&-container {
		width: 510px !important;
		height: auto !important;
		min-height: 510px !important;

		&-only-modal {
			top: 50% !important;
			left: 50% !important;
			transform: translate(-50%, -50%);
		}

		&.medium {
			width: 400px !important;
			background-color: $white;
			height: max-content !important;
			min-height: 470px !important;

			.learn-more-wrap {
				padding-bottom: 35px;
			}
		}
		.upgrade-plan-header {
			padding: 3rem 0;
			text-align: center;
			background-color: rgba(17, 161, 174, 0.24);
			// background-color: rgba(17, 161, 174, 0.08);
			position: relative;
			@include flexbox();
			justify-content: center;
			align-items: center;
			.close-icon {
				position: absolute;
				right: 10px;
				top: 10px;
				cursor: pointer;
			}
			.upgrade-icon-wrap {
				@include flexbox();
				justify-content: center;
				align-items: center;
				padding: 17px;
				background-color: #ffffff;
				box-shadow: -1px 1px 8px #b8e8f0;
				border-radius: 50%;

				.lock-icon {
					color: #11a1ae;
				}
			}
		}
		.upgrade-plan-body {
			margin-top: 18px;
			margin-bottom: 40px;
			.pro-text-wrap {
				justify-content: center;
				margin-bottom: 25px;
				color: #393f53;
				font-size: 12px;
				font-weight: 600;
				text-transform: uppercase;
				.pro-text {
					background: #ffc851;
					border-radius: 2px;
					padding: 3px 5px 3px 6px;
					margin-right: 4px;
				}
			}
			.upgrade-title {
				color: #393f53;
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin-bottom: 12px;
			}
			.upgrade-desc {
				color: #666666;
				padding: 0 66px;
				font-size: 14px;
				text-align: center;
			}
		}
		.speak-to-sales-wrap {
			justify-content: center;
			.speak-to-sales-btn {
				background-color: #00abba;
				color: #ffffff;
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				padding: 10px 30px;
			}
		}
		.learn-more-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			.learn-more-text {
				color: #11a1ae;
				font-size: 14px;
				font-weight: 700;
				margin-right: 0.27rem;
			}
			.open-icon {
				font-size: 12px;
				font-weight: 600;
				color: #11a1ae;
			}
		}
	}
}

.settings-pipeline-modal {
	@include respond-to(mobile) {
		// position: unset;
	}
	.back-arrow {
		cursor: pointer;
		font-size: 16px;
	}
	.modal__body {
		@include respond-to(mobile) {
			height: calc(100vh - 210px);
		}
	}
	.modal__footer {
		@include respond-to(mobile) {
			bottom: 47px;
		}
	}
	.settings-pipeline-container {
		height: 100%;
		.home-page {
			padding: 10px;
			.settings-item {
				cursor: pointer;
				border-bottom: 1px solid #ebebeb;
				& > .material-icons {
					font-size: 34px;
					margin-right: 20px;
					margin-top: 10px;
				}
				& > .settings-btn {
					color: #f9a89e;
				}
				& > .stages-btn {
					color: #fff176;
				}
				& > .connect-btn {
					color: #18a0fb;
				}
				&__section {
					margin-bottom: 16px;
					margin-top: 12px;
					.settings-title {
						font-size: 16px;
						font-weight: 700;
						margin-bottom: 5px;
					}
					.settings-description {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}

		.pipeline-settings {
			.priority-text {
				color: #11a1ae;
				font-size: 12px;
				font-weight: 600;
				padding: 0px 4px;
				margin-right: 1.5rem;
				cursor: pointer;
			}
			.bg-makefirst {
				background: #e8f4f5;
			}
			.card-design-field {
				font-size: 14px;
				font-weight: 400;
				border: 1px solid #dadada80;
				width: 100%;
				padding: 10px 15px;
			}
			.custom {
				background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
					no-repeat right #ddd;
				-webkit-appearance: none;
				background-position-x: 96%;
				background-color: #ffffff !important;
				border-color: #dadada80 !important;
			}
			&__label {
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 10px;
			}
			&__sub-label {
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 10px;
			}
			.pipeline-order-list {
				width: 100%;
				&__item {
					margin-bottom: 20px;
					width: 100%;
					.drag-icon {
						color: #00abba;
					}
					.pipeline-order-text {
						font-size: 14px;
						font-weight: 400;
						border: 1px solid #c2c2c2;
						width: 100%;
						padding: 10px;
						background-color: #f3f7f9;
					}
				}
			}
			.channels-list {
				border: 2px solid $border-color;
				border-radius: 10px;
				padding: 10px 10px;
			}
			.connect-channels-radio {
				padding: 1rem 3rem;
				.item {
					&:last-child {
						margin-top: 10px;
					}
				}
				.radio-button {
					cursor: pointer;
				}
				.icon {
					position: relative;
					@include avatar(13px, 13px, $white, 100%);
					border: 2px solid $border-color;
					transition: all 300ms;
				}
				.label-txt {
					width: calc(100% - 30px);
					color: #2d3762;
					font-size: 1.4rem;
					font-weight: $regular;
					line-height: 1.6rem;
					margin-left: 1rem;
				}
				input:checked + label {
					.icon {
						border: 2px solid #12b13f;
						&:after {
							content: '';
							@include avatar(13px, 13px, #12b13f, 100%);
							position: absolute;
							top: -2px;
							left: -2px;
						}
					}
					.icon-primary {
						border: 2px solid $primary-brand-color--light;
						&:after {
							@include avatar(13px, 13px, $primary-brand-color--light, 100%);
						}
					}
				}
			}
		}
		.pipeline-stages-page {
			&__drag-icon {
				color: #11a1ae;
			}
			.pipeline-stages-list {
				width: 100%;
				&__item {
					// margin-bottom: 20px;
					width: 100%;
					.pipeline-stages-text {
						font-size: 14px;
						font-weight: 400;
						border: 1px solid #c2c2c2;
						width: calc(100% - 56px);
						padding: 10px;
					}
				}
			}
			&__add-new {
				margin-left: 24px;
				font-size: 14px;
				font-weight: 700;
				cursor: pointer;
				color: #11a1ae;
			}
			.v-hidden {
				visibility: hidden;
			}
			.move-left {
				position: relative;
				right: 24px;
			}
		}
	}
}

.pipeline-bot-connections {
	& > .connections-container {
		border: 1px solid #dddddd;
		text-align: center;
		margin: 4rem 3rem;
		padding: 2rem;
		.bot-connections-title {
			font-weight: 700;
			font-size: 16px;
		}
		.bot-connections-sub-title {
			font-weight: 400;
			font-size: 14px;
			color: #5e7281;
		}
		.bot-connections-sub-link {
			color: #11a1ae;
		}
	}
}

.bot-connections-section {
	padding: 2.5rem 3rem !important;
	.connection-header {
		.title {
			color: #1c2e59;
			font-size: 16px;
		}

		.desc {
			color: #5e7281;
			font-size: 14px;
		}

		.bot-connection-link {
			color: #11a1ae;
			.link-open {
				font-size: 14px;
				font-weight: bold;
			}
		}
	}

	.connected-bot-list-wrap {
		.title {
			color: #1c2e59;
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 16px;
		}

		.connected-bot-list {
			// height: calc(100% - 138px);
			// overflow-y: auto;
			.connected-bot-item {
				border: 1px solid #e8e9ed;
				padding: 12px 18px;
				justify-content: space-between;
				.bot-profile-wrap {
					.bot-profile-img {
						width: 38px;
						height: 38px;
						border-radius: 50%;
						margin-right: 14px;
					}

					.bot-name {
						color: #1c2e59;
						font-weight: 700;
					}
				}
				.bot-action-wrap {
					.bot-item-menu {
						.dropdown {
							border: 1px solid #e5e5e5;
							box-shadow: none;

							&::before {
								border-bottom: 10px solid #e5e5e5;
							}
						}
					}
				}
			}
		}
	}
}

.pipeline-bot-add-connections {
	padding: 3rem;
	.add-connections {
		&__label {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 10px;
		}
		&__sub-label {
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 10px;
		}

		.advance-setup-text-wrap {
			.advance-label {
				color: #1c2e59;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
	.tooltip-color {
		background-color: #33516a !important;
		color: #fff;
	}
}

.pipeline-top-menu {
	display: inline-block;
	position: fixed;
	top: 0px;
	z-index: 20;
	padding: 0 1.8rem 0 3rem;
	@include respond-to(mobile) {
		left: 69px;
	}
	&__btn {
		position: absolute;
		padding-top: 8px;
		width: 100px;
		&.previous {
			top: 0;
			left: 0;
			background-image: linear-gradient(to right, #fff, transparent);
		}
		&.next {
			background-image: linear-gradient(to left, #fff, transparent);
			top: 0;
			right: 0;
		}
		i {
			&.previous-icon {
				float: left;
			}
			&.next-icon {
				float: right;
			}
			cursor: pointer;
			background-color: #fff;
			border: 1px solid #f7f7f7;
			margin: 0px 10px 10px 10px;
			padding: 5px 0px;
			color: #11a1ae;
		}
	}
	&__list {
		position: relative;
		float: left;
		width: 67vw;
		max-width: 67vw;
		min-width: 67vw;
		@media only screen and (max-width: 1800px) {
			width: 60vw;
			max-width: 60vw;
			min-width: 60vw;
		}
		@media only screen and (max-width: 1450px) {
			width: 53vw;
			max-width: 53vw;
			min-width: 53vw;
		}
		@media only screen and (max-width: 1250px) {
			width: 47vw;
			max-width: 47vw;
			min-width: 47vw;
		}
		@media only screen and (max-width: 1100px) {
			width: 40vw;
			max-width: 40vw;
			min-width: 40vw;
		}
		padding-top: 1.8rem;
		& > .items-wrapper {
			overflow-x: hidden;
			width: 100%;
			& > .scroll-items-wrapper {
				// transform: translateX(-150px);
				.pipeline-menu-item {
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					// width: 100px;
					// min-width: 100px;
					white-space: nowrap;
					// overflow: hidden;
					// text-overflow: ellipsis;
					padding-bottom: 1.5rem;
					margin-right: 10px;
					margin-left: 10px;
					&.active {
						border-bottom: 3px solid #11a1ae;
						color: #11a1ae;
						text-align: center;
					}
				}
			}
		}
	}
	&__action {
		float: left;
		font-size: 1.8rem;
		font-weight: 500;
		color: #202d56;
		padding-right: 1rem;
		padding-top: 1.8rem;
		padding-bottom: 1.8rem;
		border-right: 1px solid rgba(209, 209, 209, 0.23);
		& > i {
			margin-right: 0px;
			font-size: 16px;
		}
		&.add-new {
			font-size: 14px;
			cursor: pointer;
			color: #979797;
			position: fixed;
			right: 293px;
		}
	}
}

.create-pipeline-modal {
	z-index: 21;
	.create-pipeline-container {
		.create-pipeline-body-height {
			height: 70vh;
			min-height: 510px !important;
			overflow-y: auto;
			margin-bottom: 0px;
		}
		.create-pipeline-stages-selection {
			min-height: 77vh !important;
		}
		width: 92vw !important;
		height: auto !important;
		min-height: 510px !important;
		// top: 10% !important;
		transform: translate(4%, 3%);
		background-color: #ffffff;
		&__section-width {
			width: 26vw;
			min-width: 26vw;
			max-width: 26vw;
			@media only screen and (max-width: 1450px) {
				width: 30vw;
				min-width: 30vw;
				max-width: 30vw;
			}
		}
		&__title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
			margin-top: 20px;
		}
		&__sub-title {
			color: #11a1ae;
			font-size: 14px;
			font-weight: 700;
			margin-top: 8px;
		}
		.create-pipeline-stages {
			&__label {
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 10px;
			}
			&__sub-label {
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 30px;
			}
			&__add-stage {
				margin-left: 0;
				position: relative;
				font-size: 14px;
				font-weight: 700;
				cursor: pointer;
				color: #11a1ae;
				.icon-add-stage {
					font-size: 20px;
					font-weight: 600;
					margin-right: 5px;
				}
			}
			.create-pipeline-stages-list {
				// width: 31vw;
				&__item {
					margin-bottom: 20px;
					width: 100%;
					position: relative;
					.custom-pipeline-input-wrap {
						max-width: calc(100% - 40px);
						width: calc(100% - 40px);
					}
					.create-pipeline-stages-text {
						font-size: 14px;
						font-weight: 400;
						border: 1px solid #c2c2c2;
						width: 100%;
						padding: 10px;
					}
					.delete-icon {
						color: #777a82;
						position: absolute;
						right: -4px;
						top: 8px;
					}
				}
			}
			.input-field {
				border: 1px solid #c2c2c2 !important;
			}
		}
		.connect-bots-container {
			.connect-bots-input {
				min-width: 30%;
				width: 30%;
				@include respond-to(mobile) {
					min-width: 60%;
				}
				&__connect-another {
					color: #11a1ae;
				}
				.tooltip-bgcolor {
					background-color: #33516a !important;
					color: #fff;
				}
				.icon-advance-setup {
					position: relative;
					top: 2px;
					color: #777a82;
				}
				.advance-text {
					color: #1c2e59;
					font-size: 12px;
					font-weight: 700;
				}

				.select-wrap {
					.css-2b097c-container {
						.css-1e3zplh-control {
							border-color: transparent !important;
							background-color: #f2f7f9 !important;
						}
						.css-1pjewak-control {
							border-color: transparent !important;
						}
					}
				}
			}
			.connected-bots-list {
				min-width: 30%;
				width: 30%;
				&__item {
					position: relative;
					min-width: 100%;
					width: 100%;
					background-color: #ffffff;
					border: 1px solid #e8e9ed;
					padding: 20px;
					.bot-avatar {
						background-color: red;
						border: 1px solid #000;
						border-radius: 50%;
						width: 20px;
						height: 20px;
						margin-right: 5px;
					}
					.bot-name {
						white-space: nowrap;
						max-width: calc(100% - 30px);
						overflow: hidden;
						text-overflow: ellipsis;
					}
					&__menu {
						position: absolute;
						right: 6px;
						top: 18px;
						color: #7d7d7d;
						cursor: pointer;

						.dropdown {
							border: 1px solid #e5e5e5;
							box-shadow: none;

							&::before {
								border-bottom: 10px solid #e5e5e5;
							}
						}
					}
				}
			}
		}
	}
	.modal__footer {
		border-top: 1px solid #d7d7d7;
	}
	&__back-btn {
		color: #11a1ae;
	}
}

.create-pipeline-stepper {
	position: relative;
	&__container {
		margin-left: 22px;
	}
	.step-text {
		font-weight: 600;
	}
	.create-pipeline-step {
		border: 2px solid #11a1ae;
		border-radius: 50%;
		height: 20px;
		min-height: 20px;
		width: 20px;
		min-width: 20px;
		position: relative;
		i {
			display: none;
			position: absolute;
			top: 1px;
			left: 1px;
			color: #fff;
			font-size: 14px;
		}
		&.active {
			background-color: #11a1ae;
			i {
				display: block;
			}
		}
	}
	.create-pipeline-line {
		height: 3px;
		width: 122px;
		min-width: 122px;
		background-color: #11a1ae;
		&.transparent {
			min-width: 50px;
			width: 50px;
			background-color: transparent;
		}
	}
}
.create-pipeline-modal-body {
	.textDesign {
		margin-top: 40px;
		font-weight: bold;
		font-size: 20px;
	}
	.p-design {
		margin-top: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 17px;
	}
	.card1 {
		width: 200px;
		flex-direction: column;
		margin-top: 42px;
		margin-bottom: 20px;
		border: 1px solid #e4e4e4;

		@include respond-to(mobile) {
			width: 170px;
			margin-right: 5px !important;
		}
	}
	.card2 {
		width: 200px;
		flex-direction: column;
		margin-top: 42px;
		margin-bottom: 20px;
		border: 1px solid #e4e4e4;

		@include respond-to(mobile) {
			width: 170px;
		}
	}
	.h-text {
		font-size: 16px;
		margin-top: 45px;
	}
	.p-text {
		width: 166px;
		height: 66px;
		font-size: 14px;
		color: #5e7281;
		margin-top: 13px;
		text-align: center;
	}
	.b-text {
		text-align: center;
		color: #11a1ae;
		border: 1px solid #dbd9e5;
		margin-top: 20px;
		margin-bottom: 20px;
		font-weight: 700;
	}
}

.enquiry-types-dropdown {
	position: relative;
	.select-box {
		cursor: pointer;
		padding: 10px;
		background: #f3f7f9
			url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCAxMCA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+YXJyb3ctZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNy4wMDAwMDAsIC0xMC4wMDAwMDApIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIGZpbGwtb3BhY2l0eT0iMC41IiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSIwIDAgMjQgMCAyNCAyNCAwIDI0Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=')
			right 10px center no-repeat;
		border-radius: 2px;
		border: 1px solid #dadada;
		&.no-bot-selected {
			cursor: not-allowed;
		}
	}
	.enquiry-filters {
		top: 50px;
		left: 0px;
		width: 100%;
		display: none;
		position: absolute;
		border-radius: 8px;
		&.show {
			display: block;
			transition: 0.4s;
			z-index: 99;
		}
		.enquiry-list-wrap {
			background-color: #f3f7f9;
			max-height: 230px;
			overflow: auto;

			&-item {
				padding: 1rem;
				border-bottom: 1px solid $filter-background;

				&-type {
					border-bottom: none;
				}
			}

			.sub-list {
				margin-top: 1rem;
				margin-left: 3rem;

				li:last-child {
					margin-bottom: 1rem;
				}
			}

			.user {
				.checkbox-txt-wrap {
					top: 0px;
				}
			}

			.custom-checkbox {
				&-types {
					padding-bottom: 1rem;

					.checkbox-txt {
						margin-top: 1px;
						margin-left: 1rem;
					}

					&:last-child {
						padding-bottom: 0;
					}
				}

				&-type-list {
					padding-bottom: 1rem;

					&:last-child {
						padding-bottom: 0;
					}
				}

				.checkbox-label {
					&:last-child {
						margin-bottom: 0;
					}

					.checkbox-text-wrap {
						.checkbox-txt {
							color: #393f53;
							font-size: 1.4rem;
							font-weight: 600;
							margin-left: 1.4rem;

							&--md {
								margin-left: 1.4rem;
								color: rgba($primary-text-color, 0.7);
								font-weight: $regular;
							}
						}
					}
				}

				.user-wrap {
					margin: -4px 0 0 12px;

					.user-name {
						color: #393f53;
						font-size: 1.4rem;
						font-weight: 600;
						margin-top: 2px;
					}

					.user-email {
						font-size: 1.3rem;
						font-weight: 500;
						color: #9a9b9b;
					}
				}

				.user-wrap-types {
					display: none;
					position: relative;
					left: 38px;
					bottom: 2px;
					margin-bottom: 14px;

					.checkbox-label {
						border-bottom: none !important;
					}

					&.show-sub-type {
						display: block;
					}
				}
			}
		}
		.search-wrap {
			z-index: 99;
			.search-box {
				background-color: #f3f7f9 !important;
			}
		}
	}
}

.card-filter-wrap {
	&.card-search-box {
		width: 500px;
		min-width: 500px;
		@media only screen and (max-width: 1800px) {
			width: 400px;
			min-width: 400px;
		}
		@media only screen and (max-width: 1450px) {
			width: 328px;
			min-width: 328px;
		}
		@media only screen and (max-width: 1250px) {
			width: 280px;
			min-width: 280px;
		}
	}
	.card-dropdown-search-wrap {
		.search-box {
			width: 100%;
			padding-left: 1rem;
			&::placeholder {
				text-overflow: ellipsis;
				overflow: hidden;
				@media only screen and (max-width: 1450px) {
					width: 145px;
					min-width: 145px;
				}
				@media only screen and (max-width: 1250px) {
					width: 60px;
					min-width: 60px;
				}
			}
		}
		.clear-btn {
			position: absolute;
			right: 10px;
			// left: 10px;
			top: 8px;
		}
	}
	background-color: #fff;
	border-radius: 2px;
	&.type-dropdown {
		background-color: #fafafa;
		right: 10px;
		bottom: 10px;
		padding-top: 7px;
		padding-left: 14px;
		padding-right: 5px;
		border: 1px solid #e6e6e6;
	}
	.card-dropdown-wrap {
		.card-filter-title {
			font-weight: 600;
			color: #666666;
			white-space: nowrap;
		}
		.icon-expand {
			color: #666666;
		}
		.card-filter-dropdown {
			&.type-dropdown {
				width: 172px;
				right: -4px;
				padding: 1rem 0;
			}
			@media only screen and (min-width: 100px) and (max-width: 550px) {
				width: 128% !important;
				min-width: 128% !important;
			}
			@media only screen and (max-width: 1450px) {
				width: 150%;
				min-width: 150%;
			}
			@media only screen and (max-width: 1250px) {
				width: 210%;
				min-width: 210%;
			}
			width: 100%;
			min-width: 100%;
			position: absolute;
			background: $white;
			border: 1px solid #d7d3d3;
			border-radius: 2px;
			box-shadow: 0 2px 8px -1px rgba(180, 180, 180, 0.5);
			@include transition(all 0.3s ease);
			opacity: 0;
			visibility: hidden;
			transform: translateY(30%);
			z-index: 2;
			top: 39px;
			&__item {
				display: block;
				width: 100%;
				padding: 0.4rem 1.5rem;
				color: $primary-text-color;
				&:hover {
					background-color: rgba(96, 124, 255, 0.16);
				}
				&.active {
					background-color: rgba(96, 124, 255, 0.16);
				}
			}
			&.show {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
			.card-filter-list {
				// padding-left: 12px;
				.card-type-filter {
					padding: 0.7rem;
					// overflow-y: auto;
					// height: auto;
					// max-height: 400px;
				}
				.card-filter {
					padding: 1.5rem;
					overflow-y: auto;
					height: auto;
					max-height: 400px;
				}
				.card-filter-footer {
					font-size: 12px;
					color: #777a82;
					.search-icon {
						font-size: 14px;
						color: #777a82;
						margin: 10px;
					}
					border-top: 1px solid #efefef;
					.press-key {
						padding: 1rem;
						color: #777a82;
						font-weight: 700;
						display: inline-block;
					}
				}
			}
		}
	}
}

.card-filter-dropdown-card {
	cursor: pointer;
	background-color: #fff;
	padding: 1rem 1.5rem;
	&:hover {
		background-color: #f3f7f9;
	}
	.avatar-pic {
		margin-right: 10px;
	}
	.card-name {
		font-weight: 600;
		font-size: 16px;
		color: #393f53;
	}
	.card-email {
		font-weight: 500;
		font-size: 14px;
		color: #979797;
	}
	.card-date {
		font-weight: 500;
		font-size: 14px;
		color: #979797;
	}
	.card-tag {
		border: 1px solid #d9d9d9;
		background: #ffffff;
		border-radius: 2px;
		padding: 0.2rem 0.8rem;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

.pipeline-aside-filter {
	.pipeline-aside-container {
		height: 100%;
		.pipeline-aside-section {
			border-bottom: 1px solid #edecec;
			padding-bottom: 10px;
			& > .section-title {
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 10px;
			}
		}
		.user-filter-search-box {
			position: relative;
			&::after {
				position: absolute;
				top: 11px;
				right: 8px;
				content: '\E8B6';
				font-family: 'Material Icons';
				font-weight: normal;
				font-style: normal;
				font-size: 2rem;
				display: inline-block;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;
			}
		}
		.user-filter {
			overflow-y: auto;
			height: 212px;
			.user-filter-item {
				border-bottom: 1px solid #edecec;
				.user-name {
					font-weight: 500;
					font-size: 14px;
					color: #393f53;
				}
				.user-email {
					font-weight: 500;
					font-size: 12px;
					color: #979797;
				}
				.avatar-pic {
					@include avatar(28px, 28px, $border-color, 100%);
					margin-top: 20px;
					margin-right: 10px;
					text-align: center;
					line-height: 30px;
					font-weight: bold;
					background: #77858d;
					color: white;
					> img {
						@include avatar(100%, 100%, $border-color, 100%);
					}
				}
			}
		}
	}
}

.pipeline-filter-cards-container {
	padding: 1rem 3rem;
	border-bottom: 1px solid #edecec;
	margin-bottom: 10px;
	padding-bottom: 3px;
	.pipeline-filter-card-title {
		color: #979797;
		font-weight: 600;
		white-space: nowrap;
	}
	.pipeline-filter-cards {
		background-color: #f6f6f6;
		border-radius: 4px;
		margin-right: 10px;
		padding: 6px 0px 6px 12px;
		margin-bottom: 7px;
		.pipeline-filter-card {
			padding: 2px 6px;
			background-color: #ffffff;
			border-radius: 4px;
			i {
				color: #979797;
				font-size: 18px;
			}
		}
	}

	.clear-all-value {
		color: #00abba;
		font-weight: 600;
		font-size: 1.4rem;
	}
	.selected-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 5px;
		// padding: 5px 10px;
		// border-radius: 20px;
		border-radius: 5px;
		border: none;
		font-size: 14px;
		background-color: #dbfeff;
		min-width: -webkit-max-content;
		min-width: -moz-max-content;
		min-width: max-content;
		flex-wrap: nowrap;
		.value {
			color: darken($primary-brand-color--light, 7%);
			font-size: 14px;
			font-weight: 700;
			display: inline-block;
			padding: 5px 10px;
			&-new {
				color: #5e7281;
			}
			&.sm {
				padding: 0px 10px;
				font-size: 12px;
				min-width: 0px;
			}
		}
		.remove {
			color: darken($primary-brand-color--light, 7%);
			font-size: 12px;
			font-weight: 800;
			padding: 5px 6px;
			border-left: 1px solid #dadada;
			&-new {
				color: #5e7281;
			}
			cursor: pointer;
			&.sm {
				padding: 0px 6px;
				font-size: 10px;
			}
		}
	}
}
