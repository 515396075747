.card-detail {
	.title {
		font-size: 16px;
		font-weight: $semibold;
	}
	&-container {
		padding: 1rem;
		border-radius: 3px;
		border: 1px solid #dadada;
		width: 400px;
		.credit-card {
			.label {
				font-size: 18px;
				font-weight: bold;
				margin-left: 15px;
				text-transform: capitalize;
			}
			.logo {
				height: 60px;
				width: 60px;
				object-fit: contain;
			}
			.expire {
				padding: 8px 0;
				font-size: 14px;
			}
			.change {
				width: 100%;
				color: $primary-text-color;
				font-size: 1.5rem;
				line-height: 2rem;
				font-weight: $bold;
				padding: 0.9rem 1.6rem;
				background: #ececec;
				border: 1px solid;
				border-color: #dbd9e4;
				border-radius: 2px;
				cursor: pointer;
				@include transition(all 0.3s ease);
				&:hover {
					background: #d6d4d4;
					border-color: #d6d4d4;
				}
			}
		}
	}
	&-form {
		.title {
			font-size: 2rem;
			font-weight: bold;
			text-align: center;
		}
		.element-title {
			font-weight: 500;
			font-size: 14px;
		}
		.card-wrap {
			.field {
				width: 100%;
				font-weight: bold;
			}
			input[type='text']::placeholder {
				color: #c6ccd2;
				font-weight: 600;
				font-size: 15px;
			}
			.card-name {
				width: 100%;
				border: 1px solid;
				border-bottom: 1px solid;
				border-radius: 7px;
				border-color: #dadada;
				color: #31325f;
				font-weight: 600;
				font-size: 15px;
				&--invalid {
					border-bottom: 1px solid;
					border-color: #fa755a;
				}
				&::placeholder {
					color: red;
				}
				// &:focus {
				//   box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
				//     0 0 0 4px rgba(50, 151, 211, 0.3);
				// }
			}
			.card-number-element {
				border-radius: 0px;
				border-top-right-radius: 7px;
				border-top-left-radius: 7px;
				border-top: 1px solid;
				border-left: 1px solid;
				border-right: 1px solid;
				border-bottom: 0px solid;
				border-color: #dadada;
				.__PrivateStripeElement {
					padding: 0px !important;
				}
			}
			.card-number-element {
				&.StripeElement--invalid {
					border-bottom: 1px solid;
					border-color: #fa755a;
					& + div > .card-expiry-element {
						border-top: 1px solid #fa755a !important;
					}
					& + div > .card-cvc-element {
						border-top: 1px solid #fa755a !important;
					}
				}
			}
			.card-expiry-element {
				border-radius: 0px;
				border-bottom-left-radius: 7px;
				border: 1px solid;
				border-color: #dadada;
				position: relative;
				top: -1px;
				height: 40px;
			}
			.card-expiry-element {
				&.StripeElement--invalid {
					border-color: #fa755a;
				}
			}
			.card-expiry-element:not(.StripeElement--invalid) {
				& + .card-cvc-element {
					&.StripeElement--invalid {
						border-bottom: 1px solid;
						border-top: 1px solid;
						border-left: 1px solid;
						border-right: 1px solid;
						border-color: #fa755a;
					}
				}
			}

			.card-cvc-element {
				border-radius: 0px;
				border-bottom-right-radius: 7px;
				border-bottom: 1px solid;
				border-top: 1px solid;
				border-left: 0px solid;
				border-right: 1px solid;
				border-color: #dadada;
				position: relative;
				top: -1px;
				height: 40px;
			}
			.card-cvc-element {
				&.StripeElement--invalid {
					border-color: #fa755a;
				}
			}
		}
		.StripeElement {
			padding: 0px 10px;
		}
		.us-resident {
			margin-top: 20px;
			text-decoration: underline;
		}
		.site-info {
			margin-top: 15px;
			font-size: 12px;
			color: #dadada;
		}
		.payment-info {
			margin-top: 15px;
			font-size: 15px;
			font-weight: $medium;
		}
	}
}
