.pipeline-new {
	padding-left: 0px;
	padding-right: 0px;
	// background-color: #f8f8f8;
	background-color: #fff;
	.pipeline-border {
		border-bottom: 1px solid #edecec;
		background-color: #fff;
		margin-bottom: 20px;
		padding-left: 3rem;
		// padding-right: 3rem;
		padding-bottom: 10px;
		// padding: 15px 25px !important;
		// box-shadow: 0px 10px 30px rgba(4, 108, 117, 0.04);
		// margin-left: 3rem;
		// margin-right: 8px;
		margin-bottom: 10px;
		// border-radius: 16px;
	}
	.section-header {
		@include flexbox();
		justify-content: space-between;

		&__left {
			.select-wrap {
				width: 80px;
			}
			.custom-select {
				max-width: 80px;
				background-color: initial !important;
				padding-right: 3rem !important;
			}
			.header-sub-text {
				font-size: 1.4rem;
				color: rgba(#1c2e59, 0.7);
			}
			&.top {
				position: absolute;
				top: 16px;
				z-index: 99;
			}
		}
		.bot-types-wrap {
			> .link {
				font-size: 1.3rem;
				.arrow-down {
					position: relative;
					top: -1px;
					font-size: 2rem;
				}
			}
			&.bot-list {
				.dropdown {
					left: -50px;
				}
			}
			.dropdown {
				max-height: 220px;
				top: 30px;
				left: 0px;
				padding: 10px;
				min-width: 180px;
				&::after {
					left: 51px;
					right: initial;
				}
				&::before {
					left: 51px;
					right: initial;
				}
				@include respond-to(mobile) {
					right: 0;
					left: initial;
					&::after {
						left: initial;
						right: 1px;
					}
					&::before {
						left: initial;
						right: 0px;
					}
				}
				.links-wrap {
					max-height: 140px;
					overflow: auto;
				}
				&__item {
					white-space: nowrap;
				}
			}
		}
	}

	.show-archive-btn {
		min-width: 160px;
		@include respond-to(mobile) {
			width: 100%;
			margin-right: 0 !important;
			margin-bottom: 10px;
		}
	}
	.setting-action {
		min-width: 40px;
		min-height: 40px;
		max-width: 40px;
		max-height: 40px;
		background-color: rgba(234, 234, 234, 0.4);
		border-radius: 2px;
		position: relative;
		cursor: pointer;
		background-color: #fff;
		border: 1px solid #dadada !important;
		font-size: 24px !important;

		.settings-icon {
			font-size: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #5e7281;
			&.active {
				color: #142445;
			}
		}

		.bolt-icon {
			font-size: 24px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #5e7281;
			&.active {
				color: gold;
			}
		}
	}
	&-filter {
		position: relative;
		background: $filter-background;
		color: $filter-text;
		padding: 7px;
		border-radius: 3px;
		margin-right: 5px;
		margin-left: 5px;
		border: 0.5px solid $filter-border;
		cursor: pointer;
		&.inbox {
			padding: 5px;
		}
		@include respond-to(mobile) {
			// padding: 6px;
		}
		&.active {
			background: #96edf5;
			border-color: #11a1ae;
		}
		.total {
			border-radius: 50%;
			position: absolute;
			top: -3px;
			height: 10px;
			width: 10px;
			right: -4px;
			color: #fff;
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.4rem;
			background: #ec5959;
		}
		.filter-icon {
			font-size: 24px !important;
		}
	}

	.section-body {
		height: calc(100vh - 161px);
	}
	.pipeline-new-body {
		padding-left: 3rem;
	}

	.list-view {
		.ag-body-viewport-wrapper.ag-layout-normal {
			overflow-x: scroll;
		}

		::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 0px;
			height: 8px;
		}

		::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.5);
			box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		}
	}
	.section-container {
		height: 100%;
		width: -webkit-fill-available;
	}
	.section-row {
		// border: 1.5px solid rgba($border-secondary, 0.4);
		width: 100%;
		height: 100%;
		overflow: auto;
	}
	.section-col {
		position: relative;
		width: 364px;
		flex-shrink: 0;
		border-radius: 5px;
		// padding-bottom: 4rem;
		// border: 1px solid rgba($border-secondary, 0.4);
		margin: 0 10px;
		// background: #fff;

		background: #f8f8f8;
		border: 1px solid #d3d3d3;
		// border-top-left-radius: 16px;
		// border-top-right-radius: 16px;
		box-shadow: 0 0 5px 0 rgba(140, 163, 150, 0.36);
		overflow: hidden;
		&:first-child {
			margin-left: 0px;
		}
	}
	.stage-actions-section {
		background-color: #f2f7f9;
		color: #777a82;
		border-bottom: 1px solid #e6e6e6;
		height: 29px;
		.stage-action {
			flex-grow: 3;
			text-align: center;
		}
		.stage-close {
			color: #979797;
			cursor: pointer;
			&__icon {
				font-size: 18px;
			}
		}
		.stage-actions-border-right {
			border-right: 1px solid #e6e6e6;
		}
		.stage-actions-dropdown {
			&__wrap {
				margin-left: 8px;
				.action-text {
					margin-right: 6px;
					margin-left: 3px;
				}
				.stage-dropdown {
					padding: 5px;
					width: 160px;
					top: 30px;
					right: -18px;
					.search-container {
						padding: 5px;
					}
					.list-wrap-item:hover {
						background-color: #f2f7f9;
					}
					&::before {
						right: 34px !important;
					}
					&::after {
						right: 34px !important;
					}
				}
			}
		}
		.selection-count {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
		.pipeline-checkbox-label {
			position: relative;
			top: -1px;
		}
		.stage-action-checkbox {
			border-radius: 2px;
			border: 1px solid #11a1ae;
			width: 12px;
			height: 12px;
			min-height: 12px;
			min-width: 12px;
			background-color: #fff;

			&::before {
				line-height: 0px;
				text-align: start;
				position: absolute;
				top: 5px;
				font-size: 10px !important;
				position: absolute;
				transition: none;
			}
		}
	}
	.cont-header {
		position: relative;
		padding: 1.5rem;
		background-color: $white;
		border-bottom: 1.5px solid rgba($border-secondary, 0.4);
		min-height: 75px;
		.left-section {
			padding-right: 1rem;
			overflow: hidden;
		}
		.right-section {
			.add-new-enquiry-icon {
				background: #f1f1f1;
				border-radius: 4px;
				margin-left: 0.5rem;
				color: #979797;
				font-size: 24px;
				border: 1px solid #979797;
			}
		}
		.material-icons {
			&.more-icon {
				color: #78858d;
				&:hover {
					color: $black;
				}
			}
			&.edit {
				position: absolute;
				right: 20px;
				font-size: 1.8rem;
				top: 6px;
			}
		}
		.title {
			color: #393f53;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 1.9rem;
			margin-bottom: 0.4rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.amt {
			color: rgba(#393f53, 0.6);
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.6rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.count {
			color: #fff;
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.4rem;
			padding: 0.3rem 0.8rem;
			border-radius: 6px;
			background: #ec5959;
		}
		.custom-section {
			min-height: 25px;
		}
		.title {
			&-wrap {
				position: relative;
			}
			&-field {
				width: 190px;
				height: 32px;
				font-size: 1.4rem;
				padding: 0.7rem 2.5rem 0.7rem 1rem;
				margin-bottom: 0.5rem;
			}
			&-success {
				position: absolute;
				right: 54px;
				bottom: 0;
			}
		}
		.done-icon {
			right: 80px !important;
		}
		.pipeline-tooltip-bottom {
			right: -30px !important;
			top: 25px !important;
		}
		// &:not(:last-child):after {
		//   content: " ";
		//   display: block;
		//   position: absolute;
		//   // height: 1px;
		//   content: "";
		//   transform: scaleX(0.98);
		//   // @include transition(all 0.3s ease);
		//   // transform-origin: center, center;
		//   // position: absolute;
		//   // bottom: 0;
		//   bottom: -2px;
		//   // left: 13px;
		//   width: 88%;
		//   // transform: scale(1);
		//   height: 2px;
		//   max-height: 1px;
		//   background: #dfdfdf;
		//   left: 0;
		//   right: 0;
		//   margin-left: auto;
		//   margin-right: auto;
		// }
	}
	.cont-body {
		height: calc(100vh - 250px);
		// padding: 1.5rem;
		overflow-y: auto;
		.infiniteScroll {
			min-height: calc(100vh - 233px);
			padding: 1.5rem 1.5rem 0.5rem 1.5rem;
			overflow: hidden !important;
		}
		.card {
			&:not(:first-of-type) {
				margin-top: 1rem;
			}
			&:last-child {
				margin-bottom: 70px !important;
			}
		}
		.card-item {
			border: 1px solid #e9e9e9;
			background-color: #fff;
			border-radius: 1px 6px 6px 1px;
			&--new {
				background-color: #fff7e8;
				// box-shadow:inset 5px 0 0 0 #11a1ae;
				// box-shadow: 0 2px 7px 0 rgba(158, 158, 158, 0.5), inset 5px 0 0 0 #11a1ae;
			}
			.box-card-redeisgn {
				// padding: 1.2rem;
				margin-top: 14px;
				box-shadow: inset 5px 0 0 0 #fbbe4d;
			}
			.color {
				margin-top: 14px;
				box-shadow: inset 5px 0 0 0 #11a1ae;
				box-shadow: inset 5px 0 0 0 #11a1ae;
			}
			.item-left {
				padding-right: 1rem;
			}
			.avatar-pic {
				@include avatar(28px, 28px, $border-color, 100%);
				text-align: center;
				line-height: 30px;
				font-weight: bold;
				background: #77858d;
				color: white;
				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}
				&__sub-avatar {
					height: 16px;
					width: 16px;
					position: absolute;
					bottom: 2px;
					right: -3px;
					border-radius: 50%;
					> img {
						height: 14px;
						width: 14px;
					}
				}
			}
			.card-avatar-pic-wrap {
				position: relative !important;
				top: -2px !important;
			}
			.multi-select-marker {
				width: 30px;
				height: 30px;
				min-height: 30px;
				min-width: 30px;
				background-color: transparent;
				border-radius: 50%;
				display: -webkit-flex;
				display: -moz-flex;
				display: flex;
				-webkit-justify-content: center;
				justify-content: center;
				-webkit-align-items: center;
				align-items: center;
				border: 1px solid #11a1ae;
				box-sizing: border-box;
			}
			.title {
				color: rgba(#1c2e59, 0.53);
				font-size: 1.2rem;
				font-weight: $medium;
				line-height: 1.5rem;
				margin-bottom: 0.3rem;
				text-transform: uppercase;
			}
			.business {
				color: #393f53;
				font-size: 1.5rem;
				font-weight: 600;
				line-height: 2.1rem;
				margin-bottom: 0.3rem;
				text-transform: capitalize;
				max-width: 150px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-left: 18px;
			}
			.email {
				color: rgba(#393f53, 0.6);
				font-size: 1.3rem;
				line-height: 1.6rem;
				margin-bottom: 0.8rem;
				padding-left: 18px;
				white-space: nowrap !important;
				width: 150px !important;
				overflow: hidden !important;
				text-overflow: ellipsis !important;
			}
			.price {
				color: #1d2121;
				font-size: 1.8rem;
				font-weight: $medium;
				// line-height: 3rem;
				padding-right: 18px;
			}
			.deal {
				padding-left: 18px;
				font-size: 1.3rem;
				color: #393f53;
				margin-bottom: 1rem;
				> i {
					font-size: 1.6rem;
					color: #5e7281;
				}
				&.green {
					color: $green;
					> i {
						color: $green;
					}
				}
				&.red {
					color: $red;
					> i {
						color: $red;
					}
				}
			}
			.card-deal {
				margin-bottom: 5px !important;
			}
			.archive {
				&-menu {
					cursor: pointer;
					position: absolute;
					font-size: 14px;
					right: 0px;
					top: 13px;
					.material-icons {
						&.more-icon {
							color: #78858d;
						}
						&:hover {
							color: $black;
						}
					}
				}
			}
			.pills-wrap {
				@include flex-wrap(wrap);
				padding-left: 18px;
				// margin-bottom: 5px;
				.pills {
					white-space: nowrap;
					padding: 0.2rem 0.8rem;
					background: #f8f8f8;
					border: 1px solid #d9d9d9;
					border-radius: 3px;
					margin-right: 0.5rem;
					font-size: 1.3rem;
					color: #393f53;
					font-weight: 600;
					max-width: 150px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.card-pill {
					font-size: 11px !important;
					padding: 1px 6px !important;
					margin-bottom: 8px !important;
					// &:first-child{
					//   margin-bottom: 8px !important;
					// }
				}
				.visibility-none {
					visibility: hidden !important;
				}
			}
			.item-right {
				@include flexbox();
				@include flex-direction(column);
			}
			.user-info {
				margin-top: auto;
				// @include avatar(24px, 24px, #78858d, 50%);
				// min-width: 24px;
				// min-height: 24px;
				text-align: center;
				font-size: 1.2rem;
				color: #ffffff;
				line-height: 29px;
				font-weight: 600;
				> img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}
		}
		.load-more {
			padding: 3px 10px;
			border: 1px solid #dadada;
			margin: 10px auto;
			background: white;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				background: #96edf5;
			}
		}
	}
	.cont-footer {
		position: absolute;
		width: 100%;
		bottom: 0;
		background-color: #f8f8f8;
		padding: 0.8rem;
		z-index: 1;
		.btn--add-deal {
			> .material-icons {
				color: #f45151;
			}
			font-size: 1.4rem;
			color: #393f53;
			// border: 1px dashed $secondary-brand-color;
			border-radius: 6px;
			background: #ffffff;
			border: 1px dashed #5b5852;
		}
	}

	.pipeline-stages-wrap {
		cursor: pointer;
		position: relative;
		margin: 1rem 0 1.5rem 0;

		.pipeline-title {
			position: relative;
			font-size: 1.8rem;
			font-weight: 500;
			left: 16px;
			user-select: none;

			.keyboard-down-icon {
				position: absolute;
				top: 0px;
				font-size: 26px;
				right: -30px;
			}
		}

		.pipeline-stages {
			margin-top: 18px;
			display: none;

			.pipeline-wrap {
				user-select: none;

				.input-wrap {
					display: flex;
					flex-direction: row;
					margin-bottom: 22px;

					.drag_indicator {
						padding: 8px 0 0 0;
						font-size: 24px;
						color: #c5c5c5;
						margin-right: 6px;
					}
				}
			}

			.pipeline-stage-add {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: 30px;
				user-select: none;

				.add {
					color: #4aaeab;
					border: 2px solid #4aaeab;
					margin-right: 14px;
					border-radius: 4px;
				}
			}

			.search-box {
				width: 424px;
			}

			&.show {
				display: block;
			}
		}
	}

	.table-details {
		display: none;

		&-show {
			display: block;
		}
	}

	.table-row-inline {
		margin: 5px 0;
		padding: 0.8rem 1rem;
		flex: 1 1;
		background-color: #f3f7f9;
		display: flex;
		justify-content: space-between;
		position: relative;

		.pipeline-row-delete {
			color: gray;
			font-weight: 500;
			display: none;
		}
	}

	.table-row-inline:hover .pipeline-row-delete {
		box-sizing: border-box;
		display: flex !important;
		position: absolute;
		right: 5px;
	}

	.pipeline-stages-main-wrap {
		padding: 0 0.5rem !important;
	}

	.pipeline-stages-body {
		margin-bottom: 18px;

		.drag-icon {
			color: #dadada;
		}
	}

	.pipeline-stages-head {
		margin-left: 23px;

		.add-pipeline-row {
			background-color: #fff;

			&-actions {
				.add-icon {
					border: 1px solid;
					font-size: 18px !important;
					margin-right: 14px;
					color: $filter-type-color;
				}

				span {
					color: gray;
					font-weight: 500;
				}
			}
		}
	}

	.add-enquiry-container {
		.modal-heading {
			font-weight: 700;
		}
		.modal__body {
			padding-top: 0 !important;
			padding-bottom: 0;
			margin-bottom: 0;
			.enquiry-section {
				padding-top: 20px !important;
				padding-bottom: 5px !important;
				.section-label {
					color: #1c2e59;
					font-size: 16px;
					font-weight: 700;
				}
				.input-wrap {
					.input-field {
						border-color: transparent !important;
						background-color: #f2f7f9 !important;
					}
					.css-2b097c-container {
						.app_dropdown__control {
							background-color: #f3f7f9 !important;
							border: transparent !important;
						}
					}
					.plus-symbol {
						position: absolute;
						left: 22px;
						top: 10px;
						font-size: 20px;
					}
				}
				.icon-expand {
					position: absolute;
					right: 20px;
					top: 7px;
					font-size: 26px;
					color: #11a1ae;
				}

				.dropdown--custom-search {
					padding: 5px 0;
					top: 46px !important;
					min-height: 47px;
					.no-enquiry-text {
						color: #afa7a7;
						font-weight: 500;
						margin: auto;
						width: max-content;
						margin-top: 8px;
					}
					li {
						padding: 8px 16px;

						&:hover {
							background-color: #d2f2f5;
						}
					}
				}
				.react-datepicker-wrapper {
					.react-datepicker__input-container {
						input {
							border: transparent !important;
							width: 274px !important;
						}
					}
				}
				.editor {
					border: transparent;
					box-shadow: none;
					.DraftEditor-editorContainer {
						background-color: #f2f7f9;
					}
				}
			}
		}

		.mb-0 {
			margin-bottom: 0 !important;
		}

		.modal__footer {
			.btn--save {
				width: max-content !important;
				padding: 10px 18px;
				font-weight: 700;
			}

			.btn--cancel {
				color: #00abba;
				border: 1px solid #11a1ae;
				font-weight: 600;
			}
		}
	}
}
.filter-dropdown-wrap {
	position: relative;
	.filter-dropdown {
		&.show {
			display: block;
		}
		display: none;
		position: absolute;
		top: 45px;
		left: 0;
		width: 210px;
		background: #ffffff;
		box-shadow: 0 5px 14px 0 rgba(11, 10, 10, 0.45);
		z-index: 10;
		padding-top: 10px;
		.search-wrap {
			min-width: 100% !important;
		}
		&__type {
			max-height: 48px;
			overflow: hidden;
			background: #f2f2f2;
			margin-bottom: 2px;
			&.open {
				background: $white;
				max-height: initial;
				border: 1px solid #393f53;
				.title {
					.material-icons {
						transform: rotate(180deg);
					}
				}
			}
			padding: 0 1.5rem;
			.title {
				cursor: pointer;
				padding: 1.2rem 0;
				font-size: 1.2rem;
				color: #393f53;
				letter-spacing: 0.65px;
				text-transform: uppercase;
			}
		}
		.btn-wrap {
			padding: 1.5rem 1rem;
		}
		.custom-checkbox {
			margin-bottom: 0.8rem;
		}
		.checkbox {
			&-icon-wrap {
				width: 15px;
				height: 15px;
				min-width: 15px;
				border-radius: 2px;
				&::before {
					position: relative;
					left: 0px;
					top: -1px;
					font-size: 14px;
				}
			}
		}
		.checkbox-txt {
			font-size: 1.3rem;
			color: #393f53;
			margin-left: 0.8rem;
		}
		.search-wrap {
			input {
				padding: 0.6rem 1.6rem 0.6rem 3rem;
			}
			&::after {
				top: 7px;
			}
		}
	}
}

.sort-dropdown-wrap {
	position: relative;
	.sort-dropdown {
		&.show {
			display: block;
		}
		@include respond-to(mobile) {
			top: 40px;
		}
		display: none;
		position: absolute;
		top: 40px;
		right: 0;
		width: 180px;
		background: #ffffff;
		box-shadow: 0 5px 14px 0 rgba(11, 10, 10, 0.45);
		z-index: 10;
		// padding-top: 10px;
		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: -11px;
			right: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 10px solid #d7d3d3;
		}
		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: -10px;
			right: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 11px solid white;
		}
		.sub-menu-wrap {
			padding: 5px 0px;
		}
		.title {
			color: #000;
		}
	}
	&__type {
		max-height: 48px;
		overflow: hidden;
		background: #f2f2f2;
		margin-bottom: 2px;
		&.open {
			background: $white;
			max-height: initial;
			border: 1px solid #393f53;
			.title {
				.material-icons {
					transform: rotate(180deg);
				}
			}
		}
		padding: 0 1.5rem;
		.title {
			cursor: pointer;
			padding: 1.2rem 0;
			font-size: 1.2rem;
			color: #393f53;
			letter-spacing: 0.65px;
			text-transform: uppercase;
		}
		.sub-menu-wrap {
			padding: 10px 0px;
		}
	}
}

.dropdown-sub {
	&.show {
		display: block;
	}
	display: none;
	position: absolute;
	top: 45px;
	left: 0;
	width: 210px;
	background: #ffffff;
	box-shadow: 0 5px 14px 0 rgba(11, 10, 10, 0.45);
	z-index: 10;
	padding-top: 10px;
	.search-wrap {
		min-width: 100% !important;
	}
	&__item {
		display: block;
		width: 100%;
		padding: 0.4rem 1.5rem;
		color: $primary-text-color;
		&:hover {
			background-color: rgba(96, 124, 255, 0.16);
		}
		&.sort-container {
			padding: 8px 12px;
			&.select-wrap {
				border-bottom: 1px solid #e9e5e5;
				padding-top: 11px;
			}
			&:hover {
				background-color: $white;
			}
			.check {
				position: absolute;
				left: 12px;
			}
		}
	}
	&__type {
		max-height: 32px;
		overflow: hidden;
		margin-bottom: 2px;
		padding: 0rem;
		&:hover {
			background: #dadada;
		}
		&.open {
			background: $white;
			max-height: initial;
			// border: 1px solid #393f53;
			.sub-title {
				.material-icons {
					transform: rotate(180deg);
				}
			}
		}
		.sub-title {
			cursor: pointer;
			padding: 0.4rem 1.4rem;
			font-size: 1.2rem;
			color: #393f53;
			letter-spacing: 0.65px;
			text-transform: uppercase;
		}
	}
}

.conversation-preview {
	background-color: $white;
	&__header {
		padding: 1.2rem;
	}
	&__body {
		background-size: cover;
	}

	.chat-image-container {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: flex-start;

		.chat-wrap {
			flex-basis: 25%;
			@include flexbox();
			@include justify-content(flex-end);
			.chat-bubble {
				padding: 0 !important;
				border: none !important;
				border-radius: 0 !important;
				background-color: #fff !important;
				box-shadow: none !important;
				position: relative;

				.conversation-img {
					border-radius: 6px;
					object-fit: cover;
				}

				.img-overlay {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 96%;
					border-radius: 6px;
					opacity: 0;
					transition: 0.3s ease;
					background: rgba(0, 0, 0, 0.4);

					.icon-search {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						color: #fff;
					}
				}

				&:hover .img-overlay {
					opacity: 1;
				}
			}
		}

		.modal {
			background: rgba(0, 0, 0, 0.4);
		}
		@include respond-to(mobile) {
			justify-content: space-evenly !important;
			width: 328px;
			margin: auto;
		}
	}
	.item-left,
	.item-right {
		width: 100%;
	}
	pre {
		white-space: pre-wrap;
		white-space: -moz-pre-wrap;
		white-space: -o-pre-wrap;
		word-wrap: break-word;
	}
	.item-right {
		@include flexbox();
		@include justify-content(flex-end);
		.chat-container {
			max-width: 95%;
			width: inherit;
			&.reply {
				max-width: none;
			}
			.message-container {
				word-break: break-word;
			}
			.chat-bubble-container {
				width: 100% !important;
				margin-left: 0 !important;
				&.reply-sent {
					margin-right: 10px;
				}
				.chat-bubble {
					// border-bottom-left-radius: 15px;
					// border-top-left-radius: 15px;
					// border-bottom-right-radius: 0;
					// background-color: var(--secondary-bg-light);
					// background-color: #00abba;
					background: rgba(255, 206, 79, 0.12);
					box-shadow: 0px 1px 1px rgba(72, 77, 104, 0.1);
					border-radius: 16px 0px 16px 16px;
					padding: 12px;
					&__cont {
						// max-width: 37vw;
						color: #1f2236;
						font-weight: $medium;
						letter-spacing: 0.2px;
						// line-height: 0px;
					}
				}
				.no-subject-reply-message {
					background: none;
					box-shadow: none;
				}
			}
		}
	}

	.image-file-attachment {
		.chat-wrap {
			.chat-bubble {
				background-color: transparent;
				box-shadow: none;
				width: 125px;
				height: 125px;
				.conversation-img {
					border-radius: 6px;
				}
			}

			.img-overlay {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 96%;
				border-radius: 6px;
				opacity: 0;
				transition: 0.3s ease;
				background: rgba(0, 0, 0, 0.4);

				.icon-visible {
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
				}
			}
			&:hover .img-overlay {
				opacity: 1;
			}
			.broken-img-bubble {
				box-shadow: none !important;
			}
			.view-more-icon {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: 96%;
				border-radius: 6px;
				opacity: 1;
				transition: 0.3s ease;
				background: rgba(0, 0, 0, 0.4);
				align-items: center;
				justify-content: center;

				.add-icon {
					color: #fff;
					font-size: 16px;
					font-weight: 600;
				}
				.remaining-count {
					color: #fff;
					font-size: 16px;
					font-weight: 600;
				}
			}
			&:hover .img-overlay {
				opacity: 1;
			}
		}
		.cursor-not-allowed {
			cursor: not-allowed !important;
			&:hover .img-overlay {
				opacity: 0 !important;
			}
		}
		.pointer-events-none {
			pointer-events: none;
		}
	}

	.multimedia-file-attachment {
		margin-top: 15px;
		display: flex;
		align-items: center;
		position: relative;
		width: fit-content;
		padding: 5px 8px;

		.attachment-item {
			border: 1px solid #f8f8f8;
			position: relative;
			display: -moz-flex;
			display: flex;
			align-items: center;
			background-color: #ffffff;
			width: fit-content;
			padding: 5px 8px;
			border-radius: 5px;
			&:not(:last-child) {
				margin-bottom: 5px;
			}

			.attachment-name {
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				color: #5e7281;
			}
		}
	}

	.background-bubble {
		padding: 2rem 2rem;
		background-color: #f1f6f8;
		box-shadow: 0px 1px 1px rgba(72, 77, 104, 0.1);
		border-radius: 0px 16px 16px 16px;
	}

	.image_expand {
		flex-wrap: wrap;
		flex-direction: row;
	}

	.image-component-slide {
		width: 125px;
		height: 125px;
	}

	.chat-container {
		max-width: 95%;
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			border-radius: 15px;
			border-top-left-radius: 0;
			// background-color: $white;
			// box-shadow: 0px 0px 12px 0px rgba(59, 68, 164, 0.15);
			background-color: #f1f6f8;
			box-shadow: 0px 1px 1px rgba(72, 77, 104, 0.1);
			border-radius: 0px 16px 16px 16px;
			&:not(:first-of-type) {
				margin-top: 25px;
			}
			&__cont {
				color: #1f2236;
				font-weight: $medium;
				letter-spacing: 0.2px;
				line-height: 1.9rem;
				pre > img {
					max-width: 250px;
					max-height: 250px;
				}
				ul {
					list-style: inherit;
					margin-left: 2rem;
					margin-top: 5px;
				}
				ol {
					list-style: decimal;
					margin-left: 2rem;
					// margin-top: -20px;
				}
				li {
					margin-bottom: 3px;
					> div {
						margin-top: -18px;
					}
				}

				.subject-wrap {
					justify-content: space-between;
					padding: 6px 10px 0px 10px;
					.subject-text {
						line-height: 20px;
					}
					.bolt-icon {
						color: gold;
						font-size: 18px;
					}
					.automated-text {
						color: #11a1ae;
						font-weight: 600;
					}
					.cc-text-width {
						width: 450px;
					}
				}
			}
			&-container {
				// max-width: calc(100% - 56px);
				margin-left: 1.5rem;
			}
		}
		.chat-avatar-placeholder {
			width: 40px;
			min-width: 40px;
		}
		.chat-bubble-reply-container {
			margin-bottom: 8px !important;
			display: flex;
			flex-direction: column;
			.reply-sent {
				padding: 12px;
			}
			.no-subject-reply-message {
				background: none;
				box-shadow: none;
			}
		}
	}
	.conversation-end-text {
		color: rgba(57, 63, 83, 0.6);
		font-weight: 500;
		font-size: 12px;
		display: flex;
		justify-content: center;
		margin-bottom: 15px;
	}
}

// bot image
.bot-avatar-wrap {
	max-width: 40px;
	max-height: 40px;
	img {
		width: 43px;
		height: 43px;
		border-radius: 50%;
		object-fit: cover;
	}
	.user-info {
		@include avatar(40px, 40px, #78858d, 50%);
		min-width: 40px;
		min-height: 40px;
		max-height: 40px;
		max-width: 40px;
		text-align: center;
		font-size: 1.6rem;
		color: #ffffff;
		line-height: 19px;
		font-weight: 600;
		> img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}
	.bot-avatar-transparent {
		background-color: transparent !important;
	}
}

.conversation {
	&-container {
		position: relative;
		max-width: 300px;
		padding: 1rem;
		border-radius: 7px;
		background-color: var(--secondary-bg-light);
		box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31), 0 2px 2px 0 rgba(108, 117, 124, 0.16);
	}
	&-pic {
		width: 100%;
		height: 220px;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&-caption {
		color: $white;
		font-weight: $medium;
		padding: 1.2rem;
		background-color: $primary-brand-color;
		min-height: 44px;
	}
}
.pipeline-new {
	height: calc(100vh - 48px);
	@include respond-to(mobile) {
		// height: calc(100vh - 110px);
		.section-header,
		.actions-wrap {
			@include flex-wrap(wrap);
		}
		.btn--filter,
		.search-wrap {
			width: 100%;
			max-width: 100%;
			min-width: initial !important;
		}
		.search-wrap {
			&.inbox {
				margin-bottom: 0px !important;
			}
		}
		// .search-wrap,
		// .section-header__left {
		// margin-bottom: 10px;
		// }
		.section-col {
			max-width: 100%;
			&:first-child {
				margin-left: 0 !important;
			}
			&:last-child {
				margin-right: 0 !important;
			}
		}
		.pills {
			margin-bottom: 5px;
		}
		.card-item {
			@include flex-wrap(wrap);
			.item-left {
				width: 100%;
			}
			.item-right {
				@include flex-direction(row !important);
				@include align-items(center);
				@include justify-content(space-between);
				@include flex-grow(1);
				margin-top: 10px;
			}
		}
	}
	.exports-drop-down {
		background-color: transparent;
		border-color: transparent;
		border: 1px solid #dadada;
		.enquiries-menu {
			color: #5e7281;
			width: 68px;
		}
		.drop-down-icon {
			color: #5e7281;
		}
		.dropdown {
			min-width: 130px;
		}
		.download-dropdown-item {
			padding: 10px;
			cursor: pointer;
		}
	}
}
.pipeline-form {
	overflow-y: scroll;
	max-height: calc(100vh - 300px);
}
.sales_filter_selected_option_list {
	display: flex;
	flex-wrap: wrap;
}

.sales_filter_selected_option {
	min-width: fit-content;
	margin: 0.7rem 0.5rem 0 0;
	padding: 0 0.5rem;
	background: #e6ecff;
}
.sales_filter_selected_option_text {
	color: #2f57e6;
	.material-icons {
		font-size: 18px;
		vertical-align: text-top;
		margin-left: 1.5rem;
		color: #393f53;
		cursor: pointer;
	}
}
.sales_filter_header {
	padding: 1.5rem 2.5rem;
	border-bottom: 1px solid rgba(218, 218, 218, 0.5);
}
.sales_filter_footer {
	display: flex;
	padding: 1.5rem 2.5rem;
	flex-direction: row-reverse;
	border-top: 1px solid rgba(218, 218, 218, 0.5);
	flex-wrap: wrap;
}
.sales_filter_body {
	max-height: calc(100vh - 200px);
	overflow: scroll;
	padding: 2.5rem;
	@include respond-to(mobile) {
		max-height: calc(100vh - 125px);
	}
}
.sales_filter_button {
	margin-left: 0.5rem;
	padding: 0.5rem 1.6rem;
}
.sales_filter_reset_block {
	flex-direction: row;
}
.sales_first_filter {
	flex-grow: 1;
}
.sales_filter_reset_btn {
	text-decoration: underline;
	color: #2f57e6;
	cursor: pointer;
}
.sales_filter_options_list {
	z-index: 9;
	position: absolute;
	top: 40px;
	padding: 1rem 0;
	background: #fff;
	border: 1px solid #d7d3d3;
	border-radius: 2px;
	box-shadow: 0 3px 5px 0 rgba(180, 180, 180, 0.5);
	width: 100%;
	max-height: 204px;
	overflow-y: scroll;
}
.sales_filter_options_item {
	padding: 0.4rem 1.5rem;
}
.filter-popup-modal {
	display: none;
	&.show {
		display: block !important;
	}
}
.sales_filter_date_container {
	.react-datepicker-wrapper {
		width: 100%;
	}
	input {
		width: inherit !important;
	}
}
.sales_filter_amount_container {
	input {
		width: 100% !important;
	}
}
.sales_popup_modal_form {
	max-height: 450px;
	height: calc(100vh - 200px);
	overflow: scroll;
	padding: 25px;
	&-filter {
		height: calc(100vh - 250px);
	}
	@include respond-to(mobile) {
		height: calc(100vh - 170px);
	}
	.title {
		font-size: 13px;
		font-weight: $semibold;
		margin-bottom: 5px;
		text-transform: uppercase;
	}
}
.create-pipeline {
	.description {
		font-size: 1.4rem;
		color: #bfbdbd;
		word-wrap: break-word;
		text-overflow: hidden;
	}
}
.sales-pipeline-iframe-section {
	margin-top: 10px;
	a {
		display: flex;
		align-items: flex-end;
		color: #5fb3b7;
		font-weight: bold;
	}
	.material-icons {
		margin-right: 10px;
	}
	p {
		display: flex;
		align-items: flex-end;
		color: #5fb3b7;
		font-weight: bold;
		margin-top: 5px;
		cursor: pointer;
		span {
			height: 24px;
		}
	}
}
.pipeline-form {
	label {
		font-weight: 600 !important;
	}
}
.enable-pipeline-question-mark {
	border-radius: 50%;
	background: #d2d6d5;
	padding: 1px 6px;
	color: white;
}
.field-desc {
	color: #9a9d9c;
}
.sales-bot-type-tooltip {
	bottom: calc(100% + 12px);
	left: calc(50% - 19px);

	.tooltip_wrapper_arrow::before {
		content: '';
		display: inline-block;
		position: absolute;
		bottom: -9px;
		left: 11px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 10px solid #d7d3d3;
		border-bottom: 0 !important;
		top: inherit;
	}
	.tooltip_wrapper_arrow::after {
		content: '';
		display: inline-block;
		position: absolute;
		bottom: -8px;
		left: 11px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 11px solid white;
		border-bottom: 0 !important;
		top: inherit;
	}
	.tooltip_text {
		span {
			font-weight: normal;
		}
	}
}

.add-automation-wrap {
	display: flex;
	justify-content: center;
	align-items: center;

	.add-automation {
		align-items: center;
		display: flex;
		justify-content: center;
		color: #11a1ae;
		font-weight: 600;
		cursor: pointer;
	}
}

.hideVerticallySeparatingBar {
	&::after {
		display: none;
	}
}

.automation-status {
	// border: 1px solid #ece0ff;
	// border: 1px solid #c9fbff;
	background-color: #fff;
	position: relative;
	margin-bottom: 36px;
	cursor: pointer;
	border-radius: 2px;
	&.email-automation {
		border-color: #baa9da;
	}

	.automation-status-header {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		width: 100%;
		position: relative;

		&-title {
			font-size: 14px;
			font-weight: 600;
			margin-left: 0.5rem;
		}

		.zapier-automation-image-icon {
			width: 8%;
			margin-right: 8px;
			border-radius: 4px;
		}
		.pabau-automation-icon {
			width: 12%;
			margin-right: 12px;
			border-radius: 4px;
		}
		.icon-timer {
			width: 12%;
			color: #49adab;
		}

		.icon-input {
			color: #f9d25c !important;
		}

		.icon-pipeline_move {
			color: #49adab;
		}

		.icon-timer {
			color: #49adab;
		}

		.icon-account-circle {
			color: #4e94d4;
		}

		.icon-add-note {
			color: #49adab;
		}

		.icon-add-task {
			color: #438684;
		}

		.icon-add-zapier {
			color: #a1887f;
		}

		&::after {
			content: '';
			width: 299px;
			border-bottom: solid 1px #f5f5f5;
			position: absolute;
			left: 1px;
			top: 44px;
			z-index: 1;
		}

		.email-automation {
			color: #8c50f7;
		}
	}

	.automation-status-body {
		padding: 10px 16px 10px 0px;

		&-title {
			font-size: 13px;
			white-space: nowrap;
			width: 170px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	// &::after{
	//   content: "";
	//   background: #bbc4c4;
	//   z-index: 0;
	//   width: 2px;
	//   height: 40px;
	//   position: absolute;
	//   left: 49%;
	// }

	.automation-actions-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		z-index: 1;
		background: rgba(255, 255, 255, 0.7);
		display: none;

		.automation-actions {
			height: inherit;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&:hover .automation-actions-wrap {
		display: block;
	}
}

.external-email-border {
	border: 1px solid rgba(219, 193, 251, 0.8) !important;
}

.internal-email-border {
	border: 1px solid rgba(219, 193, 251, 0.8) !important;
}

.move-card-border {
	border: 1px solid rgba(249, 210, 92, 0.4) !important;
}

.move-to-another-pipeline-border {
	border: 1px solid #c9fbff !important;
}

.delay-time-border {
	border: 1px solid rgba(78, 165, 164, 0.3) !important;
}

.assign-enquiry-border {
	border: 1px solid rgba(78, 148, 212, 0.3) !important;
}

.notes-border {
	border: 1px solid rgba(188, 230, 229, 0.8) !important;
}

.task-border {
	border: 1px solid rgba(67, 134, 132, 0.3) !important;
}

.zaiper-border {
	border: 1px solid #a1887f59 !important;
}

.sms-border {
	border: 1px solid rgba(219, 193, 251, 0.8) !important;
}

.email-status-border-color {
	border: 1px solid #ece0ff !important;

	.email-icon {
		width: 12%;
		color: #8c50f7;
	}
}

.move-stage-border-color {
	border: 1px solid #ffeece !important;

	.move-icon {
		width: 12%;
		color: orange;
	}
}

.create-note-border-color {
	border: 1px solid #d0f7d0 !important;

	.description-icon {
		width: 12%;
		color: #4b9a4b;
	}
}

.add-automation {
	cursor: pointer;
	color: #49adab;
}

.automation-modal {
	background: rgba(0, 0, 0, 0.4);
}
.automation-container {
	width: 510px !important;
	height: auto !important;
	// top: 50% !important;
	// right: 50% !important;
	// left: 50% !important;
	left: 0;
	right: 0;
	top: 40px !important;
	margin: 0 auto !important;
	// transform: translate(-50%, -50%);

	.automation_body {
		padding: 1.8rem 2.5rem !important;
		// height: 477px;

		.popular_automation_list {
			height: 345px;
			overflow: auto;
		}

		.input-wrap {
			.title {
				position: relative;
				left: -8px;
				font-size: 1.8rem;
			}

			.sub-title {
				font-size: 1.6rem;
				font-weight: 400;
			}
		}

		.popular-options {
			.popular-option {
				background-color: #f3f7f9;
				padding: 1.5rem 1.5rem;
				cursor: pointer;
				color: #393f53;

				.option-title {
					font-size: 1.6rem;
				}

				.email-icon {
					color: #8c50f7;
				}

				.timer-icon {
					color: #49adab;
				}
			}
		}

		.bolt-icon {
			color: gold;
		}
	}

	.back_icon {
		font-size: 30px;
		cursor: pointer;
	}

	.email_body {
		// padding: 2.8rem 2.5rem !important;
		// padding: 0.2rem 2.5rem !important;
		padding: 0.8rem 2.5rem 0 !important;
		// height: 380px;
		height: 48%;
		overflow: auto;
		.chat-reply-textarea {
			.editor-container {
				@include respond-to(mobile) {
					min-height: calc(100vh - 587px) !important;
					max-height: calc(100vh - 587px) !important;
					overflow: auto;
					padding-right: 1px;
				}
				.editor-body {
					// max-height: 80px;
					max-height: 132px;
					// max-height: 102px;
					@include respond-to(mobile-ls) {
						max-height: 65px;
					}

					.DraftEditor-editorContainer {
						.public-DraftEditor-content {
							// height: 104px;
						}
					}
				}
			}
		}
		.chat-reply-top {
			border-radius: 4px;
			width: 100%;
			.app_dropdown__menu {
				z-index: 3;
			}
			.app_dropdown__control {
				background-color: transparent !important;
				border-color: transparent !important;
			}

			@include respond-to(mobile) {
				padding: 12px 0px;
				padding-bottom: 0px;
				.label-value {
					padding: 8px 12px;
					// padding-bottom: 5px;
					margin-bottom: 0px !important;
					border-bottom: 1px solid #f1f1f1;
					&:last-child {
						border-bottom: none;
					}
				}
			}
			.label-value {
				border-bottom: 1px solid #dbd9e4;
				padding: 10px 4px;

				.label {
					line-height: 19px;
					font-size: 16px;
					font-weight: 600;
					color: #a9a7a7;
					@include respond-to(mobile) {
						font-size: 1.45rem;
					}
				}

				.merge-text {
					color: #35838d;
					font-size: 15px;
					font-weight: 600;
					cursor: pointer;
				}

				&:not(:last-child) {
					// margin-bottom: 10px;
					margin-bottom: 8px;
				}
			}

			.select-wrap {
				flex: 1 1 auto;
				.custom-select {
					border: none;
					padding: 0;
				}
			}

			.value {
				color: #393f53;
				font-size: 18px;
				min-width: calc(100% - 25%);
				word-break: break-all;
				padding-right: 10px;

				.select-from-email {
					font-size: 18px !important;
					border: none !important;
					padding: 0px !important;

					@include respond-to(mobile) {
						font-size: 1.45rem !important;
					}
				}

				&.placeholder {
					color: rgba(57, 63, 83, 0.6);
				}
				@include respond-to(mobile) {
					font-size: 1.45rem;
				}
			}
			.email-pill {
				color: #393f53;
				font-size: 13px;
				line-height: 19px;
				border: 1px solid #e3e3e3;
				border-radius: 3px;
				background-color: #f4f4f4;
				padding: 0 5px;
				margin-right: 5px;
				margin-bottom: 5px;
				@include respond-to(mobile) {
					font-size: 1.45rem;
				}
				.material-icons {
					font-size: 14px;
					color: #5e7281;
					margin-left: 5px;
				}
			}
			.flex-wrap {
				flex-wrap: wrap;
			}
			.add-more {
				color: rgba(#393f53, 0.5);
				font-size: 13px;
				line-height: 19px;
				padding: 0;
				border: 0;
				background: transparent;
			}
			.email-text {
				color: rgba(#393f53, 0.9);
				// font-size: 18px;
				font-size: 1.4rem;
				line-height: 19px;
				padding: 0;
				border: 0;
				min-width: 120px;
				background: transparent;
				width: 100%;
				@include respond-to(mobile) {
					font-size: 1.45rem;
				}
			}
			.email_addvariable {
				.variable_selection_header {
					width: 120px;
					min-width: 120px;
				}
			}
		}

		.chat-reply-textarea {
			width: 100%;
			@include respond-to(mobile) {
				// min-height: calc(100vh - 264px);
				// max-height: calc(100vh - 264px);
				// overflow: auto;
			}
			.editor-container {
				border: none;
			}
		}

		.email-advance-condition {
			max-height: 240px;
			overflow-y: scroll;
		}
	}

	.zapier_webhook {
		padding: 1.8rem 2.5rem !important;
		&__body {
			padding-bottom: 0px;
			overflow: auto;
			height: 40vh;
			min-height: 325px;
			.zapier-title {
				font-size: 1.4rem;
				line-height: 2rem;
				font-weight: 600;
				margin-bottom: 0.5rem;
			}
			.zapier-sub-title {
				font-size: 1.2rem;
				line-height: 2rem;
				// font-weight: 600;
				margin-bottom: 1rem;
			}
			.variables-list {
				.variables-list {
					flex-wrap: wrap;
					overflow-y: auto;
					max-height: 100px;
					height: 100px;
				}
			}
		}
	}
	.pabau {
		height: 47vh !important;
		padding-bottom: 80px !important;
		&__info-text {
			font-size: 14px;
			color: #6c6f7c;
		}
	}
	.delay_timer_body {
		padding: 1.8rem 2.5rem !important;

		.timer-header {
			padding-bottom: 10px;
			border-bottom: 1px solid #eff0f1;
			width: 100%;

			.title {
				font-size: 16px;
				color: #202d56;
			}

			.desc {
				font-size: 16px;
				color: #202d56;
				font-weight: 600;
			}
		}

		.timer-body {
			height: 240px;
			&-row {
				align-items: baseline;
				display: flex;
				width: 100%;

				.label-wrap {
					width: 25%;
					.input-label {
						font-size: 16px;
					}
				}

				.select-wrap {
					display: flex;
					column-gap: 10px;
					width: 35%;
				}
			}

			.timer-input {
				background: #f3f7fe;
				width: 65px;
			}

			&-select {
				display: flex;
				justify-content: center;
				column-gap: 5px;

				.select-time {
					display: flex;
					// width: 35%;
					width: 40%;
					column-gap: 4px;
				}

				.dash-icon {
					position: relative;
					font-size: 20px;
					top: 8px;
					left: 0px;
				}

				@include respond-to(mobile) {
					justify-content: flex-start;
					// column-gap: 20px;
					column-gap: 10px;

					.dash-icon {
						// left: 14px;
						left: 2px;
					}
				}
			}
		}

		.custom-select {
			width: 100%;
			font-size: 1.4rem;
			line-height: 2rem;
			padding: 0.9rem 3rem 0.9rem 1.6rem;
			//background-color: #fff;
			background: #f3f7f9
				url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCAxMCA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+YXJyb3ctZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNy4wMDAwMDAsIC0xMC4wMDAwMDApIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIGZpbGwtb3BhY2l0eT0iMC41IiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSIwIDAgMjQgMCAyNCAyNCAwIDI0Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=)
				right 10px center no-repeat;
			border: 1px solid #dadada;
			border-radius: 2px;
			-webkit-appearance: none;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.automation-body {
		padding: 1.8rem 2.5rem !important;
		height: 364px;
		overflow-y: auto;
		overflow-x: hidden;
		margin-bottom: 7rem;

		.bot-container {
			> .bot-label {
				font-weight: 600;
				font-size: 18px;
			}
			> .bot-name {
				font-size: 18px;
			}
		}

		.pipeline-stage-header {
			.title {
				font-size: 18px;
			}
		}
	}

	.action-wrap-body {
		.notes-form-actions {
			.item {
				&:last-child {
					border-bottom: none;
				}
			}
		}
		.actions-details {
			height: 470px;
			padding: 1rem 1.2rem;
			.item {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #eff0f1;
				padding: 10px 15px;

				.value {
					color: #393f53;
					font-size: 15px;
					font-weight: 500;
					line-height: 22px;
				}

				.info {
					color: #6d90ad;
					font-size: 18px;
				}
			}

			.actions-notes {
				border-top: 1px solid #eff0f1;
				border-bottom: 1px solid #eff0f1;
				padding: 10px 10px;

				.editor {
					margin: 0;
					box-shadow: none;
					border: none;
				}
			}

			.border-top-none {
				border-top: none !important;
			}
		}

		.value-light-green {
			cursor: pointer;
			color: #11a1ae;
			font-size: 13px;
			font-weight: 500;
			line-height: 19px;
		}

		.value {
			color: #393f53;
			font-size: 15px;
			font-weight: 500;
			line-height: 22px;
		}

		.icon-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #f3f7f9;
			border-radius: 50%;
			height: 23px;
			width: 23px;
			min-height: 23px;
			min-width: 23px;
			border: 0.5px solid #eff0f1;
			cursor: pointer;

			.material-icons {
				color: #6d90ad;
			}
		}

		.active {
			background: #28a25f;
			border: 0.5px solid #eff0f1;

			.material-icons {
				color: #fff;
				font-weight: bold;
			}
		}

		.icon-wrap-big {
			height: 30px;
			width: 30px;
			min-height: 30px;
			min-width: 30px;

			.material-icons {
				font-size: 18px;
			}
		}

		@include respond-to(mobile) {
			overflow: hidden;
		}
	}
}

.salesforce-container {
	height: 85% !important;
	width: 40vw !important;
	@include respond-to(mobile-ls) {
		width: 80vw !important;
	}
}

.email-container {
	height: 85% !important;
	&.sms-container {
		height: 570px !important;
	}

	width: 44vw !important;
	@include respond-to(mobile-ls) {
		width: 80vw !important;
	}
	.tabs-container .tab.active::after,
	.tabs-container .tab:hover::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		transform: scale(1);
		height: 2px;
		background: #ec5959;
	}
	.status-toggle {
		float: right;
		position: inherit !important;
	}
	.rte-btn {
		padding: 0.9rem 0.9rem;
	}
	@include respond-to(mobile) {
		height: 430px !important;
	}
}
.email-body-height {
	height: 90% !important;
}
.add-stage-section {
	border: none !important;
	background-color: transparent !important;
	.cont-header {
		border: none !important;
		border-radius: 5px !important;
		padding: 1.3rem !important;
	}
	.add-stage-input-container {
		border: 1.5px solid rgba(151, 151, 151, 0.4);
		padding: 0.5rem;
		border-radius: 4px;
		width: 100%;

		input {
			border: none;
			width: inherit;
		}
	}
}
.stage-view-btns {
	// border: 1px solid #dadada;
	// border-radius: 2px;
}
.stage-view-card,
.stage-view-list {
	padding: 7px;
	border: none !important;
	border-radius: 0 !important;
	&-active {
		color: #fff !important;
		background-color: #11a1ae !important;
		background-color: #5e7281 !important;
	}
}
.go-back-btn {
	background: #ccc4b114;
	color: #393f53;
	font-size: 14px;
	line-height: 2rem;
	font-weight: 500;
	padding: 0.9rem 1.5rem;
	background: #fff;
	border: 1px solid #dadada;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s ease;
}
.back-btn {
	background-color: #ffc02114;
	border: 1px solid #ffc021;
}
.icon-color {
	color: #ffc021 !important;
	font-size: 2.2rem;
}
.icon-color-style {
	color: #ffc021 !important;
	font-size: 2.2rem;
}
