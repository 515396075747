.aside {
	position: fixed;
	top: 54px;
	left: 0;
	width: 61px;
	height: calc(100vh - 54px);
	// background: $primary-brand-color;
	// background: #FFFFFF;
	background-color: #22243d;
	z-index: 20;
	@include respond-to(mobile) {
		transition: 0.3s linear;
		transform: translateX(-100%);
		&.open {
			transform: translateX(0);
		}
	}
	.aside {
		&-menu {
			&_bottom {
				bottom: 8rem;
			}
			&__item {
				position: relative;
				text-align: center;
				padding: 0.9rem;
				> a {
					padding: 1.1rem;
					// border-radius: 5px;
					// color: #bebfc4;
					color: #f4f4f4;
					opacity: 0.6;
					.bot-menu-icon {
						fill: #f4f4f4;
						// opacity: .6;
					}
					@include transition(all 0.3s ease);
					img {
						position: relative;
						top: -3px;
					}
					&.active {
						// border-right: 3px solid #00ABBA;
						// color: #1F2236;
						color: #ffffff;
						opacity: 1;
						background-color: rgba(255, 255, 255, 0.1);
						border-radius: 6px;
						.bot-menu-icon {
							// fill: #1F2236;
							color: #ffffff;
							opacity: 1;
						}
					}
				}
				&:hover {
					> a {
						// color: #1F2236;
						color: #ffffff;
						opacity: 1;
						background-color: rgba(255, 255, 255, 0.1);
						border-radius: 6px;
						// border-right: 3px solid #00ABBA;
						.bot-menu-icon {
							// fill: #1F2236;
							color: #ffffff;
						}
					}
					.aside-menu__label {
						@include respond-to(mobile) {
							opacity: 0;
							visibility: hidden;
						}
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
			}
			&__label {
				position: absolute;
				top: 15px;
				left: 108%;
				background: $white;
				text-transform: uppercase;
				color: #26282f;
				font-weight: $medium;
				padding: 0.8rem 1.5rem 0.8rem 2rem;
				border-radius: 3px;
				box-shadow: 0 2px 4px 0 #d0d3e1;
				opacity: 0;
				visibility: hidden;
				transform: translateY(100%);
				@include transition(all 0.5s ease);
				z-index: 2;
				&::before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 7px;
					left: -21px;
					border: 9px solid transparent;
					border-right: 12px solid rgba(#d0d3e1, 0.3);
				}
				&::after {
					content: '';
					display: inline-block;
					position: absolute;
					top: 8px;
					left: -19px;
					border: 8px solid transparent;
					border-right: 11px solid white;
				}
			}
		}
	}
}
