.tasks {
	.tasks-heading {
		font-size: 32px;
	}
	.section-main {
		height: calc(100vh - 54px);
		padding: 25px;
		padding-top: 10px;
		overflow: hidden;
		@include respond-to(mobile) {
			padding: 15px;
		}
	}
	.task-section-header {
		@include flexbox();
		align-items: center;
		width: 100%;
		background: #ffffff;
		height: 70px;
		max-height: 70px;
		padding: 15px 25px;
		box-shadow: 0px 10px 30px rgba(4, 108, 117, 0.04);
		border-radius: 16px;
		margin-bottom: 20px;
		margin-top: 10px;
		@include respond-to(mobile) {
			margin-bottom: 18px;
			padding: 15px 5px;
		}
	}
	.section-body {
		width: 100%;
		// height: calc(100vh - 190px);
		height: calc(100vh - 180px);
		@include flexbox();
		justify-content: space-between;
		box-sizing: border-box;
		@include respond-to(mobile) {
			flex-direction: column;
			height: auto;
		}
		.task-left {
			flex-grow: 1;
			max-width: 58.5%;
			min-width: 58.5%;
			height: 100%;
			background: #ffffff;
			box-shadow: 0px 10px 30px rgba(4, 108, 117, 0.04);
			border-radius: 16px;
			padding: 15px 0px;
			@include respond-to(mobile) {
				min-width: 100%;
				max-width: 100%;
				height: calc(100vh - 235px);
			}
		}
		.task-right {
			flex-grow: 1;
			max-width: 40%;
			min-width: 40%;
			// height: calc(100vh - 190px);
			position: relative;
			height: 100%;
			background: #ffffff;
			box-shadow: 0px 10px 30px rgba(4, 108, 117, 0.04);
			border-radius: 16px;
			padding: 15px 25px;
			// overflow: auto;
			overflow: hidden;
			@include respond-to(mobile) {
				// display: block;
				// max-width: 100%;
				// margin-top: 30px;
				// margin-top: 50px;
				padding: 15px;
				// padding: 15px 0px;
				min-width: 100%;
				max-width: 100%;
				// height: calc(100vh - 85px);
				// height: calc(100vh - 165px);
				height: calc(100vh - 150px);
				// height: calc(100vh - 185px);
				margin-top: 0;
			}
			.task-details-empty {
				height: 100%;
			}
		}
	}
	.custom-checkbox-task {
		.checkbox {
			&-label {
				cursor: pointer;
				width: 20px;
				height: 20px;
			}
			&-icon-wrap {
				@include avatar(20px, 20px, $white, 0);
				min-width: 20px;
				min-height: 20px;
				// border: 1px solid #000000;
				// border: 1px solid #dcdcdc;
				border: 1px solid #808080;
				// border-radius: 5px;
				border-radius: 50%;
				position: relative;
				@include respond-to(mobile) {
					@include avatar(14px, 14px, $white, 0);
					// border-radius: 3px;
					border-radius: 50%;
				}
				&::before {
					content: '';
					font-family: 'Material Icons';
					font-style: normal;
					font-size: 17px;
					font-weight: $bold;
					display: inline-block;
					line-height: 1;
					text-transform: none;
					letter-spacing: normal;
					word-wrap: normal;
					white-space: nowrap;
					direction: ltr;
					// @include transition(all 0.3s ease);
					color: $white;
				}
				&:hover {
					&::before {
						// content: "";
						content: '\e5ca';
						font-size: 14px;
						// border-radius: 4px;
						border-radius: 50%;
						// width: 18px;
						width: 100%;
						// height: 18px;
						height: 100%;
						position: absolute;
						top: 50%;
						bottom: 50%;
						left: 0;
						right: 0;
						margin: auto;
						background-color: #11a1ae;
						padding: 2px 0 0 2px;
						// opacity: 0.5;
						opacity: 0.6;
						@include respond-to(mobile) {
							width: 12px;
							height: 12px;
							// border-radius: 2px;
							border-radius: 50%;
							font-size: 10px;
							padding: 1px 0 0 1px;
						}
						/*This will work for firefox*/
						@-moz-document url-prefix() {
							top: 48%;
						}
						/*This will work for imac 27inch */
						@media screen and (min-width: 2560px) {
							top: 50%;
							left: 50%;
							bottom: unset !important;
							right: unset !important;
							transform: translate(-50%, -50%);
							margin: unset !important;
						}
					}
				}
			}
			&-txt {
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.6rem;
				margin-left: 1.5rem;
			}
		}
		input:checked + .checkbox-label .checkbox-icon-wrap {
			// background-color: #12b13f;
			border: 1px solid #808080 !important;
			&::before {
				content: '\e5ca';
				border-radius: 50%;
				// width: 18px;
				// height: 18px;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 50%;
				bottom: 50%;
				left: 0;
				right: 0;
				margin: auto;
				background-color: #11a1ae;
				opacity: 0.6;
				font-size: 14px;
				padding: 2px 0 0 2px;
				@include respond-to(mobile) {
					width: 12px;
					height: 12px;
					border-radius: 50%;
					font-size: 10px;
					padding: 1px 0 0 1px;
				}
				@-moz-document url-prefix() {
					top: 46%;
				}
			}
		}
		input:disabled + .checkbox-label {
			cursor: not-allowed;
		}
		input:disabled + .checkbox-label .checkbox-icon-wrap {
			border: 1px solid #c9c9c9 !important;
			&::before {
				background-color: #c9c9c9;
			}
		}

		&.tick_box_completed {
			input:checked + .checkbox-label .checkbox-icon-wrap {
				background-color: #c9c9c9 !important;
				border: 1px solid #c9c9c9 !important;
				&::before {
					content: '\e5ca';
					color: $white;
					background-color: #c9c9c9;
					font-size: 14px;
					top: 50%;
					bottom: 50%;
					left: 0;
					right: 0;
					border-radius: 50%;
					padding: 2px 0 0 2px;
					opacity: 1;

					@include respond-to(mobile) {
						left: 0px;
						right: 4px;
						top: 0px;
						bottom: 4px;
						font-size: 10px;
						padding: 2px 0 0 1px;
					}
				}
			}
		}
	}
	.task_name_section {
		display: none;
		@include respond-to(mobile) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 18px;
			margin-top: 5px;
		}

		.task_name {
			font-size: 28px;
			font-weight: 700;
			color: #1f2236;
		}

		.btn_add {
			border-radius: 8px;

			.add-icon {
				margin-right: 8px;
			}
		}
	}
}
.custom-checkbox-task {
	.checkbox {
		&-label {
			cursor: pointer;
		}
		&-icon-wrap {
			@include avatar(20px, 20px, $white, 0);
			min-width: 20px;
			min-height: 20px;
			// border: 1px solid #000000;
			border: 1px solid #808080;
			// border-radius: 5px;
			border-radius: 50%;
			position: relative;
			@include respond-to(mobile) {
				@include avatar(14px, 14px, $white, 0);
				// border-radius: 3px;
				border-radius: 50%;
			}
			&::before {
				content: '';
				font-family: 'Material Icons';
				font-style: normal;
				font-size: 17px;
				font-weight: $bold;
				display: inline-block;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;
				// @include transition(all 0.3s ease);
				color: $white;
			}
			&:hover {
				&::before {
					// content: "";
					content: '\e5ca';
					font-size: 14px;
					padding: 2px 0 0 2px;
					// border-radius: 4px;
					border-radius: 50%;
					// width: 18px;
					// height: 18px;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					bottom: 50%;
					left: 0;
					right: 0;
					margin: auto;
					background-color: #11a1ae;
					@include respond-to(mobile) {
						width: 12px;
						height: 12px;
						// border-radius: 2px;
						border-radius: 50%;
						padding: 1px 0 0 1px;
						font-size: 10px;
					}
					@-moz-document url-prefix() {
						top: 45%;
						padding: 2px 0 0 2px;
					}
					/*This will work for imac 27inch */
					@media screen and (min-width: 2560px) {
						top: 50%;
						left: 50%;
						bottom: unset !important;
						right: unset !important;
						transform: translate(-50%, -50%);
						margin: unset !important;
					}
				}
			}
		}
		&-txt {
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.6rem;
			margin-left: 1.5rem;
		}
	}
	input:checked + .checkbox-label .checkbox-icon-wrap {
		// background-color: #12b13f;
		border: 1px solid #11a1ae !important;
		&::before {
			content: '\e5ca';
			border-radius: 50%;
			width: 100%;
			height: 100%;
			font-size: 14px;
			padding: 3px 0 0 3px;
			position: absolute;
			top: 50%;
			bottom: 50%;
			left: 0;
			right: 0;
			margin: auto;
			background-color: #11a1ae;
			@include respond-to(mobile) {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				font-size: 10px;
				padding: 1px 0 0 1px;
			}
			@-moz-document url-prefix() {
				top: 45%;
				padding: 2px 0 0 2px;
			}
		}
	}
	input:disabled + .checkbox-label {
		cursor: not-allowed;
	}
	input:disabled + .checkbox-label .checkbox-icon-wrap {
		border: 1px solid #c9c9c9 !important;
		&::before {
			background-color: #c9c9c9;
		}
	}

	&.tick_box_completed {
		input:checked + .checkbox-label .checkbox-icon-wrap {
			background-color: #c9c9c9 !important;
			border: 1px solid #c9c9c9 !important;
			&::before {
				content: '\e5ca';
				color: $white;
				background-color: #c9c9c9;
				font-size: 14px;
				top: 50%;
				bottom: 50%;
				left: 0;
				right: 0;
				// padding: 0px;
				padding: 3px 0 0 3px;

				@include respond-to(mobile) {
					left: 0px;
					right: 4px;
					top: 0px;
					bottom: 4px;
					border-radius: 50%;
					font-size: 10px;
					padding: 2px 0 0 1px;
				}
				@-moz-document url-prefix() {
					top: 45%;
					padding: 2px 0 0 2px;
				}
			}
		}
	}
}
.todo_type {
	height: 16px;
	background: rgba(247, 30, 120, 0.1);
	border-radius: 3px;
	border: transparent;
	font-size: 12px;
	font-weight: 500;
	color: #f71e78;
	width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px 5px;
	white-space: nowrap;
	max-width: 260px;
	overflow: hidden;
	text-overflow: ellipsis;
	&.recomend {
		background: rgba(124, 197, 6, 0.13);
		color: #7cc506;
	}
	&.call {
		background: rgba(255, 184, 46, 0.14);
		color: #ffb82e;
	}
	&.meeting {
		background: rgba(21, 134, 238, 0.1);
		color: #1586ee;
	}
	&.email {
		background: rgba(0, 171, 186, 0.1);
		color: #00abba;
	}
	&.disabled {
		background: rgba(201, 201, 201, 0.19);
		color: #c9c9c9;
	}
	&.green {
		background: rgba(124, 197, 6, 0.13);
		color: #7cc506;
	}
}

.add-task-select {
	.app_dropdown__control {
		border-radius: 8px;
		background-color: #fafafa !important;
		padding: 2px 0;
		&:hover {
			border: 1px solid #00abba;
			cursor: pointer;
		}
		.app_dropdown__value-container {
			.css-1uccc91-singleValue {
				color: #1f2236;
				font-size: 14px;
				font-weight: 550;
			}
		}
	}
	.app_dropdown__menu {
		border-radius: 6px !important;
		padding: 5px !important;
		background: #fff;
	}

	.app_dropdown__option {
		border-radius: 8px !important;
		padding: 8px 15px 8px 10px !important;
		// padding: 12px 15px 12px 10px !important;
		color: #808080;

		&:active {
			background-color: #fff !important;
		}

		&:hover {
			background-color: #f4f4f4 !important;
			// background-color: #fff !important;
			color: black !important;
		}
	}

	.css-1bht853-menu {
		margin-top: 3px;
	}

	.css-e09gdm-MenuList {
		padding: 0px 5px;
	}
	.css-14mwme9-option {
		background-color: #fff;
	}
	.css-1drmoyv-option {
		background-color: #fff;
	}
}
.task-select {
	.app_dropdown__control {
		height: 43px;
		border-radius: 8px;
		background-color: #fafafa !important;
		padding: 2px 0;
		&:hover {
			border: 1px solid #00abba;
			cursor: pointer;
		}

		.app_dropdown__value-container {
			.css-1uccc91-singleValue {
				color: #1f2236;
				font-size: 14px;
				font-weight: 550;
			}
		}
	}
	.app_dropdown__menu {
		border-radius: 6px !important;
		padding: 5px !important;
		background: #fff !important;
	}

	.app_dropdown__option {
		// border-radius: 8px !important;
		// padding: 12px 15px 12px 10px !important;
		// color: #808080;
		// margin-bottom: 3px;
		margin-bottom: 3px;
		border-radius: 8px;
		padding: 10px 8px;
		color: #8c8e9a;

		&:active {
			background-color: #f4f4f4;
		}

		&:hover {
			background-color: #f4f4f4;
			color: black;
		}
	}

	.css-1bht853-menu {
		margin-top: 3px;
		background-color: #fff !important;
	}
	.css-e09gdm-MenuList {
		padding: 0px 5px;
	}
	.css-14mwme9-option {
		background-color: #fff;
	}
	.css-1drmoyv-option {
		background-color: #fff;
		// background-color: #FAFAFA;
	}
}

.task-calendar {
	.calander-container {
		width: 250px; //dont change the calendar width here, it will affect
	}
	&__header {
		.task-calendar-month-name {
			font-size: 18px;
			font-weight: 700;
		}
		.task-calendar-switch {
			border-radius: 22px;
			border: 1px solid #e0e0e1;
			background: #fafafa;
			padding: 3px;
			margin-right: 6px;
			height: 32px;
			.material-icons {
				font-size: 24px;
				color: #000;
			}
		}
	}
	.days {
		padding-left: 0px !important;
		padding-right: 0px !important;
		.day {
			width: 10px !important;
			height: 35px !important;
			border-radius: 22px !important;
		}
	}
}

.task-mentions {
	background: #fafafa;
	width: 100%;
	border-radius: 8px;
	border: 1px solid #e0e0e1;
	padding: 7.5px 10px 7.5px 10px;
	color: hsl(0, 0%, 50%);
	box-shadow: none;
	.DraftEditor-editorContainer {
		.public-DraftEditor-content {
			padding: 0px;
			background: #fafafa;
			min-height: 110px;
			max-height: 110px;
		}
	}
	.public-DraftEditorPlaceholder-inner {
		padding: 5px;
	}
}

.note_edit {
	max-height: 60px !important;
	min-height: 60px !important;
	.DraftEditor-editorContainer {
		.public-DraftEditor-content {
			padding: 0px;
			background: #fafafa;
			min-height: 50px;
			max-height: 50px;
		}
	}
	.public-DraftEditorPlaceholder-inner {
		padding: 5px;
	}
}

.task-card-details {
	.wide-modal-container {
		// height: calc(100vh - 80px);
		// margin-top: 20px;
		.inbox-container.pop-up {
			// height: calc(100vh - 160px);
			border-radius: 5px;
			.enquires-section {
				border-radius: 5px;
			}
		}
		// .chat-body {
		//   height: calc(100vh - 276px);
		// }
		.pipeline-modal {
			border-radius: 5px;
		}
		.chat-section {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		.chat-header {
			border-top-left-radius: 5px;
			// border-radius: 5px;
		}
		.chat-footer {
			border-bottom-left-radius: 5px;
		}
		.notes-body {
			border-bottom-left-radius: 5px;
		}
		.product-container {
			border-bottom-left-radius: 5px;
		}
		.activity-body {
			border-bottom-left-radius: 5px;
		}
		.deal-section {
			border-bottom-right-radius: 5px;
		}
	}
	.wide-modal-main {
		overflow-y: visible;
		overflow-x: visible;
	}
	.task-close-btn {
		height: 30px;
		right: -14px;
		top: -14px;
		color: $white;
		background-color: #00abba;
		border: 2px solid $white;
	}
}
