.file-library-container {
	// height: calc(100vh - 240px);
	height: calc(100vh - 196px);
	overflow-y: auto;
	padding-bottom: 60px;
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	.file-library-items {
		grid-gap: 30px;
		gap: 30px;
		flex-wrap: wrap;
		.file-library-item {
			background-color: #f5f5f5;
			width: 220px;
			padding: 2px;
			position: relative;
			border-radius: 6px;
			// height: 280px;
			// height: 278px;
			height: 275px;
			box-shadow: 0 1px 2px 0 rgba(91, 88, 88, 0.5);
			.file-content-wrap {
				padding: 14px 15px 12px;
				height: 192px;
				position: relative;
				.file-content {
					height: 175px;
					&__thumbnail-image {
						height: 175px;
						width: 100%;
						object-fit: cover;
					}
					&__thumbnail {
						font-size: 80px;
						margin-top: 40px;
					}
					.file-preview-image {
						width: 100%;
						height: inherit;
					}
					.view-wrap {
						background-color: #5a5a5a;
						opacity: 0.6;
						height: 192px;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
						border-top-left-radius: 4px;
						border-top-right-radius: 4px;
						.eye-icon {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							color: #ffffff;
						}
					}
				}
			}

			.file-content-wrap .view-wrap {
				display: none;
			}

			.file-content-wrap:hover .view-wrap {
				display: block;
			}

			.file-info-wrap {
				position: relative;
				background-color: #ffffff;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				.dropdown-menu {
					position: absolute;
					right: 4px;
					top: 14px;

					.more-icon {
						color: #848484;
						z-index: 1;
					}
					.dropdown {
						border: 1px solid #e5e5e5;
						box-shadow: none;
						top: 38px;
						right: -4px;
					}
					.dropdown-file-library {
						&::before {
							content: '';
							border-bottom: 10px solid #e5e5e5 !important;
						}
					}
				}
				.file-info-data-wrap {
					padding: 10px 16px;
					.title-wrap {
						color: #393f53;
						font-size: 16px;
						font-weight: 600;
						margin-bottom: 8px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 180px;
						max-width: 180px;
					}
					.title-edit-wrap {
						position: relative;
						z-index: 2;
						.title-field {
							// padding: 6px 7px;
							padding: 6px 22px 6px 7px;
							font-weight: 500;
							width: 162px;
						}

						.save-icon {
							position: absolute;
							right: 25px;
							top: 6px;
							font-size: 20px;
						}

						.close-icon {
							position: absolute;
							right: 2px;
							top: 6px;
							font-size: 20px;
						}
					}
					.desc-wrap {
						position: relative;
						.profile-container {
							width: 28px;
							height: 28px;
							min-height: 28px;
							min-width: 28px;
							background-color: #dadada;
							border-radius: 100%;
							text-align: center;
							line-height: 30px;
							font-weight: bold;
							background: #77858d;
							color: white;
							margin-right: 8px;
							.user-info {
								margin-top: auto;
								text-align: center;
								font-size: 1.2rem;
								color: #ffffff;
								line-height: 29px;
								font-weight: 600;
							}
						}
						.user-name {
							color: #777a82;
							font-size: 12px;
							font-weight: 400;
							margin-right: 10px;
							width: 74px;
							max-width: 74px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
						.file-upload-day {
							color: #777a82;
							font-size: 12px;
							font-weight: 400;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 64px;
							max-width: 64px;
							&::before {
								content: '';
								position: absolute;
								display: block;
								border-top: 10px solid #777a82;
								width: 1px;
								top: 8px;
								// right: 75px;
								right: 72px;
							}
						}
						.profile-pic {
							width: 28px;
							height: 28px;
							max-width: 28px;
							max-height: 28px;
							border-radius: 50%;
						}
					}

					.visible-hidden {
						visibility: hidden;
					}
				}
				.pb-5 {
					padding-bottom: 5px !important;
				}
			}
		}
	}

	.filter-card-empty-wrap {
		height: 100%;
		width: 90%;
		margin-top: 9%;

		.filter-card-empty-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			.file-library-empty-title {
				font-weight: bold;
			}
		}
	}
}

.file-library-modal {
	width: 589px;
	margin-left: auto;
	margin-right: auto;

	.file-library-body {
		padding: 22px 24px !important;

		.file-input-wrap {
			margin-bottom: 28px !important;

			.sub-label {
				color: #979797;
				font-size: 12px;
				margin-bottom: 12px;
			}
			.file-upload-wrap {
				display: flex;
				cursor: pointer;
				flex-direction: column;
				text-align: center;
				padding: 48px 0 27px 0;
				background-color: #f2f7f9;
				border: 1px dashed #5eb6d9;
				border-radius: 2px;
				box-sizing: border-box;

				.folder-icon {
					color: #11a1ae;
					font-size: 34px;
					margin-bottom: 22px;
				}

				.folder-text {
					color: #979797;
					font-size: 12px;
				}
				.hide-file-input {
					width: 0.1px;
					height: 0.1px;
					opacity: 0;
					overflow: hidden;
					position: absolute;
					z-index: -1;
				}
			}
		}
		.file-progress-bar {
			width: 60%;
			background-color: #f2f7f9;
			.upload-progress-bar {
				height: 14px;
				background-color: #11a1ae;
				border-radius: 4px;
			}
		}
		.upload-file {
			background-color: #f2f7f9;
			padding: 5px 5px;
			width: 100%;
			.file-name {
				font-weight: 500;
				font-size: 14px;
				width: 100%;
				.uploaded-file-name {
					// width:60%;
					width: 38%;
					padding-left: 1rem;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.remove-icon {
				font-size: 14px;
				cursor: pointer;
				color: #979797;
				margin-left: 5px;
			}
		}
		.file-size-wrap {
			.file-size-text {
				color: #979797;
				font-size: 12px;
			}
			.file-compress-link {
				font-weight: 700;
				color: #11a1ae;
				text-decoration: underline;
				font-size: 12px;
			}
		}
	}

	.file-library-footer {
		border-top: 1px solid #eeeeee;
		padding-top: 1.7rem !important;
		.upload-button {
			padding-left: 2px !important;
			.loader-icon {
				height: 20px;
				width: 20px;
				.lds-ring {
					height: 20px;
					width: 20px;
					div {
						border-color: #fff4e1 transparent transparent transparent;
						border-width: 4px;
						top: -7px;
						height: 20px;
						width: 20px;
					}
				}
			}
		}
	}
}

.empty-library-state {
	.section-body {
		.empty-state-container {
			.empty-library-text-wrap {
				display: contents;
				.empty-library-text {
					color: #777a82;
					font-size: 15px;
					font-weight: 500;
					margin-bottom: 22px;
				}
				.file-library-add-btn {
					width: max-content !important;
				}
			}
		}
	}
}
