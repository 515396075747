.recent-enquiries-card {
	.enquires-card {
		cursor: pointer;
		padding: 10px 15px;
		background-color: #fff;
		width: 99.6%;
		border-bottom: 0.5px solid #f3f7f9;

		.profile-info {
			.profile-row {
				position: relative;
				width: 100%;
			}
		}

		.avatar-pic {
			width: 30px;
			height: 30px;
			min-height: 23px;
			min-width: 23px;
			border-radius: 100%;
			text-align: center;
			line-height: 24px;
			font-weight: 600;
			background: #77858d;
			color: black;
			font-size: 12px;
			top: 10px;
			position: absolute;

			.user-info {
				padding-top: 2px;
			}
		}

		.online {
			color: #28a25f;
			font-size: 10px;
		}
	}

	.today-enquiry {
		box-shadow: inset 5px 0 0 0 #fbbe4d;
	}

	.enquiry-name {
		color: #000;
		font-size: 15px;
		line-height: 22px;
		text-transform: capitalize;
		position: absolute;
		left: 42px;
	}

	.enquiry-day {
		color: rgba(57, 63, 83, 0.6);
		font-size: 12px;
		line-height: 18px;
		font-weight: 600;
		min-width: max-content;
	}

	.enquiry-actions {
		min-height: 23px;
	}

	.enquiry-type {
		font-size: 13px;
		text-transform: capitalize;
		padding: 0px 3px;
		border-radius: 3px;
		color: #9c9e9f;
	}
	.empty-list {
		width: 300px;
		text-align: center;
		margin-top: 70px;
		color: #767676;
	}
}

.activities-card {
	.activity-body {
		background-color: #fff;

		.activity-container {
			padding: 1rem 0rem 1rem 2.9rem;
			position: relative;
			height: calc(100vh - 214px) !important;
			overflow-y: scroll;
		}

		.activity-item {
			margin-top: 10px;
			position: relative;

			.content {
				background: #fff;
				margin-left: 28px;
				margin-bottom: 15px;
				padding: 8px 6px;
				/*box-shadow: 0px 0px 6px 0px rgb(57 114 120 / 18%); */

				&-text {
					font-size: 1.5rem;
					color: rgba(57, 63, 83, 0.6);
					font-weight: 300;
				}

				&-time {
					font-size: 12px;
					color: gray;
					font-weight: 400;
				}
			}

			.value {
				color: #393f53;
				font-size: 15px;
				font-weight: 600;
				line-height: 22px;
			}

			&:not(:last-child) .activity-bullet::after {
				content: '';
				background: #bbc4c4;
				z-index: 0;
				width: 1px;
				height: 100%;
				position: absolute;
				left: 10px;
				top: 30px;
			}
		}

		.activity-bullet {
			float: left;
			height: 20px;
			width: 20px;
			margin-top: 10px;
			border: 1px solid #11a1ae;
			background-color: #fef6ee;
			border-radius: 50%;
			display: -moz-flex;
			display: flex;
			justify-content: center;
			align-items: center;

			.material-icons {
				color: #11a1ae;
				font-size: 13px;
				font-weight: 800;
			}
		}
	}
}
