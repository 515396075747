.integration {
	width: 775px;
	&-box {
		margin-top: 15px;
		width: 230px;
		padding: 1.2rem;
		border: 1px solid #f5f0f0;
		border-radius: 5px;
		&:not(:nth-child(3n)) {
			margin-right: 20px;
		}
		.details {
			width: 100%;
			position: relative;
			padding-right: 40px;
			.title {
				font-size: 1.6rem !important;
				text-transform: none !important;
			}
			.desc {
				margin-top: 10px;
				font-size: 1.2rem;
				line-height: 1.6rem;
			}
			.logo {
				position: absolute;
				right: 0;
				top: 0;
				height: 30px;
				width: 30px;
			}
		}
		.connect {
			margin-top: 15px;
			@include flexbox();
			justify-content: space-between;
			align-items: flex-end;
			.read-more {
				color: #11a1ae;
				font-size: 1.2rem;
				font-weight: bold;
			}
		}
	}

	&--container {
		padding: 1rem 1rem 1rem 1.5rem;
		border: 1px solid rgba($border-color, 0.5);
		margin-bottom: 2rem;
		.sub-sec-title {
			color: $primary-text-color;
			font-size: 1.4rem;
			line-height: 1.7rem;
		}
	}

	.ga {
		// margin-top: 20px;
		&-semi {
			width: 550px;
		}
		.logo {
			margin-top: 20px;
			width: 170px;
		}
		.title {
			font-size: 1.6rem !important;
		}
		.arrow {
			font-size: 1.5rem;
		}
		.sub-desc {
			margin-top: 10px;
			color: #9a9b9c;
			font-size: 1.3rem !important;
		}
		.read-more {
			color: #11a1ae;
			font-weight: bold;
			.icon {
				position: relative;
				font-size: 14px;
				font-weight: bold;
				top: 1px;
				left: 1px;
			}
		}
	}

	.information {
		font-size: 1.3rem;
		width: max-content;
		font-weight: $medium;
		color: #232423;
		background: #e4e4e4;
		border-radius: 5px;
		padding: 0.5rem 1rem;
		border: 2px solid rgba(251, 190, 77, 0.31);
		background-color: #fff7e7;
		.info {
			color: #696b69;
			font-size: 18px;
			margin-right: 5px;
		}
	}
	.integration {
		&-item {
			display: flex;
			border: 1px solid #dbd9e4;
			justify-content: space-between;
			padding: 13px;
			border-radius: 9px;
			margin-top: 20px;
			.title-wrap {
				@include flexbox();
				justify-content: center;
				align-items: center;
				.name {
					margin-left: 20px;
				}
				.icon {
					width: 20px;
					height: 20px;
					object-fit: cover;
				}
			}
			.action {
				@include flexbox();
				justify-content: center;
				align-items: center;
				&-item {
					cursor: pointer;
					color: #ccc9c9;
					padding: 0 0.3rem;
					&:hover {
						color: #393f53;
					}
				}
			}
		}
	}
	.cont-wrap {
		padding: 0 4rem 2rem 0;
		border-bottom: 1px solid rgba($border-color, 0.5);
		.logo {
			&-title {
				color: $primary-text-color;
				font-size: 1.4rem;
				font-weight: $semibold;
				line-height: 1.7rem;
			}
			.zappier {
				width: 20px;
				height: 20px;
				margin-right: 0.5rem;
			}
		}
		.list {
			&-item {
				padding: 0.7rem 0;
				color: $primary-text-color;
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
				.item-title {
					min-width: 150px;
					> a {
						color: $primary-text-color;
					}
				}
				.material-icons {
					font-size: 18px;
					cursor: pointer;
				}
			}
		}
		.title {
			color: $primary-text-color;
			font-size: 1.4rem;
			font-weight: $bold;
			line-height: 1.7rem;
			text-transform: uppercase;
		}
		.sub-sec-title {
			color: $primary-text-color;
			font-size: 1.4rem;
			line-height: 1.7rem;
		}
		.link {
			font-size: 1.4rem;
			> .material-icons {
				font-size: 2rem;
			}
		}
		.material-icons.delete {
			position: absolute;
			right: -20px;
			top: 8px;
			cursor: pointer;
		}
	}
	&--edit {
		.integration-settings__body {
			padding-bottom: 3rem;
			border-bottom: 1px solid rgba($border-color, 0.5);
		}
	}
	&--view {
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			border-radius: 22px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 30px;
			background-color: $white;
			box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31);
			&:not(:first-of-type) {
				margin-top: 10px;
			}
			&__cont {
				color: var(--primary-text-color);
				font-weight: $medium;
				letter-spacing: 0.2px;
				line-height: 1.9rem;
			}
		}
	}
	.variable {
		font-weight: $medium;
	}
}
