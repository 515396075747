.landing-page-wrap {
	width: 100%;
	padding: 0;
	&-preview {
		height: 100vh;
	}
	&_tab {
		padding: 0 2rem;
	}
	&.main_preview {
		height: calc(100% - 0px) !important;
		position: fixed;
		top: 0;
	}

	&_body {
		height: calc(100vh - 203px);
		overflow-y: auto;
		padding: 0 !important;
		&_preview {
			height: 100vh;
		}
		&.main_preview {
			height: 100% !important;
		}
	}

	.ruler {
		margin: 2rem 0;
		border: 1px solid #f5f4f4;
	}

	&_container {
		width: 415px;
		min-width: 415px;
		background-color: #fbfbfb;
		padding: 1.8rem 2rem;
		overflow: auto;

		.collapse {
			&-container {
				background-color: #fff;
				width: 100%;
				margin-bottom: 1.5rem;
				box-shadow: 0 0 1px 0 #d0d3e1;
			}
			&-title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				user-select: none;
				padding: 1.2rem 2rem;
				.title {
					color: #4e4e4e;
					font-size: 1.5rem;
					font-weight: 700;
				}

				.icon-down {
					color: $primary-brand-color--light;
					font-size: 26px;
					transition: all 0.2s ease;
					&.active {
						transform: rotate(90deg) !important;
					}
				}
			}
			&-body {
				width: 100%;
				background-color: #fff;
				position: relative;
				// top: 10px;
				padding: 1.2rem 2rem;
				border-top: 1px solid #f3f7f9;
				// padding: 0 0.5rem;
			}
		}

		&_share {
			width: 570px;
			background-color: #ffffff;

			.title {
				margin-bottom: 7px;
				color: #4e4e4e;
				font-size: 1.5rem;
				font-weight: 700;

				&-sub {
					margin-bottom: 7px;
					color: #888b96;
					font-size: 1.4rem;
				}
			}

			.read-more {
				color: #11a1ae;
				font-size: 1.4rem;
				font-weight: bold;
			}

			.copy-field {
				padding: 2rem;
				width: 100%;
				color: #6e7282;
			}

			.domain-btn {
				min-width: 100px;
			}

			.domain-wrapper {
				@include flexbox();
				border: 1px solid #dadada;
				border-radius: 2px;
				.domain-input {
					border: transparent;
					width: 132px;
				}
				.domain-label {
					border: transparent;
					background-color: #f3f7f9;
					padding: 0.9rem 1.6rem;
				}
				&:focus-within {
					border-color: #11a1ae;
				}
			}
			.domain-input {
				// -webkit-appearance: none !important;
				// color: red;
				// text-align: right;
				// width: 75px;
				// border: 1px solid gray;
				// border-left: 0px;
				// margin: 0 0 0 -7px;
				// background: white;
			}
			.domain-label {
				// -webkit-appearance: none !important;
				// border: 1px solid gray;
				// border-right: 0px;
				// outline: none;
			}
		}

		.design-wrap {
			// padding: 1.2rem 2rem;
			// background-color: #fff;
			// width: 100%;
			// margin-bottom: 1.5rem;
			&:last-child {
				margin-bottom: 0;
			}

			.position-btn {
				&-wrap {
					display: flex;
					justify-content: space-around;
					margin-bottom: 28px;
				}
				padding: 0.8rem 1rem;
				border: 2px solid #e6e6e6;
				border-radius: 3px;
				cursor: pointer;
				flex-basis: 30%;
				flex-grow: 0;
				text-align: center;
				font-weight: $medium;
				&:hover {
					border-color: $primary-brand-color--light;
					color: $primary-brand-color--light;
				}
				&.active {
					border-color: $primary-brand-color--light;
					color: $primary-brand-color--light;
				}
			}

			.filler {
				pointer-events: none;
				height: 5px;
				background: #11a1ae;
				position: relative;
				top: -14px;
				/* left:8px; */
				border-radius: 6px;
				transition: all 0.1s ease;
				box-sizing: border-box;
				opacity: 0;
			}

			input[type='range'] {
				width: 100%;
				height: 8px; /* thumbHeight + (2 x thumbBorderWidth)*/
				-webkit-appearance: none; /*remove the line*/
				outline: none;
				border: 10px; /*for firefox on android*/
				margin: 8px 0;
				-moz-appearance: none;
				padding: 0;
			}

			input[type='range']:disabled {
				cursor: none !important;
				-moz-appearance: none;
				-webkit-appearance: none;
			}

			input[type='range']::-webkit-slider-runnable-track {
				background: #ccc; /*trackColor*/
				height: 5px; /*trackHeight*/
				border-radius: 6px; /*trackHeight*/
				transition: 0.3s;
			}

			// slider button
			input[type='range']::-webkit-slider-thumb {
				-webkit-appearance: none;
				width: 18px; /* thumbHeight + (2 x thumbBorderWidth)*/
				height: 18px; /* thumbHeight + (2 x thumbBorderWidth)*/
				border-radius: 50%;
				margin-top: -8px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
				cursor: pointer;
				transition: 0.3s;
				z-index: 10;
				position: relative;
				background: #ffffff;
				border: 1px solid #dcdcdc;
				box-shadow: 0 2px 4px 0 rgba(108, 105, 105, 0.5);
				left: 0;
			}

			input[type='range']::-moz-focus-outer {
				border: 0;
			}

			input[type='range']::-moz-range-track {
				background: #ccc; /*trackColor*/
				position: relative;
				height: 15px; /*trackHeight*/
				border-radius: 4px; /*trackHeight*/
			}

			input[type='range']::-moz-range-track + .filler {
				position: relative;
				display: none;
			}

			input[type='range']::-moz-range-progress {
				background-color: #3856de;
				height: 15px;
				border-radius: 6px;
			}

			input[type='range']::-moz-range-thumb {
				width: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
				height: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
				border-radius: 50%;
				margin-top: -8px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
				cursor: pointer;
				border: 4px solid #fff; /*border-width should be equal to thumbBorderWidth if you want same border width across all browsers and border-color should match the background*/
				transition: 0.3s;
				z-index: 10;
				position: relative;
				background: #ffffff;
				border: 1px solid #dcdcdc;
				box-shadow: 0 2px 4px 0 rgba(108, 105, 105, 0.5);
			}

			input[type='range']::-moz-focus-outer {
				border: 0;
			}

			input[type='range']::-moz-range-track {
				background: #ccc; /*trackColor*/
				position: relative;
				height: 15px; /*trackHeight*/
				border-radius: 4px; /*trackHeight*/
			}

			.toggle-wrap {
				display: flex;
				justify-content: space-between;
				.show-logo-image {
					&-wrap {
						display: flex;
						justify-content: center;
						height: 100%;
						width: 100%;
						user-select: none;

						.logo-image {
						}
					}
				}
			}

			.logo-image {
				&-wrap {
					display: flex;
					justify-content: center;
					height: 100%;
					width: 100%;
					user-select: none;

					.logo-image {
					}
				}
			}

			.input-wrap {
				input {
					background-color: #f3f7f9 !important;
					border-color: #cbd6e2 !important;
				}
				.label-title {
					font-size: 1.4rem;
					font-weight: $medium;
				}
				.sub-text {
					color: #9a9b9c;
					font-size: 1.3rem;
				}
			}

			.custom-select {
				background-color: #f3f7f9;
			}
			.range {
				position: relative;
				.label-title {
					font-size: 1.2rem;
					font-weight: $medium;
				}
				.bubble-value {
					background: #555555;
					color: white;
					padding: 1px 6px;
					position: absolute;
					font-size: 12px;
					font-weight: 700;
					border-radius: 4px;
					left: 50%;
					transform: translateX(-50%);
					z-index: 999;
					opacity: 0;
					&::after {
						content: '';
						position: absolute;
						width: 5px;
						height: 5px;
						background: #555555;
						top: -3px;
						left: 40%;
						transform: rotate(45deg);
					}
				}
			}
			.upload-img {
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 700;

				border: 1px solid #dadada;
				padding: 0.6rem 1rem;
				width: 164px;
				border-radius: 2px;
				cursor: pointer;

				&-icon {
					color: #11a1ae !important;
				}
			}

			.uploaded-img {
				position: relative;
				height: 50px;
				// width: 150px;
				min-width: 50px;
				max-width: 150px;
				> img {
					// height: 50px;
					height: 50px;
					object-fit: contain;
					// width: 150px;
					min-width: 50px;
					max-width: 150px;
				}
				.trash-icon {
					position: absolute;
					font-size: 1.7rem;
					top: 14px;
					left: 47px;
					display: block;
					cursor: pointer;
				}
			}

			// .upload-img-wrap {
			//   display: flex;
			//   align-items: center;
			//   justify-content: space-between;

			// }
			.upload-image-wrap {
				.background-image {
					&-detail {
						.sub-title {
							font-size: 1.2rem;
						}
					}

					.upload-img-wrap {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.upload-img {
							display: flex;
							align-items: center;
							font-size: 14px;
							font-weight: 700;

							border: 1px solid #dadada;
							padding: 0.6rem 1rem;
							width: 164px;
							border-radius: 2px;
							cursor: pointer;

							&-icon {
								color: #11a1ae !important;
							}
						}
					}
					.file-upload {
						position: relative;

						.trash-icon {
							position: absolute;
							font-size: 1.7rem;
							top: 9px;
							left: 39px;
							display: block;
							cursor: pointer;
						}
						.img-upload {
							position: relative;
							width: 36px;
							height: 35px;
							border-radius: 50%;
							object-fit: cover;
						}
					}
					.theme-label {
						font-size: 1.4rem;
						color: rgba(57, 63, 83, 0.6);
						font-weight: 500;
						&-sub {
							font-size: 1.2rem;
						}
					}
				}
			}

			.design-color-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;

				.theme-label {
					font-size: 1.5rem;
					font-weight: $medium;
				}
				.color-picker-wrap {
					.theme-color--bg-secondary {
						background-color: rgb(56, 86, 222);
					}

					.theme-color-input {
						width: 10px;
						height: 10px;
						border-radius: 50%;
					}

					.theme-color {
						padding: 1.5rem;
						border: none;
						box-shadow: inset 0 0 1px #bebdbd;
					}
				}
			}

			.background-colour-wrap {
				.background-colour {
					display: flex;
					-ms-flex-align: center;
					-webkit-align-items: center;
					-webkit-box-align: center;
					flex-direction: column;

					// line-height: 4rem;
					flex-basis: 30%;
					flex-grow: 0;
					padding: 1rem;
					justify-content: flex-start !important;

					.cursor-pointer {
						height: 30px;
					}

					.theme-label {
						font-size: 1.4rem;
						color: rgba(57, 63, 83, 0.6);
						font-weight: 500;
					}
					&:hover {
						background-color: #f3f7f9;
					}
					&-active {
						background-color: #f3f7f9;
					}
					.img-upload {
						position: relative;
						width: 36px;
						height: 35px;
						border-radius: 50%;
						object-fit: cover;
					}
				}
			}

			.design-detail {
				width: 100%;
				background-color: #fff;
				position: relative;
				top: 10px;
				padding: 0 0.5rem;
				display: none;

				&-title {
					margin-bottom: 14px;
					color: #4e4e4e;
					font-size: 1.5rem;
					font-weight: 700;

					&-sub {
						margin-bottom: 14px;
						color: #4e4e4e;
						font-size: 1.5rem;
					}
				}

				.show-logo-wrap {
					.show-logo {
						display: flex;
						justify-content: space-between;
					}
				}

				.design-text-wrap {
					.input-wrap {
						.label-title {
							font-size: 1.5rem;
							color: #4e4e4e;
						}

						.input-field:focus {
							background-color: #f3f7f9;
						}
					}
				}

				.gradient-wrap {
					display: none;
				}

				.design-color-wrap {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: relative;

					.color-picker-wrap {
						.theme-color--bg-secondary {
							background-color: rgb(56, 86, 222);
						}

						.theme-color-input {
							width: 10px;
							height: 10px;
							border-radius: 50%;
						}

						.theme-color {
							padding: 1.5rem;
							border: none;
						}
					}
				}

				.opacity {
					opacity: 1;
				}

				&:before {
					content: '';
					position: absolute;
					width: 112%;
					border-bottom: 1px solid #f3f7f9;
					left: -20px;
					top: -18px;
				}

				.theme-label {
					font-size: 1.5rem;
					color: rgba(57, 63, 83, 0.6);
					font-weight: 500;
				}

				.sub-text {
					color: #9a9b9c;
					font-size: 1.3rem !important;
				}

				.img-upload {
					position: relative;
					width: 36px;
					height: 35px;
					border-radius: 50%;
					object-fit: cover;
				}

				.file-upload {
					position: relative;

					.trash-icon {
						position: absolute;
						font-size: 1.7rem;
						top: 9px;
						left: 39px;
						display: block;
						cursor: pointer;
					}
				}
			}

			.active {
				display: block !important;
			}

			.background-active {
				background-color: #f3f7f9;
			}
		}
	}

	.landing {
		&-preview {
			position: relative;
			width: 100%;
			&-public {
				height: 100%;
			}
			&.main_preview {
				display: flex;
				flex-direction: column;
				margin: 0;
				height: 100%;
				// min-height: calc(100vh - 110px);
				// height: calc(100vh - 60px) !important;
			}
		}
		.background-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.3);
		}
		&-header {
			width: 100%;
			display: flex;
			background-color: $primary-brand-color--light;
			height: 60px;
			max-height: 60px;
			padding: 0.5rem;
			z-index: 99999;
			position: relative;
			&.main_preview {
				// position: fixed;
				// top: 0;
			}
			.logo {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 5px;
				margin-right: 5px;
				&-img {
					height: 50px;
					// width: 50px;
					// object-fit: cover;
				}
				&-text-container {
					display: flex;
					flex-direction: column;
					padding-left: 5px;
				}
				&-text {
					display: flex;
					align-items: center;
					height: 100%;
					font-size: 14px;
				}
				&-tagline {
					display: flex;
					align-items: center;
					height: 100%;
					font-size: 14px;
				}
			}
		}
		&-body {
			position: relative;
			height: calc(100% - 110px);
			overflow-y: hidden;
			&.main_preview {
				// height: calc(100vh - 170px);
				// max-height: calc(100vh - 170px);
				// margin-top: 60px;
			}
			.conversation-preview {
				background-color: transparent;
				width: 600px;
				margin: 0 auto;
				padding-top: 20px;
			}
		}
		&-footer {
			height: 50px;
			@include flexbox();
			justify-content: center;
			align-items: center;
			position: relative;
			.powered-by {
				padding: 4px 12px;
				font-size: 1.5rem;
				border: 1px solid #dadada;
				background: white;
				border-radius: 3px;
				.power {
					font-size: 12px;
				}
				.by {
					margin-right: 5px;
					&::before {
						content: '\26A1';
						margin-left: -1px;
						margin-right: 3px;
						font-size: 10px;
					}
				}
				.brand {
					font-weight: 600;
					color: black;
				}
			}
		}
	}
}
