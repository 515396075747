.client-account {
	// padding: 3rem 3rem 0;
	height: 100vh;
	.account {
		&-grid {
			display: flex;
		}
	}
	.left {
		flex-basis: 25%;
		.section-header {
			max-width: 180px;
		}
	}
	.ruler-client {
		border-top: 1px solid #dbdbdb;
	}
	.header-section {
		justify-content: center;
		margin: 1.7rem 0;
		.heading-level1 {
			margin-bottom: 0.6rem;
		}
	}
	.right {
		background: white;
		padding: 40px 30px 0 32px;
		border-radius: 10px;
		box-shadow: 0 11px 12px 0 #c4cbc9;
		min-height: 550px;
		max-width: 900px;
		overflow: auto;
		padding-bottom: 10px;
	}
	.right-sec {
		width: calc(100% - 146px);
		// margin-left: 2rem;
		width: 900px;
	}
	.section-nav {
		&__item {
			position: relative;
			color: #1c2e59;
			// &:not(:last-child):after{
			//   content: "\e315";
			//   font-family: "Material Icons";
			//   font-weight: normal;
			//   font-style: normal;
			//   font-size: 20px;
			//   display: inline-block;
			//   line-height: 1;
			//   text-transform: none;
			//   letter-spacing: normal;
			//   word-wrap: normal;
			//   white-space: nowrap;
			//   direction: ltr;
			//   vertical-align: bottom;
			// }
			&.active {
				font-weight: $semibold;
			}
		}
	}
}
.client-info-edit {
	max-width: 1000px;
	border-radius: 10px;
	background: white;
	box-shadow: 0 11px 12px 0 #c4cbc9;
	.info {
		padding: 20px;
	}
	.border-right {
		border-right: 1px solid #dbdbdb;
	}
	&__header {
		padding: 1rem 0;
		border-bottom: 1px solid rgba($border-secondary, 0.3);
		margin-bottom: 2.5rem;
		.title {
			color: #393f53;
			font-size: 1.6rem;
			font-weight: $medium;
			line-height: 2rem;
		}
	}

	.company-logo {
		position: relative;
		@include avatar(100px, 100px, rgba(32, 45, 86, 0.04), 100%);
		border: 1px dashed rgba(32, 45, 86, 0.33);
		cursor: pointer;
		.plus-icon {
			color: $primary-brand-color;
			font-size: 4.5rem;
			text-align: center;
		}
		> img {
			@include avatar(100%, 100%, transparent, 100%);
			object-fit: cover;
		}
		&-wrap {
			width: 70px;
			height: 70px;
			border-radius: 35px;
			border: 1px dashed #36c2cf;
			background: #eaf3f4;
		}
	}

	.plus {
		width: 100%;
		height: 100%;
	}
	.address-info-txt {
		color: rgba(#393f53, 0.6);
		font-size: 1.3rem;
		font-weight: $medium;
		line-height: 1.6rem;
		text-transform: uppercase;
	}
}
.org-info {
	.client-info__body {
		height: calc(100vh - 200px);
		overflow-y: auto;
	}
}
.mr-25 {
	margin-right: 2.5rem;
}
.ml-25 {
	margin-left: 2.5rem;
}
.pl-25 {
	padding-left: 2.5rem;
}
.no-border {
	border: 0 !important;
}
.action-wrapper {
	padding: 10px 20px;
	border-top: 1px solid #dbdbdb;
}

.client-info-2 {
	.client-info__header {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.company-logo {
		position: relative;
		@include avatar(100px, 100px, rgba(32, 45, 86, 0.04), 100%);
		border: 1px dashed rgba(32, 45, 86, 0.33);
		margin-bottom: 20px;
		cursor: pointer;
		.plus-icon {
			color: $primary-brand-color;
			font-size: 4.5rem;
			text-align: center;
			margin: 0;
		}
		> img {
			@include avatar(100%, 100%, transparent, 100%);
			object-fit: cover;
		}
	}
}
.client-account-info-label-div {
	display: flex;
	.address-info-txt {
		flex-grow: 1;
	}
}
.status-pill {
	&.payment_submitted {
		background: #edf8ec;
		border: 1px solid rgba(76, 200, 68, 0.31);
		border-radius: 14px;
		color: #2aa228;
	}
}
.bg-d-sub-heading {
	font-size: 16px;
	font-weight: 700;
}
.client-form {
	.custom-radio {
		.item {
			min-width: 100px;
		}
	}
	.primary-label {
		min-width: 100px;
	}
	.label-txt {
		font-weight: 600 !important;
		color: #5e7281 !important;
	}
	.icon {
		position: relative;
		width: 14px !important;
		height: 14px !important;
		min-height: 14px !important;
		min-width: 14px !important;
		border: 2px solid $primary-text-color;
		transition: all 300ms;
		background: #c4c4c4;
		border: 1px solid #393f53 !important;
	}
	input:checked + label {
		.icon {
			border: 1px solid #393f53 !important;
			&:after {
				content: '';
				width: 12px !important;
				height: 11.5px !important;
				min-height: 11.5px !important;
				min-width: 12px !important;
				position: absolute;
				top: auto;
				left: auto;
			}
		}
	}
}
