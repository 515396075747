.advanced-condition {
	padding-bottom: 5px;
	overflow-x: auto;
	min-height: 220px;
}

.advanced-condition.view {
	min-height: 130px !important;
}
.advanced-condition .custom-select {
	padding: 0.5rem 3rem 0.5rem 1.6rem;
}
.advanced-condition .btn {
	padding: 0.5rem 1rem;
}
.advanced-condition .btn.material-icons {
	font-size: 18px;
}
.advanced-condition .start-chat {
	width: 215px;
}
.advanced-condition .and-wrap,
.advanced-condition .equal-btn {
	width: 90px;
}
.advanced-condition .yes-btn {
	width: 120px;
	z-index: 999;
}
.advanced-condition .custom-btn {
	width: 160px;
}
.advanced-condition .custom-btn {
	width: 160px;
}
.advance-btn {
	height: 31px !important;
	margin-bottom: 4px !important;
}

.advanced-condition .and-view {
	width: max-content;
	// height: 35px;
	min-height: 30px;
	z-index: 999;
	font-size: 1.4rem;
	line-height: 1rem;
	padding: 0.9rem 1.6rem;
	background: #fafbff;
	border: 1px solid #dadada;
	cursor: default;
}
.advanced-condition .yes-btn .input-text {
	width: 120px;
	height: 32px;
	font-size: 1.4rem;
	// line-height: 2rem;
	// padding: 0.9rem 3rem 0.9rem 1.6rem;
	&:disabled {
		background-color: #e6e4e1;
		color: grey;
		font-weight: 500;
	}
}
.advanced-condition .custom-btn .input-text {
	width: 160px;
	height: 32px;
	font-size: 1.4rem;
	// line-height: 2rem;
	// padding: 0.9rem 3rem 0.9rem 1.6rem;
}
.advanced-condition .constraint-container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	// padding-top: 10px;
	margin-right: auto;
	margin-left: auto;
	border-left: 1px solid #dadada;
}
.advanced-condition .constraint-container:not(:first-child) {
	position: relative;
}
.advanced-condition .constraint-container:not(:first-child)::before {
	content: '';
	display: inline-block;
	position: absolute;
	width: 27px;
	// top: 16px;
	height: 1px;
	left: -27px;
	background: #dadada;
}
.advanced-condition .main-group {
	width: max-content;
}
.advanced-condition .main-group * {
	position: relative;
	z-index: 1;
}
.advanced-condition .main-group .holder,
.sub-type {
	position: relative;
}
.advanced-condition .main-group .holder::after {
	content: '';
	display: inline-block;
	position: absolute;
	width: 100%;
	top: 16px;
	height: 1px;
	left: -15px;
	background: #dadada;
}
.advanced-condition .main-group .sub-type::after {
	content: '';
	display: inline-block;
	position: absolute;
	width: 100%;
	top: 16px;
	height: 1px;
	left: -15px;
	background: #dadada;
}
.advanced-condition .main-group__child {
	margin-left: 10px;
	width: max-content;
}
.advanced-condition .main-group__child .holder {
	width: max-content;
	position: relative;
}
.advanced-condition .main-group__child .holder::before {
	content: '';
	display: inline-block;
	position: absolute;
	width: 100%;
	height: 1px;
	left: -25px;
	background: #dadada;
}
.options {
	padding: 0 !important;
	margin-right: 10px;
}

.search-field {
	padding: 0.5rem 1.1rem !important;
	line-height: 21px !important;
}
// .advanced-condition .main-group__child * {
//   // position: relative;
//   // z-index: 1;
// }
.advanced-condition .main-group__child .btn {
	margin-bottom: 0 !important;
	z-index: 1;
}
.advanced-condition .sub-group .advanced-condition {
	position: relative;
	padding-bottom: 0;
}
.advanced-condition .sub-group .advanced-condition:after {
	content: '';
	width: 26px;
	height: 1px;
	background: #dadada;
	position: absolute;
	left: -26px;
	top: 16px;
}
