.filters {
	height: 100%;
	margin-right: 5px;

	&:last-child {
		margin-right: 0px;
	}

	.modal__body {
		padding: 0px !important;
	}

	.collapsible {
		padding: 1rem 2rem;
		border-bottom: 1px solid #dadada;
		@include respond-to(mobile) {
			padding: 1rem;
		}
		&-wrap {
			@include flexbox();
			@include align-items(center);
			width: max-content;
			padding: 0.4rem 1rem;

			&.active {
				border: 1px solid #dadada;
				background: #dffafd;
				color: $filter-type-color;
				border-radius: 2px;
			}
			.total {
				letter-spacing: 2px;
			}
			.close-icon {
				color: $filter-type-color;
				font-size: 1.5rem;
				font-weight: $bold;
			}
		}
		.label-txt {
			font-weight: 700;
			font-size: 1.5rem;
		}
	}

	.sub-text {
		color: #666666;
		font-weight: 500;
		font-size: 1.4rem;
	}

	&-dropdown-wrap {
		@include flexbox();
		height: 100%;
		justify-content: center;
		align-items: center;
		padding: 0.5rem 0.8rem;
		font-size: 1.4rem;
		background: $white;
		border: 1px solid #dadada;
		border-radius: 2px;
		-webkit-appearance: none;
		white-space: nowrap;
		-webkit-user-select: none;
		user-select: none;

		&.active {
			background: #dffafd;
		}

		.enquiries-menu {
			@include text-elipsis();
			width: 100px;
		}
		.label-txt {
			color: $filter-type-color;
			font-weight: $bold;
		}

		.total {
			letter-spacing: 2px;
		}

		.type {
			margin-left: 5px;
		}

		.drop-down-icon {
			color: $filter-type-color;
			font-weight: $bold;
			font-size: 2rem;
			margin-left: 5px;
		}

		.close-icon {
			color: $filter-type-color;
			font-size: 1.5rem;
			font-weight: $bold;
		}
	}

	.dropdown {
		//width: 250px;
		min-width: 246px;
		max-height: 500px;
		right: unset;

		&-user,
		&-bot_action {
			&.left {
				right: -6px;
			}
			&.right {
				left: -13px;
				&::before {
					top: -11px;
					right: unset;
					left: 103px;
				}
				&::after {
					top: -10px;
					right: unset;
					left: 103px;
				}
			}
		}
		&-user {
			top: 45px;
		}
		&-bot_action {
			top: 53px;
			.btn-text {
				color: #81889f !important;
			}
		}

		&-enquiry-list {
			padding: 5px 10px;

			&:not(:first-child) {
				border-top: 1px solid #dadada;
			}
			.btn-text {
				font-weight: 600;
				font-size: 15px;
				padding: 0.6rem 1rem;
			}

			.enquiry-types {
				&.scroll {
					overflow-y: scroll;
					max-height: 120px;
				}
				.enquiry-option {
					color: #81889f;
					cursor: pointer;
					padding: 0.6rem 1rem;
					&.active {
						background-color: #e6eef4;
						border-radius: 3px;
					}
					&:hover {
						background-color: #f8f8f8;
						border-radius: 3px;
					}
				}
			}
		}
	}

	&-calendar {
		.dropdown {
			padding: 0;
			min-width: max-content;
		}
	}

	.list-wrap {
		max-height: 230px;
		overflow: auto;

		&-item {
			padding: 1rem;
			border-bottom: 1px solid $filter-background;

			&-type {
				border-bottom: none;
			}
		}

		&-item-list {
			// max-height: 140px;
			// overflow: auto;
		}

		.main-list {
			// margin-top: 1rem;
		}

		.sub-list {
			margin-top: 1rem;
			margin-left: 3rem;

			li:last-child {
				margin-bottom: 1rem;
			}
		}

		.user {
			.checkbox-txt-wrap {
				top: 0px;
			}
		}

		.custom-checkbox {
			&-types {
				padding-bottom: 1rem;

				.checkbox-txt {
					margin-top: 1px;
					margin-left: 1rem;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}

			&-type-list {
				padding-bottom: 1rem;

				&:last-child {
					padding-bottom: 0;
				}
			}

			.checkbox-label {
				&:last-child {
					margin-bottom: 0;
				}

				.checkbox-text-wrap {
					.checkbox-txt {
						color: #393f53;
						font-size: 1.4rem;
						font-weight: 600;
						margin-left: 1.4rem;

						&--md {
							margin-left: 1.4rem;
							color: rgba($primary-text-color, 0.7);
							font-weight: $regular;
						}
					}
				}
			}

			.user-wrap {
				margin: -4px 0 0 12px;

				.user-name {
					color: #393f53;
					font-size: 1.4rem;
					font-weight: 600;
					margin-top: 2px;
				}

				.user-email {
					font-size: 1.3rem;
					font-weight: 500;
					color: #9a9b9b;
				}
			}

			.user-wrap-types {
				display: none;
				position: relative;
				left: 38px;
				bottom: 2px;
				margin-bottom: 14px;

				.checkbox-label {
					border-bottom: none !important;
				}

				&.show-sub-type {
					display: block;
				}
			}
		}
	}

	.search-container {
		padding: 0 1rem;
		padding-bottom: 10px;
		border-bottom: 1px solid $filter-background;

		&::after {
			color: $primary-brand-color--light;
		}
	}
}

.simple_dropdown {
	width: 100%;
	min-width: 100%;
	position: absolute;
	background: #fff;
	border: 1px solid #d7d3d3;
	border-radius: 2px;
	box-shadow: 0 2px 8px -1px rgba(180, 180, 180, 0.5);
	@include transition(all 0.3s ease);
	opacity: 0;
	visibility: hidden;
	transform: translateY(30%);
	z-index: 2;
	top: 39px;
	&.show {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.simple-dropdown-title {
	background: #fafafa;
	border: 1px solid #e0e0e1;
	padding: 7.5px 10px 7.5px 10px;
	color: gray;
}
.simple-dropdown-container {
	max-height: 150px;
	overflow-y: auto;
}

.simple-dropdown-item {
	background-color: #fff;
	&:hover {
		background-color: #f4f4f4;
		color: #1f2236;
	}
}

.select-wrap {
	position: relative;

	.custom-select-new {
		width: 100%;
		font-size: 1.4rem;
		line-height: 2rem;
		padding: 0.9rem 3rem 0.9rem 1.6rem;
		background: #fff
			url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCAxMCA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+YXJyb3ctZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNy4wMDAwMDAsIC0xMC4wMDAwMDApIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIGZpbGwtb3BhY2l0eT0iMC41IiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSIwIDAgMjQgMCAyNCAyNCAwIDI0Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=)
			right 10px center no-repeat;
		border: 1px solid #dadada;
		border-radius: 2px;
		-webkit-appearance: none;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		user-select: none;
	}
}

.input-date {
	width: 235px;
	padding-right: 3.5rem;
	background: $white url(/img/date-range1.svg) right 6px center no-repeat;
}

.dropdown-wrap {
	position: relative;
	top: 24px;
	right: 38px;
	display: none;

	.user-selection-list {
		position: absolute;
		width: 284px;
		border: 1px solid #dadada;
		padding: 10px;
		z-index: 5;
		background-color: #fff;

		.sub-content {
		}

		&.show {
			display: block !important;
		}
	}

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: -10px;
		right: -30px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 10px solid #dadada;
	}

	&:after {
		content: '';
		position: absolute;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 11px solid #fff;
		z-index: 5;
		top: -9px;
		left: 91px;
	}
}

.date-select-option {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	left: 30px;

	.date-option-wrap {
		padding: 1rem;
		min-width: 135px;
		&.border {
			border-right: 1px solid rgba(85, 85, 85, 0.12);
		}

		.date-type {
			font-size: 1.3rem;
			color: #5c5c5c;
			cursor: pointer;
			padding: 0.3rem 1rem;

			&.active {
				background-color: #e6eef4;
				border-radius: 3px;
				font-weight: $semibold;
			}
		}

		.date-btn-wrap {
			position: relative;
			right: 6px;

			.custom-range {
				font-size: 16px;
				font-weight: 500;
				background-color: #f8f8f8;
				padding: 6px 0;
				text-align: center;
				user-select: none;
				cursor: pointer;
				border-radius: 2px;
				margin-bottom: 22px;
			}
		}

		.date-apply-btn {
			background-color: #35838d;
			color: #fff;
			font-weight: 700;
			padding: 6px 0;
			text-align: center;
			user-select: none;
			cursor: pointer;
			border-radius: 2px;
			width: 116px;
			display: inline-block;
		}
	}

	.date-calender-wrap {
		flex: auto;
		padding: 1rem;
		border-left: 1px solid rgba(85, 85, 85, 0.12);
		@include respond-to(mobile) {
			border-left: none;
			padding: 0;
		}
		.calender-btn-wrap {
			display: flex;
			justify-content: space-around;
			.search-box {
				width: 118px;
				padding: 0.5rem 1rem;
				&-color {
					background-color: $grey;
				}
				&.focused {
					border-color: #11a1ae;
				}
			}
			.arrow-right {
				align-self: center;
				color: #35838d;
				font-size: 28px;
			}
			//
		}
		.calender-select {
			/*date picker css*/
			.calander-container {
				width: 325px;
				height: auto;
				background: #ffffff;
				border-radius: 5px;
				margin: 10px auto;
				box-sizing: border-box;
				overflow: hidden;
				position: relative;
			}
			.center .calander-container,
			.center .date-range-container {
				margin: 10px 40px;
			}
			.calander-container .re-try-overlay {
				position: absolute;
				display: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
			.header {
				position: relative;
				height: 75px;
				//height: 26px;
				background-color: var(--primary-color);
				box-shadow: none;
				display: flex;
				flex-direction: column;
				.top {
					display: flex;
					padding: 10px 0;
					select {
						background: rgba(255, 255, 255, 0.3);
						border: 0;
						font-size: 13px;
						font-weight: 500;
						-webkit-appearance: none;
						-moz-appearance: none;
						width: auto;
					}
					#months {
						margin: 0 5px;
						margin-left: 20px;
						width: 71px;
						background: none !important;
						font-size: 14px;
						font-weight: 600;
						color: #363636;
					}
					#years {
						margin: 0 5px;
						margin-right: 20px;
						background: none !important;
						font-size: 14px;
						font-weight: 600;
						color: #363636;
					}
				}
				.bottom {
					padding: 0 20px;
					display: flex;
					font-size: 12px;
					margin-top: 5px;
					width: inherit;
					.day-label {
						flex-grow: 1;
						text-align: center;
						color: #c3c3c3;
						font-weight: 600;
						font-size: 12px;
					}
				}
				button {
					border: 0;
					color: white;
					font-weight: 500;
					padding: 0;
					// border-radius: 50%;
					// background-color: #ecf0f1;
					height: 28px;
					width: 120px;
					.material-icons {
						font-size: 22px;
						color: #33516a;
						margin-top: 3px;
					}
				}
				.nav-right:disabled {
					background: rgba(0, 0, 0, 0.3) !important;
					cursor: not-allowed !important;
				}
				.nav-left:disabled {
					background: rgba(0, 0, 0, 0.3) !important;
					cursor: not-allowed !important;
				}
			}
			.days {
				display: flex;
				padding: 0px 20px;
				flex-wrap: wrap;
				.day {
					flex-grow: 1;
					text-align: center;
					height: 25px;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					display: flex;
					flex-basis: 13%;
					font-weight: 500;
					color: $black;
					font-size: 12px;
					cursor: pointer;
					&:hover {
						background: $filter-background;
					}
					&.disabled {
						opacity: 0.3;
						pointer-events: none;
					}
					&.selected {
						background-color: #11a1ae;
						color: #ffffff;
						font-weight: 600;
					}
					&.today {
						background-color: #dadada;
						font-weight: 600;
					}
				}
			}
			.body {
				padding: 20px;
			}
		}
	}
}

.date-selection-type {
	//display: none;
	width: 461px;

	&.show {
		display: block !important;
	}

	//&:after {
	//  content: "";
	//  display: inline-block;
	//  position: absolute;
	//  top: -9px;
	//  border-left: 8px solid transparent;
	//  border-right: 8px solid transparent;
	//  border-bottom: 11px solid #ffffff;
	//  left: 142px;
	//}
}

.table-details {
	display: none;

	&-show {
		display: block;
	}
}

// .table-row {
//   padding: 1rem;
//   background-color: #f3f7f9;

//   .actions {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .actions-item {
//       font-weight: 600 !important;
//       color: #11a1ae !important;

//       .add-icon {
//         font-size: 16px;
//         color: #11a1ae;
//       }

//       .grey-icon {
//         font-size: 16px;
//         color: #ccc;
//       }
//     }
//   }
// }

.table-row-inline {
	margin: 5px 0;
	padding: 0.8rem 1rem;
	flex: 1 1;
}

.table-body {
	.drag-icon {
		color: #dadada;
	}
}

input[type='checkbox']:checked ~ #email {
	display: block;
}

.input-field {
	&-sub {
		padding: 0.7rem 1rem;
	}
}
.hide-arrow {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}
.input-wrap {
	margin-bottom: 1.5rem;

	.input-label {
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: $semibold;
		margin-bottom: 0.5rem;
	}

	.input-field {
		width: 100%;
	}
}

.date-range {
	&-wrap {
		width: 220px;
	}

	&-selector {
		width: 100%;
		background: $white
			url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTggMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT4yNHB4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjI0cHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjAwMDAwMCwgLTEuMDAwMDAwKSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNywxMiBMMTIsMTIgTDEyLDE3IEwxNywxNyBMMTcsMTIgWiBNMTYsMSBMMTYsMyBMOCwzIEw4LDEgTDYsMSBMNiwzIEw1LDMgQzMuODksMyAzLjAxLDMuOSAzLjAxLDUgTDMsMTkgQzMsMjAuMSAzLjg5LDIxIDUsMjEgTDE5LDIxIEMyMC4xLDIxIDIxLDIwLjEgMjEsMTkgTDIxLDUgQzIxLDMuOSAyMC4xLDMgMTksMyBMMTgsMyBMMTgsMSBMMTYsMSBaIE0xOSwxOSBMNSwxOSBMNSw4IEwxOSw4IEwxOSwxOSBaIiBpZD0iU2hhcGUiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIgb3BhY2l0eT0iMC43Ij48L3BhdGg+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjAgMCAyNCAwIDI0IDI0IDAgMjQiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
			right 10px center no-repeat;
		padding-right: 3rem;
		cursor: pointer;
	}
}
