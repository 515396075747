.all-bots {
	padding: 2rem 3rem;
	overflow-y: auto;
	&--email {
		overflow: hidden;
	}
	&.raw {
		min-height: 300px;
	}
	.inner-table {
		max-height: calc(100vh - 238px);
		overflow-y: auto;
	}
	&-table {
		overflow-y: auto;
		max-height: calc(100vh - 160px);
		&-paginate {
			max-height: calc(100vh - 210px);
		}
		&--raw-data {
			min-height: calc(100vh - 160px);
			.table tr th,
			table tr td {
				white-space: nowrap;
				border-right: 1px solid rgba(166, 167, 167, 0.31);
			}
			table {
				width: initial !important;
			}
		}
		.table {
			width: 100%;
			background: $white;
			table-layout: fixed;
			thead {
				position: relative;
			}
			.raw-header {
				background: #dedddd;
				font-weight: $semibold;
				position: sticky;
				top: -1px;
				// border-right: 1px solid rgba(166, 167, 167, 0.) !important;
			}
			tr {
				border: 1px solid rgba(166, 167, 167, 0.31);
				border-radius: 1px;
			}
			th {
				font-size: 1.3rem;
				text-transform: uppercase;
				font-weight: $medium;
				// &:last-child {
				//   width: 15%;
				// }
			}
			td,
			th,
			td {
				text-align: center;
				padding: 1rem 2rem;
				word-break: break-all;
				&:first-child,
				&:nth-child(2) {
					text-align: left;
				}
			}
			.user-notification-td {
				@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
					padding-right: 0;
					padding-left: 5px;

					.status-pill {
						padding: 2px 10px;
					}
				}
			}

			.user-notification-type-and-status {
				@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
					width: 80px;
				}
			}

			.link {
				@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
					display: inline-block;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 80px;
					white-space: nowrap;
				}
			}

			.action-type {
				width: 150px;
			}
			.action-icons {
				> .material-icons:not(:last-child) {
					margin-right: 0.8rem;
				}
				> .material-icons {
					color: rgba($primary-text-color, 0.6);
					transition: color 300ms;
					cursor: pointer;
					&:hover {
						color: rgba($primary-text-color, 0.8);
					}
				}
			}
			.table-caption {
				color: #4a4e51;
			}
			.interactions-wrap {
				li {
					font-size: 1.6rem;
					&::before {
						content: '';
						display: inline-block;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: $white;
						border: 2px solid #fbbe4d;
						margin-right: 0.5rem;
					}
					&.attempts::before {
						border-color: #607cff;
					}
					&.completed::before {
						border-color: $green;
					}
				}
			}
			.table-actions {
				height: 32px;
				min-width: 32px;
				background-color: rgba(234, 234, 234, 0.4);
				border-radius: 5px;
				margin-right: 1rem;
				font-size: 2rem;
				color: #5e7281;
			}

			.user-notification-table-actions {
				@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
					margin-right: 5px;
				}
			}

			.user-notification-slider-ball {
				@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
					margin-left: 0 !important;
				}
			}

			.table-bot-actions {
				@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
					min-width: 10px;
				}
			}

			.client-name-link {
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 1.9rem;
				transition: color 300ms;
				white-space: nowrap;
				overflow: hidden;
				cursor: pointer;
				color: #4e4e4e;
				width: 374px;
				text-overflow: ellipsis;
			}
		}
	}
	&-pagination-btn {
		text-decoration: none;
		transition: background-color 0.3s;
		border: 1px solid #ddd;
		margin: 0 4px;
		padding: 0.4rem 1rem;
		cursor: pointer;
		color: #393f53;
		font-size: 1.5rem;
		font-weight: 500;
		display: inline-block;
		user-select: none;

		&:hover {
			background-color: #ddd;
		}
	}

	.btn-disable {
		display: none !important;
	}

	.actions-wrap {
		.account-type-wrap {
			padding: 0.7rem 1.2rem;
			background-color: #fff;
			border: 1px solid #e6e6e6;
			border-radius: 2px;
			.account-dropdown-wrap {
				.account-title {
					font-weight: 600;
					color: #11a1ae;
				}
				.icon-expand {
					color: #11a1ae;
				}

				.dropdown-account {
					top: 50px;
					right: -4px;
					.dropdown-height {
						max-height: 200px;
						overflow: auto;
					}
					.account-type-list {
						padding-left: 12px;
						.account-type {
							.account-option {
								font-size: 1.4rem;
								font-weight: 500;
								line-height: 1.6rem;
								&:not(:last-child) {
									padding-bottom: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.accounts-padding-top {
	padding-top: 5px !important;
}

.status-pill {
	font-size: 1.3rem;
	font-weight: $medium;
	color: #585b59;
	background: #e4e4e4;
	border: 1px solid rgba(166, 167, 167, 0.31);
	border-radius: 14px;
	padding: 0.2rem 1.5rem;
	&.published {
		background: #ecf8f2;
		border: 1px solid rgba(108, 198, 149, 0.31);
		border-radius: 14px;
		color: $green;
	}
	&.active {
		color: #00a558;
		border: 1px solid rgba(68, 200, 144, 0.31);
		border-radius: 14px;
		background-color: #e9f9f2;
	}
	&.pending {
		color: darken($secondary-brand-color, 15%);
		border: 1px solid rgba(251, 190, 77, 0.31);
		background-color: #fff7e7;
	}
	&.submitted {
		color: #2f57e6;
		border: 1px solid rgba(47, 87, 230, 0.31);
		background-color: #eaeeff;
	}
}

.status-pill__no-border {
	font-size: 1.3rem;
	font-weight: $medium;
	color: #585b59;
	background: #e4e4e4;
	padding: 0.2rem 1.5rem;
	&.bold {
		font-weight: $bold;
	}
	&.published {
		background: #ecf8f2;
		color: $green;
	}
	&.active {
		color: #398139;
		background-color: #e9f3ed;
	}
	&.pending {
		color: #f3933d;
		background-color: #fef4d9;
	}
	&.submitted {
		color: #2f57e6;
		background-color: #eaeeff;
	}
	&.expired {
		color: #ee5b4f;
		background-color: #fadedc;
	}
	&.empty {
		padding: 0rem;
	}
}

.status-pill-backdrop {
	font-size: 1.2rem;
	font-weight: $medium;
	color: #585b59;
	background: #e4e4e4;
	border-radius: 15px;
	padding: 0.1rem 1.3rem;
	// @include flexbox();
	// justify-content: center;
	// align-items: center;
	&.progress {
		color: #ffc93e;
		background-color: rgba(255, 225, 147, 0.28);
	}
	&.overdue {
		color: #ff6b6b;
		background-color: #ffe1e1;
	}
	&.complete {
		color: #11a843;
		background-color: rgba(0, 206, 69, 0.21);
	}
}
